.assign-modal {
    font-family: 'Nunito';
    .modal-header {
        padding: .5rem 1rem;
        font-weight: 600;
    }
    .modal-content {
        border-radius: 0;
        border: 0;
    }
}