#profile{
    font-family: Nunito;
}

#profile .userName{
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: bold;
    color: #1a1818;
    margin-top: 0.5rem;
}

#profile .userMail, .userPhone{
    font-size: 13px;
    color: #b7b7b7;
}

#profile .userDetails{
    border-bottom: solid 1px #efefef;
}

#profile .userDiv{
    border-radius: 4px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    min-height: 243px;
}

#profile .userDetailsDiv{
    border-radius: 4px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    min-height: 243px;
}

#profile .user_image {
    height: 100px;
    width: 100px;
}
.camera {
    height:60px;
    width:60px;
    border-radius: 30px;
    border: 2px solid rgb(160, 156, 156);
}
.camera_button {
    margin-top: -80px;
    margin-left: 140px;
    margin-bottom: 20px;
}
#profile .profile-heading {
    color: #3B3B3B;
    font-size: 24px;
    font-family: Nunito;
    font-weight: bold;
    line-height: 31px;
    width: 100%;
    padding-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#profile .editName {
    color: #3B3B3B;	
    height: 30px;
    font-size: 12px;
    border-bottom: solid 1px #e0e0e0;
    margin-top: -7px;
}

#profile .editEmail{
    font-size: 12px;
    color: #1a1818;
    border-bottom: 0;
    margin-top: -7px;
}

#profile .emailDiv{
    font-size: 12px;
    opacity: 0.29;
    border-bottom: solid 1px #e0e0e0;
}

#profile .editPhone{
    font-size: 12px;
    color: #1a1818;
    border-bottom: solid 1px #e0e0e0;
    margin-top: -7px;
}

#profile .label{
    text-align: left;
    font-size: 10px;
    color: #a8a8a8;
}

.name ::selection {
    color: red; 
    background: yellow;
}

#profile .email {
    color: #3B3B3B;	
    font-size: 15px;	
    font-family: Nunito;
    opacity: 0.5;
}

#profile .phone {
    color: #3B3B3B;	
    font-size: 20px;	
    font-family: Nunito;
    opacity: 0.9;
    border: 0px;
    text-align: center;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

#profile input {
    height: 25px;
    width: 300px;
    font-size: 17px;
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid rgb(138, 138, 138);
    padding-left: 10px;
    font-family: Nunito;
    outline: none;
}

#profile .pass_button {
    height: 40px;
    width: 200px;
    font-size: 15px;
    border-radius: 5px;
    font-family: Nunito;
    outline: none;
    background-color: rgb(74, 144, 226);
    color: white;
}

.password_container {
    border-top: 1px rgb(209, 209, 209) solid;
}

#profile .dataHead{
    font-size: 13px;
    font-weight: bold;
    color: #1a1818;
}

#profile .dataValues{
    font-size: 13px;
    color: #3b3b3b;
}

#toggleButton .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
    margin-bottom: 0;
}
  
#toggleButton .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

#toggleButton .slider {
    position: absolute;
    cursor: pointer;
    height: 16px;
    width: 26px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d8d8d8;
    -webkit-transition: .4s;
    transition: .4s;
}

#toggleButton .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: #558dca;
    -webkit-transition: .4s;
    transition: .4s;
}

#toggleButton input:checked + .slider {
    background-color: #558dca;
}

#toggleButton input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

#toggleButton input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -14px;
    background-color: white;
}

#toggleButton .slider.round {
    border-radius: 34px;
    margin-top: 2px;
}
  
#toggleButton .slider.round:before {
    border-radius: 50%;
}

#profile #dashboard-nav{
    height: 120px;
    margin-top: 0.375rem !important;
    padding-top: 5px !important;
    z-index: 2;
}

#profile .myRolesLabel, .myApartmentsLabel, .defaultApartmentLabel{
    font-size: 10px !important;
    color: #a8a8a8 !important;
    font-weight: normal !important;
    padding-top: 15px;
}

#profile .editMyRoles, .editMyApartments{
    font-size: 12px !important;
    border-bottom: solid 1px #e0e0e0;
    color: #1a1818;
    opacity: 0.29;
}

#profile .cancelButton{
    height: 32px;
    width: 81px;
    font-size: 12px;
}

#profile .modalDropdown{
    background-position: 100% !important;
}

@media (max-width: 576px){    
    .camera {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        overflow: hidden;
    }
    .camera_button {
        margin-top: -65px;
        margin-left: 110px;
    }
    #profile input {
        height: 25px;
        width: 90%;
        font-size: 18px;
    }
    #profile .password {
        height: 40px;
        width: 70%;
        font-size: 18px;
    }
}

@media (max-width: 768px){
    #profile .userDiv{
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: solid 1px #efefef;
        padding-bottom: 10px;
        min-height: auto;
    }
    #profile .userDetailsDiv{
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-height: auto;
    }
    #profile .profileDiv{
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
        margin-top: 110px;
        z-index: 999;
    }
    #profile .user_image{
        margin-top: -100px;
    }
    #profile .editImage{
        margin-top: -49px;
    }
    #profile .editButton{
        margin-right: -10px;
        font-size: 20px;
        text-align: right;
        margin-bottom: 14px;
    }
    #profile .label{
        text-align: left;
        font-size: 10px;
        color: #a8a8a8;
    }
    #profile .myRolesLabel, .myApartmentsLabel{
        font-size: 10px !important;
        color: #a8a8a8 !important;
        font-weight: normal !important;
        padding-top: 15px;
    }
    #profile .editMyRoles, .editMyApartments{
        font-size: 12px !important;
        border-bottom: solid 1px #e0e0e0;
        color: #1a1818;
        opacity: 0.29;
    }
}