.details-header {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.details-label {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: #5a5a5a;
}

.details-description {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #a4a4a4;
}
@media (max-width: 990px) {
  .details-header {
    margin-top: 20px;
  }
  .details-label {
    font-size: 28px;
  }
  .details-description {
    font-size: 14px;
    padding: 6px 0px 16px;
  }
}
