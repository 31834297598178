.ComponentCard{
    min-height: 140px;	
    width: 100%;
    border-radius: 5px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding: 15px;
    padding-left: 22px;
    margin-top: 30px;
}
.ComponentHead{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 800;	
    line-height: 31px;
}
.EquipmentItem{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 10px;	
    line-height: 14px;
}
.EquipmentStatus{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 14px;	
    line-height: 19px;
    width: 100%;
}
.LastUpdatedDate{
    color: #A6A6A6;	
    font-family: 'Nunito';	
    font-size: 10px;	
    line-height: 14px;
}
.pencil{
    height: 10px;
    width: 10px;
    margin-bottom: 1px;
}
.pencilButton{
    background: none;
    border: none;
    outline: none !important;
}

#SystemStatus .modal-content{
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 305px !important;
}
.modalHeader{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 14px;	
    font-weight: 800;	
    line-height: 19px;
}
.modalDropdown{
    width: 200px;
    height: 30px;
    border : 0px;
    overflow:hidden;
    font-size: 13px;
    outline: none !important;
    font-family: 'Nunito';
    cursor: pointer;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    border-radius: 0px !important;
    border-bottom: 2px solid #4A90E2;
    background-color: white;
}

.sysred {
    height: 10px;
        width: 10px;
        border-radius: 7px;
        background-color: red;
        margin-top: 7px;
    }
    .sysgreen {
    height: 10px;
        width: 10px;
        border-radius: 7px;
        background-color: #79d140;
        margin-top: 7px;
    }
    .sysamber {
    height: 10px;
        width: 10px;
        border-radius: 7px;
        background-color: #ffbf00;
        margin-top: 7px;
    }
    .modalSubhead{
        color: #4A90E2;	
        font-family: 'Nunito';	
        font-size: 10px;	
        line-height: 14px;
        margin-top: 10px;
        font-weight: 800;
    }

    #SystemServies .modal-content {
        padding-left: 25px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 25px;
        margin-top: 50px;
        margin-bottom: 100px;        
      }
      

      #SystemServies .modal {
        padding-left: 0px !important;
      }

      .serviceEdit{
          border-bottom: 1px solid #D5D5D5;
          width: 90%;
      }

      #history {
        height: 0px;
        overflow: hidden;
      }

      #history.expand {
        height: auto;
      }

      .historyButton{
        color: #4A90E2;
        font-family: 'Nunito';	
        font-size: 14px;
    }


      @media (min-width: 768px){        
        .modalFirstHalf{
            width: 400px;
        }
        .rightBorder {
            border-right: 1px solid #979797;
        }
        
    }


    @media (max-width: 576px){
       
    #SystemServies .modal-content {
        padding-left: 25px;
        padding-top: 25px;
        padding-bottom: 45px;
        padding-right: 0px;
        margin-top: 25px;
        margin-bottom: 50px;
      }
        
    }

    #Service .react-datepicker-wrapper{
        width: 100%;
    }

    #Service .react-datepicker__input-container{
        width: 100%;
    }

    #Service .react-datepicker-wrapper{
        width: 100%;
    }