#settings {
  font-family: 'Nunito';
  padding-bottom: 1rem;
}
#settings .component-sidebar {
  position: absolute;
  top: calc(5rem + 7px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 99;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#settings .component-sidebar.open {
  left: 0;
}
#settings .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#settings .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  flex: 1;
}
#settings .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
}
#settings .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#settings .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#settings .settings-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#settings .settings-header p span {
  text-decoration: underline;
}
#settings .settings-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#settings .settings-body .systems {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#settings .settings-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#settings .settings-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#settings .settings-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#settings .settings-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#settings .settings-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#settings .settings-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#settings .settings-body .settings-card-header {
  padding: 0.875rem 0rem;
  border-bottom: 1px solid #dadada;
}
#settings .settings-card .settings-card-header span.header-helper {
  float: right;
  margin-left: auto;
  color: #558dca;
  font-size: 1rem;
  margin-top: 4px;
}
#settings .settings-card .settings-card-header .search-component {
  float: right;
  margin-left: auto;
  margin-top: 4px;
}
#settings .settings-body .settings-card-body {
  padding: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.5fr));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}
#settings .settings-card .card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
  transition: all ease 450ms;
}
#settings .settings-card .card:hover {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
}
#settings .settings-card .card .card-subtitle {
  font-size: 0.875rem;
  color: #9d9d9d;
}
#settings .settings-card .card .card-subtitle a {
  color: #558dca !important;
}
#settings .settings-card .card .card-text {
  font-size: 0.875rem;
}
#settings .settings-card .card .buttons {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
}
#settings .settings-card .card .buttons button {
  font-size: 1.125rem;
  background-color: transparent;
  border: 0;
}
#settings .settings-card .card .btn-del {
  color: #ff6a6a !important;
  text-decoration: underline;
  font-size: 0.75rem;
  cursor: pointer;
  line-height: 1.875rem;
}
#settings .settings-card .card .card-footer {
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  border: 0;
}
#settings .settings-card .card .roles > div, .profile .roles > div {
  border: 0 !important;
  border-bottom: 1px solid #eaeaea !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
#settings .settings-card .card .roles ul li, .profile .roles ul li {
  padding: 0.5rem 1rem !important;
  cursor: pointer;
}
#settings .settings-card .card .roles ul li:hover, .profile .roles ul li:hover {
  background-color: #2c2c2c !important;
  color: #ffffff;
}
#settings .settings-card .profile-img, .profile .profile-img {
  position: relative;
}
#settings .settings-card .profile-img label, .profile .profile-img label {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all ease 300ms;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
#settings .settings-card .profile-img label::after, .profile .profile-img label::after {
  content: 'Change';
  position: absolute;
  font-size: 0.625rem;
  font-weight: bold;
  color: #2c2c2c;
  top: calc(50% - 5px);
}
#settings .settings-card .profile-img:hover label, .profile .profile-img:hover label {
  opacity: 1;
}

.settings-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.settings-card .btn-primary:hover, .settings-card .btn-primary:active, .settings-card .btn-primary:focus {
  background-color: #3b76b6;
  border-color: #3b76b6;
}
.settings-card .text-danger {
  color: #ff6a6a !important;
}
.settings-card .text-muted {
  color: #9d9d9d !important;
}
#settings .table {
  font-size: 0.875rem;
}
#settings .table thead th {
  color: #b7b7b7;
  border-top: 0;
}
.tb-modal-header, .tb-modal-footer {
  border: 0;
}
.tb-modal-header .close {
  color: #ff6a6a;
}
.tb-modal-title {
  font-weight: bold;
  font-family: 'Nunito';
}
.tb-modal-body {
  padding: 0 1rem;
  font-family: 'Nunito';
}
.tb-modal-footer {
  font-family: 'Nunito';
  border: 0;
}
.tb-modal-footer .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.tb-modal-footer .btn-primary:hover, .reading-card .btn-primary:active, .reading-card .btn-primary:focus {
  background-color: #3b76b6;
  border-color: #3b76b6;
}

#settings .table button:disabled {
  opacity: 0.4;
}
#settings .table button .icon-pencil {
  font-size: 1rem;
}
#settings .altText, .profile .altText{
  height: 50px;
  width: 50px;
  color: white;
  border-radius: 50%;
  font-weight: 700;
  cursor: pointer;
  background: linear-gradient(135deg, #558dca 0%, #558dca 48%, #67a1df 53%, #67a1df 100%);
}

.profile {
  font-family: 'Nunito';
}
.profile .col-form-label {
  font-size: 0.75rem;
  color: #9d9d9d;
  display: block;
  margin-bottom: 0.25rem;
  padding: 0;
  line-height: 1.1;
}
.profile p {
  font-size: 0.875rem;
  color: #212529;
  line-height: 1.1;
  margin-bottom: 0;
}
.profile .helper {
  font-size: 0.675rem;
  color: #9d9d9d;
  line-height: 1.1;
}
.profile input {
  outline: 0 !important;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  padding: 0;
  font-size: 0.875rem;
  color: #212529;
  display: block;
  width: 100%;
  line-height: 1.1;
}
.profile input:focus, .profile input:active {
  border-color: #558dca;
}
.profile select {
  display: block;
  width: 100%;
  border-color: #eaeaea;
  font-size: 0.875rem;
  line-height: 1.1;
}

@media (min-width: 769px) {
  #settings {
    padding: 2.5rem 2rem 1rem;
  }
  #settings .settings-header h4 {
    line-height: 2rem;
  }
  #settings .settings-body {
    padding-top: 1rem;
  }
  #settings .settings-card.card {
    min-height: 100%;
  }
}
@media (max-width: 768px) {
  #settings .settings-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.375rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 3;
  }
  #settings .settings-header h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  #settings .settings-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #settings .settings-header p span {
    text-decoration: none;
  }
  #settings .settings-body {
    margin-right: 0;
    margin-left: 0;
  }
  #settings .settings-body .settings-card-body {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  #settings .settings-body .card-header {
    padding: 1.5rem 1.25rem 1.5rem;
  }
  #settings .settings-body .card-header h5 {
    font-size: 1.125rem;
  }
  #settings .settings-card.card .card-header span.header-helper {
    font-size: 0.875rem;
  }
  #settings .settings-body .profile-types {
    background-color: #3b3b3b;
    margin-top: -0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: sticky;
    top: calc(2rem + 30px - 0.625rem + 1rem + 24px - 0.625rem);
    z-index: 2;
  }
  #settings .settings-body .profile-types .list-group {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #settings .settings-body .profile-types .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #settings .settings-body .profile-types .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #settings .settings-body .settings-card-header h5 {
    font-size: 1.125rem;
  }
  #settings .settings-body .profile-types .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  #settings table, #settings thead, #settings tbody, #settings th, #settings td, #settings tr { 
    display: block; 
    width: 100%;
    height: auto !important;
    text-align: left !important;
  }
  #settings table thead { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  #settings table tr {
    position: relative;
    padding: 0.75rem 0;
    border-bottom: 1px solid #efefef;
  }
  #settings table td { 
		border: none;
    position: relative;
    padding: 0.375rem;
		padding-left: 40% !important; 
  }
  #settings td:nth-of-type(5) {
    position: absolute;
    top: 0.25rem;
    right: 0;
    padding: 0 !important;
    width: max-content;
  }
  #settings table td:before { 
		position: absolute;
		left: 0.5rem;
		width: 35%; 
		padding-right: 5%; 
		white-space: nowrap;
    color: #b7b7b7;
  }
  #settings td:nth-of-type(1):before { content: "Full Name"; }
	#settings td:nth-of-type(2):before { content: "Roles"; }
	#settings td:nth-of-type(3):before { content: "Email"; }
	#settings td:nth-of-type(4):before { content: "Phone"; }  
}