.sysName{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    line-height: 14px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.sysGreen{
    height: 10px;	
    width: 10px;	
    border-radius: 5px;
    background-color: #64BE00;
}
.sysRed{
    height: 10px;	
    width: 10px;	
    border-radius: 5px;
    background-color: red;
}
.sysAmber{
    height: 10px;	
    width: 10px;	
    border-radius: 5px;
    background-color: #ffbf00;
}
.sysStatus{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 14px;	
    line-height: 19px;
}

.sysBottom{
    color: #A6A6A6;	
    font-family: 'Nunito';	
    font-size: 10px;	
    line-height: 14px;
}
