/* table.exe-table {
  font-family: Inter;
  border-collapse: collapse;
  width: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: #ffffff;
}

table.exe-table th {
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #b6b6b6;
  padding: 22px 24px;
}

.exe-details-header-container {
  margin: 15px 0px;
  padding: 16px 0px;
}

.btn-white {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 13px 16px;
}
.bgc {
  background-color: red;
} */
.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}

table.child-table-border-separate {
  /* border-collapse: collapse !important; */
  border-collapse: separate !important;
  border-spacing: 0px 5px !important;
}

@media (max-width: 990px) {
  .back-button {
    margin-top: -20px;
  }
}

/* modal styles */
.modal-content {
  margin-top: 160px;
  border-radius: 8px;
}
.modal-body {
  padding: 0px;
}
.tableFixHead tbody tr td {
  border-bottom: 1px solid #e8e8e8;
}
.tableFixHead thead th {
  background: #ffffff !important;
  border-bottom: 1px solid #e8e8e8;
}