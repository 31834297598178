.reading-card {
  transition: all ease 300ms;
  position: relative;
}
.reading-card .card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
  height: 100%;
  transition: all 450ms ease;
}
.reading-card .card:hover {
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.13);
}

.reading-card .card .card-header {
  background-color: #ffffff;
  padding: 1.25rem;
  border-bottom-color: #eaeaea !important;
  border-top: 3px solid transparent;
}
.reading-card .card .card-header h5 {
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0;
  display: inline-block;
  align-items: center;
}
.reading-card .card .card-header p {
  font-size: 0.875rem;
  line-height: 1;
}
.reading-card .card .card-header .badge-indicator {
  width: 1rem;
  height: 1rem;
  background-color: #a8a8a8;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: -0.125rem;
}
.reading-card .card .card-header span.icon {
  font-size: 0.375rem;
  margin-left: auto;
  transform-origin: center;
  transition: transform 300ms ease;
}
.reading-card .card .card-header span.icon.open {
  transform: rotate(180deg);
}
.reading-card .card .card-header .edit-icon {
  font-size: 1.25rem;
  margin-top: -0.5rem;
}
.reading-card .card .card-body {
  font-size: 0.8125rem;
  color: #a8a8a8;
  position: relative;
}
.reading-card .card .card-body p {
  display: flex;
  align-items: center;
  height: 1.625rem;
}
.reading-card .card .card-body .time-widget.edit {
  margin-bottom: 2.5rem !important;
  position: relative;
}
.reading-card .card .card-body .badge-indicator,
.modal .badge-indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #a8a8a8;
  border-radius: 0.125rem;
  display: inline-block;
  margin-right: 0.5rem;
  align-self: center;
}
.reading-card .card .card-body p .progress-side {
  width: 50%;
  margin-bottom: 0.25rem;
}
.reading-card .card .card-body p .progress-side .progress {
  width: 100%;
  height: 0.625rem;
  border-radius: 25rem;
}
.reading-card .card .card-body p .progress-side .progress .progress-bar {
  height: 0.625rem;
  border-radius: 25rem;
  margin-right: 0;
}
.reading-card .card .card-body .sub {
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  margin-top: -0.25rem;
}
.reading-card .edit-body {
  display: flex;
  align-items: center;
}
.reading-card .time-widget .edit-body {
  flex-direction: column;
  align-items: flex-end;
}
.reading-card .edit-body input {
  text-align: center;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  color: #a8a8a8;
  line-height: 1;
  outline: none !important;
  box-shadow: none !important;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.reading-card .edit-body input:focus {
  border-bottom-color: #558dca;
}
.reading-card .edit-body input[type="datetime-local"] {
  margin-top: 0.5rem;
  margin-right: 0;
  position: absolute;
  top: 1rem;
}
.reading-card .card .card-body select.edit-body {
  border-color: #eaeaea;
}
.reading-card .edit-body span.helper {
  cursor: pointer;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b7b7b7;
  color: #6e6e6e;
  font-weight: bolder;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.reading-card .edit-body span.helper:hover {
  background-color: #eaeaea;
}
.reading-card .card .card-body .overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reading-card .btn-tab {
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: bold;
  line-height: 1.75;
  outline: none !important;
  box-shadow: none !important;
  color: #a5a5a5;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}
.reading-card .btn-tab:hover {
  color: #3d3d3d;
}
.reading-card .btn-tab.active {
  color: #3d3d3d;
  border-bottom-color: #558dca;
}
.reading-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.reading-card .btn-primary:hover, .reading-card .btn-primary:active, .reading-card .btn-primary:focus {
  background-color: #3b76b6;
  border-color: #3b76b6;
}
.reading-card .text-muted {
  color: #9d9d9d !important;
}

.reading-card .graph-sidebar {
  position: absolute;
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1;
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-left: 0;
}
.reading-card .graph-sidebar.open {
  left: 0;
}
.reading-card .graph-sidebar .close {
  font-size: 0.625rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.reading-card .graph-sidebar .list-group {
  border-radius: 1rem;
  color: #a5a5a5;
  font-size: 0.75rem;
  flex: 1;
}
.reading-card .graph-sidebar .list-group .list-group-item {
  border: 0;
  background-color: #ffffff !important;
  border-bottom: 2px solid;
  border-color: transparent;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  font-weight: bold;
  color: #a5a5a5;
  cursor: pointer;
}
.reading-card .graph-sidebar .list-group .list-group-item.active {
  z-index: auto;
  color: #3d3d3d;
  border-color: #558dca;
}
.reading-card .graph-sidebar .list-group .list-group-item:hover {
  color: #3d3d3d;
}

@media (max-width: 768px) {
  .reading-card .card .card-header {
    display: flex;
    align-items: center;
  }
  .reading-card .card .card-header h5 {
    font-size: 0.875rem;
    width: 8.5rem;
  }
  .reading-card .card .card-header .badge-indicator {
    margin-left: 0.5rem;
  }
  .reading-card .card .card-header p {
    font-size: 0.625rem;
    margin-left: 0.5rem;
  }
  .reading-card .card .card-body p {
    font-size: 0.75rem;
  }
}

.transient-edit {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 1.25rem;
  background-color: #ffffff;
  position: absolute;
  inset: 0 0 0 100%;
  opacity: 0;
  overflow: hidden;
  transition: all ease 0.4s;
}
.transient-edit.active {
  inset: 0;
  opacity: 1;
}

.history-block {
  background-color: #f9f9f9;
  padding-bottom: .5rem;
  text-align: center;
}
.history-block .helper {
  display: block;
  text-align: center;
  font-size: 0.7rem;
  margin-top: 1rem;
}
.timeline-description-input {
  width: 90px;
  padding: 0 0 0.125rem;
  border-radius: 0;
  height: auto;
  line-height: 0.75rem;
  font-size: 11px;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 0.125rem;
  outline: none !important;
  box-shadow: none !important;
}
.timeline .icon-close,
.timeline .icon-tick {
  font-size: 9px;
  margin-top: -2px;
}
.button-radio {
  display: inline-flex;
  margin-right: 0.5rem;
}
.button-radio label {
  padding: 0.125rem 0.5rem;
  border: 1px solid var(--gray);
  color: var(--gray);
  border-radius: 4px;
}
.button-radio label:hover {
  background-color: #fafafa;
}
.button-radio input[type="radio"]:checked + label {
  background-color: var(--gray);
  border-color: var(--gray);
  color: #ffffff;
}
.history-descriptions .form-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ced4da;
  outline: none;
  box-shadow: none;
  padding: 0.125rem 0;
  line-height: 1.5;
  height: calc(1.5rem + 0.125rem + 1px);
}
.reading-card .edit-body input.rbt-input-main,
.reading-card .edit-body input.rbt-input-hint {
  width: auto;
  margin: 0;
  font-size: 0.875rem;
  border-radius: 0;
  height: calc(0.965rem + 0.25rem + 2px);
  text-align: left;
}
.reading-card .edit-body input.rbt-input-hint {
  color: #9d9d9d;
}
.reading-card .rbt-menu {
  padding: 0.25rem 0;
  font-size: 0.8rem;
}
.reading-card .rbt-menu a.reading-card .rbt-menu a {
  padding: 0.25rem 0.5rem;
  line-height: 1.1;
}