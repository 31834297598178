#staff-attendance {
  font-family: 'Nunito';
  padding-bottom: 1rem;
}
#staff-attendance .component-sidebar {
  position: absolute;
  top: calc(6rem + 3px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#staff-attendance .component-sidebar.open {
  left: 0;
}
#staff-attendance .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#staff-attendance .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  flex: 1;
}
#staff-attendance .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  cursor: pointer;
}
#staff-attendance .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#staff-attendance .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#staff-attendance .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#staff-attendance .system-header p span {
  text-decoration: underline;
}
#staff-attendance .system-header .filter-button {
  height: 26px;
}
#staff-attendance .system-header .filter-dropdown {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  opacity: 0;
  font-size: 0.75rem;
}
#staff-attendance .system-header .filter-dropdown::before {
  content: '\e927';
  font-family: 'icomoon';
  font-size: 24px;
  height: 24px;
}
#staff-attendance .example-custom-input {
  font-size: 0.875rem !important;
  font-weight: 700;
  color: #3b3b3b;
  width: 120px;
  text-align: center;
  padding-right: 28px;
  margin-top: 3px;
}
#staff-attendance .system-header .react-datepicker-wrapper {
  font-size: 0.875rem;
  width: 120px;
}
#staff-attendance .react-datepicker__triangle {
  left: calc(100% - 50px);
}
#staff-attendance .system-body .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#staff-attendance .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#staff-attendance .system-body .systems {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#staff-attendance .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#staff-attendance .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#staff-attendance .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#staff-attendance .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#staff-attendance .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#staff-attendance .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#staff-attendance .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#staff-attendance .system-card .header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
}
#staff-attendance .system-card .header h5 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0;
}
#staff-attendance .system-card .tab-headers {
  display: flex;
}
#staff-attendance .system-card .tab-headers button {
  border: 0;
  flex: 1;
  background-color: #ffffff;
  text-align: left;
  font-weight: 400;
  font-size: 1.25rem;
  padding: 1rem 1.5rem calc(1rem - 0.375rem);
  border-bottom: 0.375rem solid #eaeaea;
  letter-spacing: 0.5px;
}
#staff-attendance .system-card .tab-headers button.active {
  font-weight: 700;
}
#staff-attendance .table {
  font-size: 0.875rem;
  line-height: 1.2;
  table-layout: fixed;
  margin-bottom: 0;
}
#staff-attendance .table th {
  border: 0;
  color: #b7b7b7;
  padding: 1rem 0;
}
#staff-attendance .table td {
  vertical-align: middle;
  padding: 1rem 0;
  color: #3b3b3b;
}
#staff-attendance .helper {
  font-size: 0.75rem;
  color: #b7b7b7;
  margin-top: 0.25rem;
  padding-left: 0.75rem;
}
#staff-attendance .badge-indicator {
  width: 0.625rem;
  height: 0.625rem;
  background-color: #a8a8a8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.25rem;
}
#staff-attendance .user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-right: 0.625rem;
  font-weight: 700;
  font-size: 1rem;
  background:  linear-gradient(135deg, #558dca 0%, #558dca 48%, #67a1df 53%, #67a1df 100%);
}

#staff-attendance .accordion {
  width: 100%;
}
#staff-attendance .accordion .card {
  border: 0
}
#staff-attendance .accordion .card-header,
#staff-attendance .accordion .card-body {
  padding: 0;
}
#staff-attendance .accordion .card-header {
  background-color: #ffffff;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eaeaea;
}

#staff-attendance .accordion .card-header .icon-chevron-down {
  font-size: 0.5rem !important;
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.3);
  transition: transform 400ms ease;
  display: inline-block;
}
#staff-attendance .accordion .card-header .icon-chevron-down.open {
  transform: rotate(180deg);
}

@media (min-width: 769px) {
  #staff-attendance {
    padding: 2.5rem 2rem 1rem;
  }
  #staff-attendance .system-header h4 {
    line-height: 2rem;
  }
  #staff-attendance .system-body {
    padding-top: 1rem;
  }  
}
@media (max-width: 768px) {
  #staff-attendance .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999;
  }
  #staff-attendance .system-header .filter-button {
    background-color: transparent;
    color: #ffffff;
    border-radius: 50%;
    font-size: 60%;
    height: 20px;
    margin-top: 4px;
  }
  #staff-attendance .example-custom-input {
    color: #ffffff;
    margin-top: 2px;
  }
  #staff-attendance .system-header h4 {
    font-size: 1.25rem;
    line-height: 1;
  }
  #staff-attendance .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #staff-attendance .system-header p span {
    text-decoration: none;
  }
  #staff-attendance .system-header .subtitle {
    color: #a2a2a2;
    font-size: 0.625rem;
  }
  #staff-attendance .system-header .btn-icon {
    font-size: 0.875rem;
    line-height: 1;
    padding: 5px 6px;
  }
  #staff-attendance .system-header .btn-outline-secondary {
    border-color: #ffffff;
    color: #ffffff;
    font-size: 0.625rem;
    padding: 0.25rem 0.75rem;
  }
  #staff-attendance .system-header .btn-outline-secondary:hover,
  #staff-attendance .system-header .btn-outline-secondary:active,
  #staff-attendance .system-header .btn-outline-secondary:focus {
    background-color: #ffffff;
    color: #3b3b3b;
  }
  #staff-attendance .system-body {
    margin-top: 10.5rem;
    margin-right: 0;
    margin-left: 0;
  }
  #staff-attendance .system-body .system-names {
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 1rem + 40px - 0.625rem);
    z-index: 2;
  }
  #staff-attendance .system-body .system-names .list-group {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #staff-attendance .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #staff-attendance .system-body .system-names .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #staff-attendance .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  #staff-attendance .system-body .card-body {
    padding: 0;
  }
  #staff-attendance .system-card .tab-headers button {
    font-size: 0.875rem;
  }
  #staff-attendance .system-card .tab-headers.sticky-top {
    top: 161px !important;
  }
  #staff-attendance .accordion .card-header {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  #staff-attendance .table, 
  #staff-attendance .table thead, 
  #staff-attendance .table tbody { 
    display: block;
    height: auto !important;
    text-align: left !important;
  }
  #staff-attendance .table {
    padding: 0 0.75rem;
  }
  #staff-attendance .table thead { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  #staff-attendance .table tr {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    border-bottom: 1px solid #efefef;
  }
  #staff-attendance .tb-modal-body .table tr {
    padding-bottom: 0;
    padding-top: 0.75rem;
  }
  #staff-attendance .table tr:last-of-type {
    border-bottom: 0;
  }
  #staff-attendance .table td { 
		border: none;
    position: relative;
    display: inline-block !important;
    padding: 0;
    font-size: 13px;
    padding-top: 1.125rem;
    width: 30%;
    order: 3;
    white-space: nowrap;
  }
  #staff-attendance .table td:nth-of-type(1) {
    width: 70%;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
    order: 1;
  }
  #staff-attendance .table td:nth-of-type(5) {
    width: 30%;
    padding-top: 1.625rem;
    padding-bottom: 0.75rem;
    order: 2;
    white-space: nowrap;
    font-style: italic;
    font-weight: 600;
  }
  #staff-attendance .tb-modal-body .table td:nth-of-type(1),
  #staff-attendance .tb-modal-body .table td:nth-of-type(2) {
    width: 30%;
  }
  #staff-attendance .tb-modal-body .table td:nth-of-type(4) {
    display: none !important;
  }
  #staff-attendance .table td:before { 
    position: absolute;
    top: 0;
		white-space: nowrap;
    color: #b7b7b7;
    font-size: 13px;
    content: attr(data-attr);
  }
}