.trak-bot-avatar {
    img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
    .initial {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin-right: 0.625rem;
        font-weight: 700;
        // font-size: 1rem;
        background:  linear-gradient(135deg, #558dca 0%, #558dca 48%, #67a1df 53%, #67a1df 100%);
    }
}