.widget-header-container {
    padding: 16px 16px;
    border-bottom: 1px solid #E8E8E8;
}
.widget-header {
    display: flex;
    flex-direction: column;
}
.widget-label {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #5A5A5A;
}

.widget-description {
    font-size: 14px;
    line-height: 17px;
    color: #A4A4A4;
}

.widget-header .disabled {
    color: #5A5A5A;
}