
#incidents {
    /* font-size: 13px; */
    // padding: 5rem 1rem;
    padding-top: 8.75rem;
    background-color: #eef2fa;
    font-family: 'Nunito';
    @media (min-width: 769px) {
        padding: 2.5rem 2rem;
        background-color: #fafafa;
    }
    .status-track {
        .status-radio-btn {
            font-size: 14px;
            label {
                margin-bottom: 0;
                display: block;
                padding: .5rem 1rem;
                border-radius: 0.25rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 700;
                color: #fff;
                opacity: 0.5;
                white-space: normal;
                cursor: pointer;
                .count {
                    font-weight: 700;
                    background-color: #fff;
                    border-radius: 50%;
                    height: 2.5rem;
                    width: 2.5rem;
                    padding: .5rem;
                    text-align: center;
                    margin-left: 1rem !important;
                }
            }
            input[type='checkbox'] {
                display: none;
                &:checked +label {
                    // border: 1px solid #558dca;
                    // background-color: #558dca;
                    // color: #fff;
                    opacity: 1;
                }
            }
        }
        @media (max-width: 565px) {
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow-x: scroll;
           
            .col-6 {
                flex: none !important;
                width: unset !important;
                max-width: unset !important;
                &:not(:last-child) {
                    padding-right: 0 !important;
                }
            }
            
        }
    }
    .filter-input-active {
        padding-left: 0;
    }
    .age-filter {
        width: unset;
        border: 0;
        background-color: transparent;
        @media (max-width: 565px) {
            flex: 0 0 100%;
            order: -1
        }
    }

    .priority-select {
        border: 2px solid #000;
        border-radius: 40px;
        font-size: unset;
        font-weight: 700;
        background-position: 85%;
        padding: .365rem .75rem;
        width: unset;
        min-width: 100px;
        option{
            padding: 2px !important;
            color: black;
        }
    }
    @media (max-width: 565px) {
        .category {
            border: 1px solid #cdcdcd;
        }
    }

    .tag-track{
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: scroll;
    }
    .tb-tag {
        border: 1px solid #cdcdcd;
        border-radius: 3px;
        padding: .25rem 0.75rem;
        font-weight: 600;
        i {
            opacity: 0.7;
            &:hover {
                opacity: 1;
            }
        }
    }

    @media (max-width: 768px) { 
        .incident-header {
            position: fixed;
            background: #3b3b3b;
            color: #fff;
            margin: 0 !important;
            width: 100%;
            z-index: 999;
            padding: 0 1rem;
            top: 62px;
            h1 {
                font-size: 1.25rem;
                font-weight: 600 !important;
            }
            .add-incident-btn {
                border-radius: 50% !important;
                padding: 0;
                line-height: 1.25;
                height: 38px;
                width: 38px;
                font-size: 1.25rem;
            }
        }
    }

    .nav-tabs.incident-tab {
        .nav-link {
            color: #c5c5c5;
            border: 0;
            padding: .5rem 0rem;
            margin-right: 1rem;
            font-weight: 600;
            &.active {
                color: #000;
                font-weight: 700;
                background-color: transparent;
                border-bottom: 3px solid #000;
            }
        }

        @media (max-width: 768px) {
            position: fixed;
            background-color: #3b3b3b;
            top: 100px;
            width: 100%;
            padding: 0 1rem;
            padding-bottom: .5rem;
            border-bottom-right-radius: 0.625rem;
            border-bottom-left-radius: 0.625rem;
            margin-left: -1rem;
            z-index: 999;
            .nav-link {
                font-size: 14px;
                font-weight: 500 !important;
                color: #b9b9b9 !important;
                &.active {
                    color: #fff !important;
                    border: 0 !important;
                    font-weight: 600 !important;
                }
            }
        }

    } 
    
}

#incidents .nav-tabs.incident-tab .nav-link {
    color: #c5c5c5;
    border: 0;
    padding: .5rem 0rem;
    margin-right: 1rem;
    font-weight: 600;
}

#incidents .nav-tabs.incident-tab .nav-link.active {
    color: #000;
    font-weight: 700;
    background-color: transparent;
    border-bottom: 3px solid #000;
}

#incidents .count-card {
    border-radius: 0.25rem;
}

#incidents .card-header {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    color: #000;
    font-size: 16px !important;
    padding: 1rem;
    i {
        font-size: 0.5rem;
        color: rgba($color: #000000, $alpha: 0.3);
    }
}

.incidents-table.table {
    font-size: 0.875rem;
    table-layout: fixed;
    // border-collapse: initial;
    // border-spacing: 0 8px;
    thead tr {
        background-color: #fff;
        color: #b7b7b7;
        border-top: 1px solid #ececec;
        th {
            border: 0;
            padding: .75rem .5rem;
            background-color: #eaeaea;
            color: #7e7e7e;
            text-transform: uppercase;
        }
    }
    tbody tr {
        background-color: #fff;
        // box-shadow: 0 0 18px 0 rgba(0,0,0,.03);
        // border-top: 1px solid #ececec;
        cursor: pointer;
        transition: all .45s ease;
        // &:hover {
        //     box-shadow: 0 1px 18px 0 rgba(0,0,0,.13);
        // }
    }
    td {
        border-color: #ececec;
        // border: 0;
        padding: .5rem;
        vertical-align: middle;
        color: #000;
        // :nth-child(1) { width: 10%; }
        // :nth-child(1) { width: 10%; }
        // :nth-child(1) { width: 10%; }
        // :nth-child(1) { width: 10%; }
        // :nth-child(1) { width: 10%; }
    }
    .priority {
        border: 2px solid #000;
        border-radius: 40px;
        min-width: 100px;
        text-align: center;
        padding: .335rem .75rem;
        font-weight: 700;
    }

    @media (max-width: 565px) {
        display: block;
        background-color: #fff;
        border-top: 1px solid #cdcdcd;
        thead {
            display: none !important;
        }
       
        tbody tr {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #cdcdcd;
            border-top: 3px solid;
            // margin: 0.35rem 0;
            border-radius: 0.25rem;
            margin: 5px;
            padding: 0.5rem .75rem;
            width: calc(100vw - 44px);
        }
        td {
            padding: 0;
            display: block;
            border: 0 !important;
            flex: 0 0 100%;
            &:nth-child(1) { // Ticket number
                order: 2;
                flex: 0 0 35%;
                text-align: right;
                color: gray;
                font-weight: 600;
            }
            &:nth-child(2) { // Title
                order: 3;
                font-weight: 700;
                font-size: 1rem;
                // margin: 4px 0;
                padding-bottom: .6rem;
                border-bottom: 1px solid #cdcdcd !important;
                margin-bottom: 4px;
            }
            &:nth-child(3) { // Created date
                order: 0;
                flex: 0 0 65%;
                font-size: 88%;
                color: gray;
            }
            &:nth-child(4) { // Priority
                order: 8;
                flex: 0 0 20%;
                display: none;
            }
            &:nth-child(5) { // Avatar
                order: 6;
                flex: 0 0 60%;
                text-align: right;
                margin-top: 0.5rem;
                div {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                }
                img {
                    height: 30px;
                    width: 30px;
                }
            }
            &:nth-child(6) { // Assign status
                // text-align: right;
                order: 5;
                flex: 0 0 40%;
                color: gray;
                .status {
                    font-weight: 600;
                    // color: green;
                }
            }
        }
    }
}



.font-13 { font-size: 13px !important;}
.font-14 { font-size: 14px !important;}
.font-16 { font-size: 16px !important;}
.font-18 { font-size: 18px !important;}

.font-weight-400 { font-weight: 400 !important; }
.font-weight-500 { font-weight: 500 !important; }
.font-weight-600 { font-weight: 600 !important; }
.font-weight-700 { font-weight: 700 !important; }

.rotate {
    transform: rotate(180deg);
}

.btn-outline-primary {
    color: #558dca;
    border-color: #558dca;
    &:hover {
        color: #fff;
        background-color: #558dca;
        border-color: #558dca;
    }
}
.btn-primary {
    background-color: #558dca;
    border-color: #558dca;
}