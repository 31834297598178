#reports {
  font-family: "Nunito";
  padding-bottom: 1rem;
  height: 100%;
}
#reports .component-sidebar {
  position: absolute;
  top: calc(6rem - 2px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 99;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
}
#reports .component-sidebar.open {
  left: 0;
}
#reports .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#reports .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  flex: 1;
}
#reports .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
}
#reports .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#reports .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#reports .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#reports .system-header p span {
  text-decoration: underline;
}
#reports .system-body .card-header {
  background-color: #ffffff;
  padding: 1.5rem 1.25rem 2rem;
}
#reports .system-body .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#reports .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#reports .system-body .systems {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#reports .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#reports .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#reports .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#reports .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: "";
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#reports .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#reports .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#reports .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#reports .system-card.card .card-header span.header-helper {
  float: right;
  margin-left: auto;
  color: #558dca;
  font-size: 1rem;
  margin-top: 4px;
}
@media (min-width: 769px) {
  #reports {
    padding: 2.5rem 2rem 1rem;
  }
  #reports .system-header h4 {
    line-height: 2rem;
  }
  #reports .system-body {
    padding-top: 1rem;
  }
  #reports .system-card.card {
    min-height: 100%;
  }
}
@media (max-width: 768px) {
  #reports .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.375rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 2;
  }
  #reports .system-header h4 {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  #reports .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #reports .system-header p span {
    text-decoration: none;
  }
  #reports .system-body {
    margin-right: 0;
    margin-left: 0;
    margin-top: 6.15rem;
  }
  #reports .system-body .card-header {
    padding: 1.5rem 1.25rem 1.5rem;
  }
  #reports .system-body .card-header h5 {
    font-size: 1.125rem;
  }
  #reports .system-card.card .card-header span.header-helper {
    font-size: 0.875rem;
  }
  #reports .system-body .system-names {
    background-color: #3b3b3b;
    margin-top: -0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 55px - 0.625rem);
    z-index: 2;
  }
  #reports .system-body .system-names .list-group {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #reports .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #reports .system-body .system-names .list-group .list-group-item::after {
    content: "";
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;
  }
  #reports .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }

  /* ios iframe scroll issue fix */
  .system-card {
    height: 100%;
  }
  #mobile-iframe {
    height: 100%;
  }

  #mobile-iframe iframe {
    width: 100%;
    height: 100%;
    border-width: 0px;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .mobile {
    height: 80%;
  }
}
