.dashboard {
    margin-top: 2.5rem;
}
.dashboard .arrow-back, .dashboard .arrow-next{
  height: 1rem;
  width: 1.25rem;
}
.dashboard .arrow-back {
  transform: rotate(180deg);
}

button:focus {
    outline: none;
}
#dashboardCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    margin-right: 2.5rem;
    width: 235px;
    position: relative;
}
#dashboardCard:hover {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
}

#dashboard .daily_readings, #dashboard .system_statuses, #dashboard .task-row{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.5rem;
}

#dashboard_system_status #dashboardCard, #dashboard_staff_activities #dashboardCard, .attendance-row #dashboardCard>.d-flex {
    align-items: start;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: none;
}
#dashboard_daily_reading .daily_readings::before, #dashboard_system_status .system_statuses::before, #dashboard_staff_activities .task-row::before  {
    content: " ";
    min-width: 2.5rem;
}
#dashboard_daily_reading .daily_readings::after, #dashboard_system_status .system_statuses:last-child::after, #dashboard_staff_activities .task-row:last-child::after  {
    content: " ";
    min-width: 2.5rem;
}

#dashboard_daily_reading .label {
  font-family: 'Nunito';
  font-size: 1rem;
  color: #494949;
}
#dashboard_daily_reading .last_updated {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #a2a2a2;
}

#dashboard_daily_reading .icon {
    max-height: 3.75rem;
    font-size: 2.75rem;
    line-height: 1;
    color: #558dca;
    margin-bottom: 0.75rem;
    background-color: transparent;
}

#dashboard_system_status .label {
  font-family: 'Nunito';
  font-size: 1rem;
  color: #494949;
  margin-bottom: 1rem;
}
#dashboard_system_status .status {
    font-family: 'Nunito';
    font-size: 1rem;
    color: #494949;
    font-weight: bold;
  }
#dashboard_system_status .last_updated {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #a2a2a2;
}
#dashboard_system_status .updatedBy {
    font-family: 'Nunito';
    font-size: 0.75rem;
    color: #b2b2b2;
    margin-top: 1rem;
}
#dashboard_system_status .icon {
    max-height: 3.75rem;
    margin-bottom: 5px;
    background-color: transparent;
}

#dashboard_staff_activities .attendance-row {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    position: relative;
}
#dashboard_staff_activities .tasks .total{
    font-size: 1.125rem;
    color: #494949;
    font-weight: bold;
}
#dashboard_staff_activities .tasks .completed{
    font-size: 0.875rem;
    color: #a2a2a2;
}
#dashboard_staff_activities .label {
    font-family: 'Nunito';
    font-size: 1rem;
    display: block;
    text-align: left;
    padding-right: 2.5rem;
}
#dashboard_staff_activities .status {
    font-family: 'Nunito';
    font-size: 1.125rem;
    color: #494949;
}
#dashboard .tasks .label span {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    font-size: 2rem;
}
.navbar-mobile { 
    background-color: #3b3b3b;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    padding: 1rem 1.25rem;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2
}
.navbar-mobile .menu-button {
    cursor: pointer;
}
.navbar-mobile .menu-icons {
    font-size: 1.5rem;
    line-height: 1.25;
}
#dashboard-nav {
    padding: 1rem 1.25rem;
    background-color: #3b3b3b;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    font-family: 'Nunito';
    color: #ffffff;
    position: fixed;
    top: calc(1.375rem + 30px);
    z-index: 2;
    width: 100%;
}
#viewall-nav {
    padding: 0.5rem 1.25rem 1rem;
    background-color: #3b3b3b;
    margin-top: -0.25rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    font-family: 'Nunito';
    color: #ffffff;
}
#dashboard-nav h2 {
    font-size: 1.75rem;
    margin-top: 0.25rem;
}
a.d-mobile {
    position: relative;
    padding-right: 1rem;
}
a.d-mobile::after {
    position: absolute;
    right: 0;
    content: '\2192';

}

@media (max-width: 768px){
    .dashboard {
        margin-top: 0;
        margin-right: 0; 
    }
    .dashboard_body {
        margin-top: 10rem;
        margin-bottom: 1rem;
    }
    .dashboard .arrow-back, .dashboard .arrow-next{
        height: 0.5rem;
        width: 0.75rem;
    }
    #dashboard_daily_reading .daily_readings::before, #dashboard_system_status .system_statuses::before, #dashboard_staff_activities .task-row::before  {
        content: " ";
        min-width: 1rem;
    }
    #dashboard_daily_reading .daily_readings::after, #dashboard_system_status .system_statuses:last-child::after, #dashboard_staff_activities .task-row:last-child::after  {
        content: " ";
        min-width: 1rem;
    }
    #dashboard #dashboardCard, #expand_section #dashboardCard {
        box-shadow:  0 0 2px 0 rgba(0, 0, 0, 0.17);
        margin-right: 1rem;
        min-width: 8.75rem;
        max-height: 125px;
        width: unset;
    }
   
    #dashboard .daily_readings, #dashboard .system_statuses, #dashboard .task-row{
        overflow-x: scroll;
        padding-left: 0;
        display: flex;
    }
    #dashboard_system_status #dashboardCard, #dashboard_staff_activities #dashboardCard {
        align-items: start;
        padding-top: 1rem;
        padding-bottom: 1.375rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    #dashboard_staff_activities #dashboardCard {
        padding-bottom: 1rem;
    }
    #dashboard_staff_activities #attendance-row #dashboardCard{
        border-bottom: 0;
        box-shadow: none;
        position: relative;
    }
    #dashboard_daily_reading .label {
      font-size: 0.75rem;
    }
    #dashboard_daily_reading .last_updated {
      font-size: 0.5rem;
    }
    
    #dashboard_daily_reading .icon {
        max-height: 1.875rem;
        font-size: 1.875rem;
        margin-bottom: 0.6875rem;
        background-color: transparent;
    }
    
    #dashboard_system_status .label {
      font-size: 0.75rem;
      margin-bottom: 1.125rem;
    }
    #dashboard_system_status .status {
        font-size: 0.75rem;
      }
    #dashboard_system_status .last_updated {
      font-size: 0.5625rem;
    }
  
    #dashboard_staff_activities .tasks {
        margin-bottom: 0;
    }
    #dashboard_staff_activities .attendance{
        margin-bottom: 1rem;
    }
    #dashboard_staff_activities .tasks .total{
        font-size: 0.75rem;
    }
    #dashboard_staff_activities .label {
        font-size: 0.75rem;
        padding-right: 1.75rem;
    }
    #dashboard .tasks .label span {
        top: 1rem;
        right: 1rem;
        font-size: 1.25rem;
    }
    #dashboard_staff_activities .status {
        font-size: 0.75rem;
    }
    #dashboard_staff_activities .attendance-row {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

/* After 09/10/2020 */

.icon-audit:before {
    content: "\e941";
    color: #ffcd39;
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 18px;
    background: #fff;
}