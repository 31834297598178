#device-info .device {
  position: relative;
  padding: 1.5rem 1rem 1rem;
  background-color: #f2f8ff;
  border-radius: 0.25rem;
  margin-top: 1rem;
}
#device-info .info-group {
  font-size: 0.875rem;
  color: #1a1818;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#device-info .helper, 
#device-info .helper-icon {
  font-size: .75rem;
  color: #a8a8a8;
  display: inline;
  text-overflow: ellipsis;
}
#device-info input {
  padding: 0;
  border: 0;
  font-size: .875rem;
  color: #1a1818;
  border-bottom: 1px solid #ababab;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
  background-color: transparent;
  width: 100%;
}
#device-info input:focus {
  border-color: #92beef;
}
#device-info .edit-button {
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}