.typeform-container {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
}
.typeform-body {
  height: calc(100vh - 7.5rem);
  // overflow-x: hidden;
  // overflow-y: auto;
  overflow: hidden;
  position: relative;
}
.typeform-footer {
  // border-top: 1px solid #ececec;
  height: 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

// .typeform-radion-btn input[type='radio'] {
//   display: none;
// }
// .typeform-radion-btn label{
//   padding: 0.25rem 1rem;
//   background-color: #dcdcdc;
//   min-width: 225px;
// }

// .typeform-radion-btn input[type='radio']:checked + label {
//   border: 1px solid #000;
// }
.q-block {
  position: absolute;
  width: 100%;
  height: 100%;
  // pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(100vh);
}

.question {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1.5rem !important;
}
.tf-input {
  background: transparent !important;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #fff !important;
  padding: 0.5rem 0;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 1.2rem !important;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba($color: #fff, $alpha: 0.5);
  }
}

label.tf-file-upload {
  padding: 2.5rem 3rem;
  cursor: pointer;
  border-radius: .35rem;
  background-color: rgba($color: #fff, $alpha: 0.1);
  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.3);
  }
}

// #incident-file-upload {
  .tb-camera {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1030;
    background-color: #333232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
    .btn-capture {
      position: absolute;
      bottom: 80px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #558dca;
      align-self: center;
      border: 0;
      box-shadow: 0px 0px 45px -10px #558dca;
    }
    .btn-close {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: transparent;
      color: #ffffff;
      border: 0;
      z-index: 10;
    }
    .btn-close.btn-cancel {
      top: auto;
      bottom: 80px;
      right: auto;
      left: 60px;
      color: var(--danger);
      background: rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      padding: 0.5rem;
      width: 38px;
      height: 38px;
    }
    .btn-close.btn-confirm {
      top: auto;
      bottom: 80px;
      right: 60px;
      color: var(--success);
      background: rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      padding: 0.5rem;
      width: 38px;
      height: 38px;
    }
  }
// }

.tf-radio-group {
  .tf-radio-btn {
    label {
      position: relative;
      padding: .5rem 2.5rem .5rem 1rem;
      border: 1px solid #fff;
      border-radius: 0.25rem;
      margin-right: 1rem;
      transition: all .25s;
      cursor: pointer;
      font-size: 0.875rem;
      background-color: rgba($color: #fff, $alpha: 0.1);
      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.3);
      }
    }
    input[type='radio']:checked + label {
      border: 2px solid #fff;
      &::after {
        content: '\e92c';
        position: absolute;
        font-family: 'icomoon' !important;
        color: #fff;
        right: .5rem;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}