.svg-container {
    width: 100%;
    height: 80%;
}

.svg-content {
    display: block;
    margin: auto;
}

.label-donut {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #434343;
}