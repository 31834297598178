.qr-modal {
  width: 100%;
  margin: 0;
}
.qr-modal .modal-content {
  height: 100%;
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
.qr-modal .modal-body {
  background-color: #3b3b3b;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.qr-modal .modal-body .close {
  margin-left: auto;
  font-size: 0.75rem;
  margin-right: 1rem;
  margin-top: 1rem;
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 50%;
  opacity: 1;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.18);
  cursor: pointer;
}
.qr-modal .modal-body .qr-scanner section div {
  box-shadow: rgba(0, 0, 0, 0.18) 0 0 0.75rem 0.25rem inset !important;
}