#staff-activities {
  font-family: "Nunito";
  padding-bottom: 1rem;

  .system-header {
    h4 {
      font-weight: bold;
      margin-bottom: 0;
    }
    p span {
      text-decoration: underline;
    }
  }

  .tb-camera {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1030;
    background-color: #333232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .tb-camera img {
    width: 100%;
    height: auto;
  }
  .tb-camera .btn-capture {
    position: absolute;
    bottom: 80px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #558dca;
    align-self: center;
    border: 0;
    box-shadow: 0px 0px 45px -10px #558dca;
  }
  .tb-camera .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ffffff;
    border: 0;
    z-index: 10;
  }
  .tb-camera .btn-close.btn-cancel {
    top: auto;
    bottom: 80px;
    right: auto;
    left: 60px;
    color: var(--danger);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    padding: 0.5rem;
    width: 38px;
    height: 38px;
  }
  .tb-camera .btn-close.btn-confirm {
    top: auto;
    bottom: 80px;
    right: 60px;
    color: var(--success);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    padding: 0.5rem;
    width: 38px;
    height: 38px;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }
  .icon-arrow-left{
    font-size: 1rem;
  }
}
@media (min-width: 769px) {
  #staff-activities {
    padding: 2.5rem 2rem 1rem;
    .system-header h4 {
      line-height: 2rem;
    }
    .system-body {
      padding-top: 1rem;
    }
    .system-card.card {
      min-height: 100%;
    }
  }
}
@media (max-width: 768px) {
  #staff-activities {
    .system-header {
      padding: 0.5rem 1.25rem 0.5rem;
      background-color: #3b3b3b;
      margin-top: 0.375rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      color: #ffffff;
      position: fixed;
      width: 100%;
      top: calc(2rem + 30px - 0.625rem);
      z-index: 999;
    }
    .system-header h4 {
      font-size: 1.25rem;
      line-height: 1.2;
    }
    .system-header p {
      color: #a2a2a2 !important;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    .system-header p span {
      text-decoration: none;
    }
    .system-body {
      margin: calc(7rem + 30px - 0.625rem) 1rem 1rem;
    }
    .system-body .header {
      padding-left: 0.875rem;
    }
    .add-ticket-btn{
      border-radius: 50% !important;
      padding: 0;
      line-height: 1.25;
      height: 38px;
      width: 38px;
      font-size: 1.25rem;
    }
  }
}