.widget {
  /* height: 480px; */
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  margin: 15px 0px;
  border-radius: 5px;
  font-family: Inter;
}
.widget .disabled {
  pointer-events: none;
  opacity: 0.4;
}
.edit-dashed .widget {
  border: 2px dashed #989898;
}
.full-h {
  height: 480px;
}

.half-h {
  height: 250px;
}
.dashboard-header {
  font-family: Inter;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #3a3a3a;
}
.dashboard-sub-header {
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  color: #a4a4a4;
}
.layout-mode-button {
  /* padding: 14px 24px; */
  padding: 12px 18px;
  border: 1.5px solid #9fc9f7;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

/* Compliance styles */
nav.nav-tabs {
  border: none !important;
}

.nav-tabs > .nav-link {
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  border: none;
  color: #c9c9c9;
}

/* .nav-tabs > .nav-link {    
    color: #333333
} */

.nav-tabs > .nav-link.active {
  color: #333333;
  border-bottom: 2px solid #000000 !important;
}

.cancelButton {
  height: 44px !important;
  width: 79px;
  background-color: transparent;
  border: 0px;
  outline: none !important;
  color: #ff5151;
  font-size: 14px;
  line-height: 19px;
  margin-right: 30px;
  font-family: "Nunito";
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.06) !important;
}
