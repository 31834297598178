.activity-detail-modal {
  .name {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
  }
  .his-comment {
    font-size: 0.875rem;
    color: var(--black);
  }
  .his-time {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.4);
  }

  .comment {
    background: var(--color-gray-2);
    border-radius: 4px;
    line-height: 1.25rem;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    max-width: 320px;
  }
  .comment-summary {
    line-height: 1rem;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.4);
  }

  .adhocTextarea{
    height: 120px;
    padding-right: 10px;
  }

  .submitButton {
    background: var(--color-active);
    bottom: 15px;
    right: 10px;
    &:disabled {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
}
