#staff-activity-detail {
  .tab-head {
    list-style-type: none;
    .item {
      width: 33%;
      border-radius: 4px;
      opacity: 0.5;
      color: var(--color-white);
      position: relative;
      .item-count {
        font-size: 1.5rem;
      }
      .item-state {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
        padding: 3px;
      }
      &.selected {
        cursor: default;
        opacity: 1;
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -7px;
          margin: auto;
        }
      }
      &.active {
        background: var(--color-active);
        .item-state {
          background: url("../../assets/images/active-filled.svg") no-repeat
            center #fff;
        }
        &:after {
          border-top: 7px solid var(--color-active);
        }
      }
      &.completed {
        background: var(--color-completed);
        .item-state {
          background: url("../../assets/images/completed-filled.svg") no-repeat
            center #fff;
        }
        &:after {
          border-top: 7px solid var(--color-completed);
        }
      }
      &.defaulted {
        background: var(--color-defaulted);
        .item-state {
          background: url("../../assets/images/defaulted-filled.svg") no-repeat
            center #fff;
        }
        &:after {
          border-top: 7px solid var(--color-defaulted);
        }
      }
      &.upcoming {
        background: var(--color-upcoming);
        .item-state {
          background: url("../../assets/images/upcoming-filled.svg") no-repeat
            center #fff;
        }
        &:after {
          border-top: 7px solid var(--color-upcoming);
        }
      }
    }
  }
  .tab-content {
    .card {
      border: 0;
      box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.05);
    }
    .card-header {
      background-color: var(--color-white);
      font-size: 1rem;
      cursor: pointer;
      border: 0;
      .icon-chevron-down {
        font-size: 0.5rem !important;
        margin-right: 0.5rem;
        color: rgba(0, 0, 0, 0.3);
        transition: transform 400ms ease;
        display: inline-block;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
    .name {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.6);
    }
    .time {
      background: #eeeeee;
      padding: 2px 4px;
      color: #00000066;
      font-size: 0.75rem;
      border-radius: 2px;
    }
    .close-time {
      padding: 2px 4px;
      font-size: 0.75rem;
      border-radius: 2px;
      &-gray {
        border: 1px solid #bdbdbd;
        color: #bdbdbd;
      }
      &-red {
        border: 1px solid #ff8d8d;
        color: #ff8d8d;
      }
    }
    .frequency {
      font-size: 0.75rem;
      color: #4f4f4f;
    }
    .performer {
      color: #bdbdbd;
      font-size: 0.75rem;
      list-style-type: disc;
    }
    .separator {
      font-size: 0.75rem;
      color: #4f4f4f;
    }
  }
  .icon-chevron-down {
    font-size: 0.5rem !important;
    margin-right: 0.5rem;
    color: rgba(0, 0, 0, 0.3);
    transition: transform 400ms ease;
    display: inline-block;
    &.open {
      transform: rotate(180deg);
    }
  }
  .icon-comments {
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 3px;
    background: url("../../assets/images/icon-comments.svg") no-repeat center
      transparent;
      &.active-comments{
        background: url("../../assets/images/icon-comments-active.svg") no-repeat center
      }
  }
  .icon-images {
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 3px;
    background: url("../../assets/images/icon-images.svg") no-repeat center
      transparent;
  }
  .icon-camera {
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:hover {
      &:before {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  .comment-count {
    font-size: 0.75rem;
    color: #00000066;
  }
  .link {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .btn-approve {
    color: #73d48e;
    border: 0;
    padding: 3px 6px 3px 30px;
    border-radius: 4px;
    background: url("../../assets/images/icon-thumbs-up.svg") no-repeat 3px
      center #e1ffe9;
    &:disabled {
      opacity: 0.6;
    }
  }
  .label-approve {
    color: #73d48e;
    padding: 3px 6px 3px 30px;
    background: url("../../assets/images/icon-thumbs-up.svg") no-repeat 3px
      center;
  }
  .btn-reject {
    color: #ff6a6a;
    border: 0;
    padding: 3px 6px 3px 30px;
    border-radius: 4px;
    background: url("../../assets/images/icon-thumbs-down.svg") no-repeat 3px
      center #fff0f4;
    &:disabled {
      opacity: 0.6;
    }
  }
  .label-reject {
    color: #ff6a6a;
    padding: 3px 6px 3px 30px;
    background: url("../../assets/images/icon-thumbs-down.svg") no-repeat 3px
      center;
  }

  .open-detail {
    width: 44px;
    height: 30px;
    background: url("../../assets/images/arrow-blue-large.svg") no-repeat 3px
      center;
    cursor: pointer;
  }

  .task-image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.25rem;
  }

  .all-actions{
    flex-wrap: wrap;
  }

  .line-break{
    width: 100%;
    height: 1rem;
  }

  .tb-camera {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1030;
    background-color: #333232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
    .btn-capture {
      position: absolute;
      bottom: 80px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #558dca;
      align-self: center;
      border: 0;
      box-shadow: 0px 0px 45px -10px #558dca;
    }
    .btn-close {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: transparent;
      color: #ffffff;
      border: 0;
      z-index: 10;
      &.btn-cancel {
        top: auto;
        bottom: 80px;
        right: auto;
        left: 60px;
        color: var(--danger);
        background: rgba(255, 255, 255, 0.25);
        border-radius: 50%;
        padding: 0.5rem;
        width: 38px;
        height: 38px;
      }
      &.btn-confirm {
        top: auto;
        bottom: 80px;
        right: 60px;
        color: var(--success);
        background: rgba(255, 255, 255, 0.25);
        border-radius: 50%;
        padding: 0.5rem;
        width: 38px;
        height: 38px;
      }
    }
  }
}

#container-circles{
  bottom: 140px!important;
}

@media (max-device-width: 767px) {
  #staff-activity-detail {
    .tab-content {
      .name {
        font-size: 0.9rem;
      }
      .item-highlights {
        flex-direction: column;
        align-items: flex-start !important;
        .close-time {
          margin: 0.5rem 0;
        }
      }
    }
  }
}
