#staff-activity-list {
  .list-group .list-group-item {
    background-color: var(--color-white);
    border: 0;
    border-radius: 4px;
    padding: 1.5rem 1.5rem;
    font-size: 1.15rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    margin-bottom: 1rem;
    transition: all 300ms ease;
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.05);
    flex-wrap: wrap;
  }

  .name {
    width: 20%;
  }

  .stats {
    width: 40%;
  }

  .icon-state {
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    text-align: center;
    padding: 3px;
    &.icon-active {
      background: url("../../assets/images/active.svg") no-repeat center #14d5ff;
    }
    &.icon-done {
      background: url("../../assets/images/done.svg") no-repeat center #73d48e;
    }
    &.icon-exception {
      background: url("../../assets/images/hourglass.svg") no-repeat center
        #ff8989;
    }
  }

  .count {
    color: #adadad;
    width: 60px;
  }

  .progress {
    border-radius: 0.25rem;
    width: 30%;
  }

  .progress-indicator {
    background: #558dca;
  }

  .icon-chevron-right {
    transform: rotate(180deg);
    color: #adadad;
    position: absolute;
    right: 20px;
  }

  .badge-indicator {
    background-color: #558dca;
  }
}

@media (max-width: 768px) {
  #staff-activity-list {
    .name {
      width: 90%;
      margin-bottom: 20px;
    }

    .stats {
      width: 60%;
      order: 3;
    }

    .progress {
      width: 90%;
      order: 2;
      margin-bottom: 20px;
    }
  }
}
