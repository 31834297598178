.spinLabel{
    height: 10px;
    width: 10px;
    border: 2px solid currentColor;
    border-right-color: transparent;
    margin-top: 5px;
    margin-left: 7px;
}

#adhocTask{
    font-family: 'Nunito';
}

.activeAdhocButton{
    color: #3d3d3d;
    font-size: 23px;	
    font-weight: bold;	
    line-height: 31px;
    margin: 0;
}
.adhocButton{
    color: #666666;
    font-size: 23px;	
    font-weight: 300;	
    line-height: 31px;
}
.addTaskButton{
    color: #ffffff;
    font-size: 13px;	
    font-weight: bold;
    line-height: 19px;
}
.adhocCard{
    /* width: 260px;
    height: 175px; */
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    border-radius: 4px;
    background-color: #FFFFFF;	
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    padding : 10px;
    padding-left: 23px;
    padding-right: 23px;
    /* margin: 25px 17px; */
    cursor: pointer;
    border: 0px;
    outline: none !important;
}
.oldCard{
    height: 200px;	
    width: 300px;	
    border-radius: 5px;	
    background-color: #E8E8E8;	
    padding : 10px;
    padding-left: 23px;
    padding-right: 23px;
    margin: 25px;
    cursor: pointer;
    border: 0px;
    outline: none !important;
}

.adhocTaskTitle {
    color: #1a1818;
    font-size: 1.125rem;	
    font-weight: bold;	
}
.adhocTaskType{
    color: #9d9d9d;	
    font-size: 0.9375rem;	
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: 5px;
}

.adhocTaskDescription{
    padding-top: 8px;
    color: #3B3B3B;	
    font-size: 0.875rem;
}

.adhocTaskDate{
    color: #3B3B3B;	
    font-size: 11px;	
    min-width: 100px;
    /* padding-right: 30px; */
}
.adhocCurrentStatus{
    border-radius: 5px;	
    font-size: 0.75rem;	
    font-weight: bold;
    white-space: nowrap;
}

.modalSubHeadings{
    color: #3B3B3B;
    font-size: 10px;	
    line-height: 14px;
    margin-top: 15px;
}
.modalHeading{
    color: #3B3B3B;
    font-size: 14px;	
    font-weight: 800;	
    line-height: 19px;
}

.slide-pane{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.slide-pane__overlay{
    z-index: 1030;
}

.slide-pane__overlay.ReactModal__Overlay--after-open{
    background-color: transparent;
}

.slide-pane__header{
    display: none;
}

#adhocTask .close{
    background-color: white;
    border: 0;
}

.addTaskHeading{
    font-size: 18px;
    font-weight: bold;
    color: #1a1818;
}

.addTaskTitle{
    margin-top: 30px !important;
}

.addTaskSubHeadings{
    font-size: 12px;
    color: #a8a8a8;
}

.addTaskInputs{
    border: 0px;
    border-bottom: 1px solid #D5D5D5;
    width: 325px;
    outline: none;
    font-size: 14px;
    font-weight: normal;
    color: #1a1818;
}

.addTaskCalendarDatePicker{
    font-size: 12px;
    width: 325px;
    color: #3B3B3B;
    border: 0px;
    border-bottom: 1px solid #D5D5D5;
    outline: none;
}

.uploadFile{
    font-size: 0.75rem;
    color: #1a1818;
    height: 2.25rem;
    padding: 0px 14px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #d0efdb;
}

.addFile{
    width: 18px;
    height: 17px;
    background-color: #96ccaa;
}

.addFileDiv{
    width: 2.3125rem;
    height: 2.25rem;
    background-color: #96ccaa;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.cancel{
    width: 80px;
    height: 32px;
    border-radius: 3px;
    border: solid 1px #979797;
    background-color: #ffffff;
    font-size: 12px;
    color: #979797;
}

.saveDraft{
    background-color: transparent;
    text-decoration: underline;
    font-size: 12px;
    color: #558dca;
    font-weight: bold;
}

.save{
    background-color: #558dca;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    width: 80px;
    height: 32px;
}

.modalDropdown{
    width: 218px;
    border-bottom: 2px solid #D5D5D5;
    background-color: white;
}

#Adhoc .modal-content {
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 50px;
    margin-top: 100px;
}

.adhocInputs{
    border: 0px;
    border-bottom: 2px solid #D5D5D5;
    outline: none;
}
.CalendarDatePicker{
    font-size: 12px;
    width: 100px;
    color: #3B3B3B;
    border: 0px;
    border-bottom: 2px solid #D5D5D5;
    outline: none;
}

.AdhocButton {
    box-shadow: '0 0 10px 0 rgba(0,0,0,0.23)';
    color: #FFFFFF;
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;	
    text-align: center;
    margin-bottom: 15px;
    padding: 3px;
    border: 1px solid #4A90E2;
    border-radius: 4px;
    outline: none;
}

input[type="file"] {
    /* display: none; */
    opacity: 1;
    background-color: 'pink';
    width: 270px;
    height: 25px;
    opacity: 0;
    cursor: pointer;
    margin-left: -110px;
}
.uploadFileLabel{
    color: #4A90E2;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;
    margin-left: 7px;
    pointer-events: none;
}

.cabinet{
    overflow: hidden;
    /* margin-bottom: 50px; */
}

.addImage {
    height: 20px; 
    width: 20px;
    pointer-events: none;
}

.fileItems{
    color: #3B3B3B;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Adhoc .modalDropdown{
    width: 218px;
    border-bottom: 2px solid #D5D5D5;
    background-color: white;
}

.adhocDropdown{
    width: 34px;
    height: 34px;
    border : 0px;
    overflow:hidden;
    font-size: 13px;
    outline: none !important;
    cursor: pointer;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    background-color: transparent;
    border-radius: 3px;
    padding-left: 10px;
    position: relative;
    color: rgb(73, 69, 69);
    opacity: 0;
}
.adhocTaskCard{
    border-radius: 5px;
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    margin: 40px 15px 15px;
    border: 0px;
    outline: none !important;
}

.taskDetails{
    padding: 0 25px;
    font-family: 'Nunito';
}

.adhocTaskDetailCard{
    border-radius: 4px;
    background-color: #FFFFFF;	
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    margin: 40px 15px 15px;
    border: 0px;
    outline: none !important;
}

.rowDiv{
    display: flex;
}

.column1{
    width: 50%;
    padding: 18px 24px;
}

.column2{
    width: 50%;
    padding: 33px 150px 33px 35px;
    border-left: solid 1px #e0e0e0;
}

.heading{
    font-size: 18px;
    font-weight: bold;
    color: #1a1818;
}

.back-close{
    height: 16px;
    width: 16px;
}

#adhocTask .col-6{
    padding: 15px 35px;
}

.AdTaskTitle{
    color: #3B3B3B;
    font-size: 12px;	
    line-height: 14px;
}

.AdTaskAttachments{
    font-size: 14px;
    font-weight: bold;
    color: #1a1818;
}

.AdTaskTime{
    font-size: 12px;
    color: #9d9d9d;
}

.commentTitle{
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #1a1818;
}

.adhocTextarea{
    width: 100%;
    height: 76px;
    border: 1px solid #A1A1A1;	
    border-radius: 3px;
    resize: none;
    color: rgb(123, 123, 123);	
    font-size: 13px;	
    line-height: 14px;
    padding: 10px;
    outline: none !important;
    padding-right: 96px;
}
.submitButton {
    height: 28px;	
    width: 85px;	
    border-radius: 3px;	
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.23);
    border: 0px;
    color: #FFFFFF;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;	
    text-align: center;
    outline: none !important;
}

.AdTaskCommonStyle{
    color: #3B3B3B;
    font-size: 16px;	
    line-height: 19px;
    white-space: pre-line;
}

.adTaskButtons{
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.23);
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.attachedFilesLabel{
    color: #4A90E2;	
    font-size: 14px;	
    line-height: 19px;
    text-decoration: underline #4A90E2
}

.noTasksDiv{
    height: 55vh;
    margin-top: 12px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    margin-left: 20px;
    margin-right: 50px;
}

.noTasks{
    font-size: 21px;
    font-weight: bold;
    color: #3b3b3b;
    margin-top: 17px;
}

.noTaskDesc{
    margin-top: -5px;
    font-size: 13px;
    color: #a2a2a2; 
}

.comments{
    min-height: 65px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.commentedBy{
    font-size: 12px;
    color: #558dca;
}

.commentHeading{
    color: #a8a8a8;
    font-size: 10px;	
    line-height: normal;
}
.commentSubeading{
    color: #3B3B3B;	
    font-size: 16px;	
    line-height: 19px;
}

.morecomment-btn{
    font-size: 12px;
    color: rgb(0, 124, 201);
    text-decoration: underline;
}

#adhocTask #dashboard-nav {
    /* padding: 1rem 1.25rem;
    background-color: #3b3b3b; */
    height: 70px;
    margin-top: -0.3125rem !important;
    padding-top: 20px !important;
    /* border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff; */
}

#dashboard-nav h2 {
    font-size: 1.75rem;
    margin-top: 0.25rem;
}

#taskList .list-group .list-group-item{
    border: 0;
    border-radius: 0;
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    background-color: transparent;
}
#taskList .list-group .list-group-item + .list-group-item {
    border-top: 1px solid rgb(220, 220, 220);
}
#taskList .list-group .list-group-item::after{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 0 solid transparent;
    transition: all 300ms ease;
}
#taskList .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
    border-color: #333232;
}
#taskList .list-group .list-group-item:hover {
    font-weight: bold;
}
#taskList .list-group-item.active {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: bold;
    border-top-color: #2c2c2c;
}
#taskList .list-group .list-group-item.active::after {
    right: -6px;
    border-left: 6px solid #2c2c2c;
}
#taskList .list-group .list-group-item.active:hover{
    color: #ffffff;
}

#taskList .listCards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px,0.5fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding-right: 57px;
}
#taskList .card-main-icon {
    font-size: 42px;
    color: #558dca;
}

#taskList .card-header {
    font-weight: bold;
    background: #ffffff;;
}

#taskList .accordion table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    /* border-top: 1px solid #e5e5e5; */
    /* background-color: #fafafa ; */
}

#taskList .accordion table tr {
    background-color: #fafafa;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0.25rem;
}

#taskList .accordion button.btn-outline-primary {
    white-space: nowrap;
    font-size: 0.75rem;
}

#taskList .card-header .headerCount {
    color: rgba(0, 0, 0, 0.3);
}

#taskList .card-header .icon-chevron-down {
    margin-right: 0.5rem;
    font-size: 0.5rem;
    color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    transition: transform 0.4s ease;
    transform-origin: center;
}
#taskList .card-header .icon-chevron-down.open {
    transform: rotate(180deg);
}

#taskList .accordion .card-body .taskTitle {
    font-weight: 500;
}

#adhocTask .component-sidebar {
    position: absolute;
    top: calc(5rem + 15px);
    left: -100%;
    width: 80%;
    transition: all 300ms ease-in-out;
    z-index: 1;
    display: flex;
    background-color: #3b3b3b;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.35);
}
#adhocTask .component-sidebar.open {
    left: 0;
    z-index: 2;
}
#adhocTask .component-sidebar .closeMenu {
    font-size: 0.75rem;
    color: #ffffff;
    margin-top: 1rem;
    margin-right: 0.5rem;
}
#adhocTask .component-sidebar .list-group {
    border-radius: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    flex: 1;
}
#adhocTask .component-sidebar .list-group .list-group-item {
    background-color: #3b3b3b;
    border: 0;
    border-bottom: 1px solid;
    border-color: #777777 !important;
    border-radius: 0;
    margin-top: 1px;
    padding-left: 0.75rem;
}
#adhocTask .component-sidebar .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
}
#adhocTask .component-sidebar .list-group .list-group-item:hover {
    font-weight: bold;
}
#adhocTask .listCards .hourglass {
    margin-right: 8px;
    height: 13px;
}
#adhocTask .listCards .lastdate {
    font-size: 12px;
    color: #9d9d9d;
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
}
.font-sm {
    font-size: 90%;
}

/* @media (max-width: 900px) {
    #taskList{
        flex-direction: column;
    }
    #taskList .listCards{
        margin-top: 15px !important;
    }
} */

@media only screen and (max-width: 576px) {
    .activeAdhocButton{
        font-size: 13px;	
    }
    .adhocButton{
        font-size: 13px;	
    }
    #Adhoc .modal-content {
        margin-top: 20px
    }
    .listCards{
        justify-content: center;
    }

    .listCards.grouped-cards {
        grid-template-columns: repeat(auto-fit, minmax(140px,1fr)) !important;
    }
    .listCards.grouped-cards .tasktype {
        font-size: 0.75rem !important;
        margin-right: 8px;
    }
    .listCards.grouped-cards .card-main-icon {
        font-size: 20px !important;
    }
    .listCards.grouped-cards .adhocTaskTitle {
        font-size: 0.875rem !important;
        margin-top: auto;
        margin-bottom: 0 !important;
    }
    .listCards.grouped-cards .adhocCard {
        min-height: 70px !important;
        padding: 16px 20px !important;
    }
    .listCards.grouped-cards .adhocCard .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    .listCards.grouped-cards .lastdate {
        font-size: 10px !important;
    }
    .listCards.grouped-cards .hourglass {
        height: 11px !important;
    }
}
@media only screen and (max-width: 1000px) {
    .rowDiv{
        display: block;
    }

    .column2{
        width: 100%;
        padding-right: 33px;
    }

    .column1{
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    #adhocTask{
        margin: 0 !important;
    }
    #taskList .listCards{
        justify-content: center;
        margin: 10px 10px 20px 10px;
        margin-left: 0 !important;
        padding-right: 15px;
        grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
    }
    .adhocCard{
        width: 100%;
        /* height: 13.0625rem; */
        padding: 22px 24px 24px !important;
        margin-bottom: 0;
    }
    .adhocTaskTitle{
        padding-top: 7px;
    }
    .adhocTaskType{
        padding-top: 5px;
    }
    .adhocTaskDescription{
        padding-top: 8px;
    }
    .adhocTaskDate{
        padding-top: 12px;
    }
    .adhocDiv{
        font-size: 1.25rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.25;
    }
    .activeAdhocDiv{
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
    }
    .some-custom-class{
        width: 100% !important;
    }
    .addTaskInputs, .addTaskCalendarDatePicker, .react-datepicker-wrapper, .react-datepicker__input-container{
        width: 100% !important;
    }
    #Adhoc{
        width: 100% !important;
    }
    .addTaskTitle{
        margin-top: 50px !important;
    }

    /* TaskDetail */
    .taskDetails{
        padding: 0;
        z-index: 999;
    }

    .adhocTaskDetailCard{
        width: 100% !important;
        border-radius: 0;
        margin: 0;
        min-height: 100vh;
    }

    .rowDiv{
        display: block;
    }

    .column1{
        width: 100%;
    }
    
    .column2{
        width: 100%;
        padding: 14px 24px 30px 25px !important;
        border-top: solid 1px #e0e0e0;
        border-left: 0;
    }

    .taskDetailHeading{
        border: 0 !important;
    }

    .timeDiv{
        margin-top: 11px;
    }

    .noTasksDiv{
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 50px;
    }

    #taskList .accordion table, #taskList .accordion tbody, #taskList .accordion td {
        display: block;
    }

    #taskList .accordion tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
        padding: 1.25rem 1rem 0.25rem;
    }
    #taskList .accordion td {
        order: 1;
        padding: 0;
        margin-bottom: 1rem;
    }
    #taskList .accordion td:nth-child(3) {
        order: 0;
    }
}