@import '_variables';

@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?bkkc15');
  src:  url('./assets/fonts/icomoon.eot?bkkc15#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?bkkc15') format('truetype'),
    url('./assets/fonts/icomoon.woff?bkkc15') format('woff'),
    url('./assets/fonts/icomoon.svg?bkkc15#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-record-outline:before {
  content: "\e93d";
}
.icon-alert:before {
  content: "\e93c";
}
.icon-minus:before {
  content: "\e93a";
}
.icon-plus:before {
  content: "\e93b";
}
.icon-info:before {
  content: "\e935";
}
.icon-mail:before {
  content: "\e936";
}
.icon-name:before {
  content: "\e937";
}
.icon-phone:before {
  content: "\e938";
}
.icon-tag:before {
  content: "\e939";
}
.icon-pencil:before {
  content: "\e925";
  color: #558dca;
}
.icon-lock:before {
  content: "\e926";
}
.icon-converter .path1:before {
  content: "\e900";
  color: rgb(183, 183, 183);
}
.icon-converter .path2:before {
  content: "\e901";
  margin-left: -0.900390625em;
  color: rgb(59, 59, 59);
}
.icon-default-daily:before {
  content: "\e924";
}
.icon-attendance:before {
  content: "\e914";
}
.icon-water_outflow:before {
  content: "\e918";
}
.icon-arrow-left:before {
  content: "\e922";
}
.icon-menu:before {
  content: "\e91a";
}
.icon-qr-code:before {
  content: "\e91b";
}
.icon-notification:before {
  content: "\e91c";
}
.icon-daily_readings:before {
  content: "\e910";
}
.icon-dashboard_home:before {
  content: "\e90f";
}
.icon-chevron-right:before {
  content: "\e91d";
}
.icon-chevron-down:before {
  content: "\e91e";
}
.icon-arrow-right:before {
  content: "\e91f";
}
.icon-pool:before {
  content: "\e916";
}
.icon-stp:before {
  content: "\e915";
}
.icon-water_inflow:before {
  content: "\e917";
}
.icon-power:before {
  content: "\e919";
}
.icon-logout:before {
  content: "\e920";
}
.icon-staff_activities:before {
  content: "\e912";
}
.icon-adhoc_task:before {
  content: "\e913";
}
.icon-settings:before {
  content: "\e923";
}
.icon-reports:before {
  content: "\e921";
}
.icon-system_status:before {
  content: "\e911";
}
.icon-hamburger:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-history:before {
  content: "\e904";
}
.icon-carpenter:before {
  content: "\e905";
}
.icon-diesel-generator:before {
  content: "\e906";
}
.icon-electrician:before {
  content: "\e907";
}
.icon-electricity:before {
  content: "\e908";
}
.icon-fire:before {
  content: "\e909";
}
.icon-garden:before {
  content: "\e90a";
}
.icon-house_keeping:before {
  content: "\e90b";
}
.icon-lift:before {
  content: "\e90c";
}
.icon-plumber:before {
  content: "\e90d";
}
.icon-security:before {
  content: "\e90e";
}
.icon-filter:before {
  content: "\e927";
}
.icon-sort:before {
  content: "\e928";
}
.icon-close:before {
  content: "\e929";
}
.icon-calendar:before {
  content: "\e92a";
}
.icon-download:before {
  content: "\e92b";
  color: #3b3b3b;
}
.icon-tick:before {
  content: "\e92c";
}
.icon-screw-driver:before {
  content: "\e92d";
}
.icon-clock:before {
  content: "\e92e";
}
.icon-gas-cylinder:before {
  content: "\e92f";
}
.icon-cctv:before {
  content: "\e930";
}
.icon-transformers:before {
  content: "\e931";
}
.icon-gym:before {
  content: "\e932";
}
.icon-water-tank:before {
  content: "\e933";
}
.icon-wtp:before {
  content: "\e934";
}
.icon-camera:before {
  content: "\e93e";
}
.icon-camera-off:before {
  content: "\e93f";
}
.icon-image:before {
  content: "\e940";
}
.icon-alert-circle:before {
  content: "\e941";
}
.icon-remove:before {
  content: "\e942";
}
.icon-cancel:before {
  content: "\e942";
}
.icon-times:before {
  content: "\e942";
}
.icon-close-circle:before {
  content: "\e942";
}
.icon-refresh:before {
  content: "\e943";
}
.icon-renew:before {
  content: "\e943";
}
.icon-message:before {
  content: "\e944";
}
.icon-comment:before {
  content: "\e944";
}
.icon-attachment:before {
  content: "\e945";
}
.icon-upload-cloud:before {
  content: "\e946";
}
.icon-tickets:before {
  content: "\e947";
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select {
  border: 0;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid;
  border-radius: 0;
  -webkit-appearance: none;
  background-image: url('./assets/images/sidebarNew/chevron-down.svg');
  background-position: 97%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-right: 1.25rem !important;
}

.cursor-pointer {
  cursor: pointer;
}
.text-indicator {
  font-weight: bold;
  color: #3d3d3d !important;
}
.text-color {
  color: #3d3d3d;
}
.text-color.red {
  color: #ff6a6a !important;
}
.text-color.amber {
  color: #ffbf00 !important;
}
.text-color.green {
  color: #28bb00 !important;
}
.text-color.blue {
  color: #266afa !important;
}
.badge-indicator.red {
  background-color: #ff6a6a !important;
}
.badge-indicator.amber {
  background-color: #ffcd39 !important;
}
.badge-indicator.green {
  background-color: #28bb00 !important;
}
.badge-indicator.primary {
 background-color: #558dca !important;
}
.badge-indicator.grey {
  background-color: #bdbdbd !important;
}
.border-indicator {
  border-color: transparent !important;
}
.border-indicator.red {
  border-color: #ff6a6a !important;
}
.border-indicator.amber {
  border-color: #ffcd39 !important;
}
.border-indicator.green {
  border-color: #28bb00 !important;
}
.border-indicator.grey {
  border-color: #bdbdbd !important;
}
.border-indicator.blue {
  border-color: #266afa !important;
}
.border-indicator.danger {
  border-color: #ff6a6a !important;
}
.border-indicator.success {
  border-color: #73d58e !important;
}
.border-indicator.primary {
  border-color: #14d5ff !important;
}
.linear-border {
  background-image: linear-gradient(#28bb00, #28bb00);
}
.linear-border.amber {
  background-image: linear-gradient(#ffbf01, #ffbf01);
}
.text-danger {
  color: #ff6a6a !important;
}
.text-primary {
  color: #558dca !important;
}
.modal-full {
  width: calc(100vw - 1rem);
  height: calc(100vh - 1rem);
}
.modal-full .modal-content {
  height: 100%;
}

.icon-2x {
  font-size: 1.125em;
}

input[type="radio"] {
  display: none;
}