#readings {
  font-family: 'Nunito';
}
#readings .readings-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#readings .readings-header p span {
  text-decoration: underline;
}
#readings .readings-body .readings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 1.5rem;
}
#readings .reading-card .menu {
  font-size: 0.625rem;
  padding: 0.5rem;
  cursor: pointer;
}
#readings .reading-card .menu-list {
  display: flex;
  overflow-x: auto;
  margin-left: 0.25rem;
}
#readings .switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
  margin-bottom: 0;
}
#readings .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
#readings .slider {
  position: absolute;
  cursor: pointer;
  height: 16px;
  width: 26px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d8d8;
  -webkit-transition: .4s;
  transition: .4s;
}
#readings .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: #558dca;
  -webkit-transition: .4s;
  transition: .4s;
}
#readings input:checked + .slider {
  background-color: #558dca;
}
#readings input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
#readings input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -14px;
  background-color: white;
}
#readings .slider.round {
  border-radius: 34px;
  margin-top: 2px;
}
#readings .slider.round:before {
  border-radius: 50%;
}

@media (min-width: 769px) {
  #readings {
    padding: 2.5rem 2rem;
  }
  #readings .readings-header h4 {
    line-height: 2rem;
  }
  #readings .readings-body {
    padding-top: 1rem;
  }
}
@media (max-width: 768px) {
  #readings .readings-header {
    padding: 0.5rem 1.25rem 1rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999;
  }
  #readings .readings-header h4 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  #readings .readings-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #readings .readings-header p span {
    text-decoration: none;
  }
  #readings .readings-body {
    margin-top: 10rem;
    margin-right: 0;
    margin-left: 0;
  }
}
.recharts-legend-item-text {
  font-size: 0.75rem;
}