.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav_container {
  height: 100px; 
  width: 100%; 
  background-color: #f2f2f2;   
  padding-left: 20px;
}
#offline-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 2rem;
  opacity: 0;
  text-align: center;
  transform: scale3d(0, 0, 0);
  transition: all ease 300ms;
}
#offline-text span {
  color: #fff;
  background-color: #3b3b3b;
  padding: 0.5rem 1rem;
  border-radius: 25rem;
  font-size: 0.75rem;
  display: inline-block;
}
#offline-text.active {
  opacity: 1;
  bottom: 1rem;
  transform: scale3d(1, 1, 1);
}
.rbt {
  transform: scale(1) !important;
}
.rbt-input-main {
  width: 220px;
  font-family: 'Nunito';
  font-size: 1rem;
  margin-top: 2rem;
}
@media (max-width: 576px){
  .nav_container {
    height: 50px; 
    width: 100%; 
    background-color: #f2f2f2; 
    margin-top: 20px;    
  }
}
.cursor-pointer {
  cursor: pointer;
}