.full-modal .modal-dialog {
    max-width: unset !important;
    margin: 0;
    font-family: 'Nunito';
}
.full-modal .modal-dialog .modal-content {
    border-radius: 0;
    border: 0;
    background-color: #558dca;
    /* background-color: rgb(210 243 255); */
    
    color: #fff;
    margin-top: 0px;
}
.add-incident-header {
    background-color: #3b3b3b;
    border-radius: 0;
    button {
        color: #fff;
        font-weight: 500;
        opacity: 1;
    }
}