#service-interval {
  font-family: 'Nunito';
}
#service-interval .component-sidebar {
  position: absolute;
  top: calc(7.5rem + 6px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#service-interval .component-sidebar.open {
  left: 0;
}
#service-interval .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#service-interval .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  flex: 1;
}
#service-interval .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  cursor: pointer;
}
#service-interval .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#service-interval .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#service-interval .system-body .card-body {
  font-size: 0.875rem;
}
#service-interval .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#service-interval .system-body .systems {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#service-interval .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#service-interval .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#service-interval .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#service-interval .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#service-interval .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#service-interval .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#service-interval .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#service-interval .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#service-interval .system-header p span {
  text-decoration: underline;
}
#service-interval .system-body h6 {
  font-weight: 600;
  margin-bottom: 0;
}
#service-interval .system-body .tabular-interval .table {
  font-size: 0.875rem;
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 10px;
}
#service-interval .system-body .tabular-interval .table th {
  border: 0 !important;
  padding: 0 0.75rem;
}
#service-interval .system-body .tabular-interval .table tr.interval-row {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  cursor: pointer;
  transition: all 450ms ease;
}
#service-interval .system-body .tabular-interval .table td {
  border: 0;
  background-color: #ffffff;
}
#service-interval .system-body .tabular-interval .table tr.interval-row:hover {
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.13);
}
#service-interval .system-body .tabular-interval .table td::first-letter {
  text-transform: uppercase;
}
#service-interval .system-body .tabular-interval .table td:first-child {
  border-radius: 2px 0 0 2px;
}
#service-interval .system-body .tabular-interval .table td:last-child {
  border-radius: 0 2px 2px 0;
}
#service-interval .system-body .tabular-interval .table td.border-indicator {
  border-left: 3px solid transparent;
}
#service-interval .system-body .card-interval {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 1.5rem;
}
#service-interval .system-body .card-interval .card {
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0px #efefef;
}
#service-interval .system-body .card-interval .card .card-body {
  border-top: 3px solid transparent;
  border-radius: 2px;
  font-size: 0.875rem;
  align-items: flex-start;
}
#service-interval .btn-primary, .system-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
#service-interval .btn-success, .system-card .btn-success {
  background-color: #28bb00;
  border-color: #28bb00;
}
#service-interval .btn-light {
  background-color: transparent;
  border-color: transparent;
  color: #6d6d6d;
}
#service-interval .btn-light:hover {
  background-color: #f8f9fa;
  color: #212529;
}
#service-interval .filter-btn {
  position: relative;
}
#service-interval .filter-btn button {
  background-color: #fafafa;
  cursor: pointer;
  border: 1px solid #999999;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#service-interval .filter-btn select {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  z-index: 2;
  font-size: 0.875rem;
  appearance: none;
  opacity: 0;
}
.required::after {
  content: '*';
  color: #dd3444;
  margin-left: 3px;
}
#service-interval .col-form-label {
  font-size: 0.75rem;
  color: #9d9d9d;
  display: block;
  margin-bottom: 0.25rem;
  padding: 0;
  line-height: 1.1;
} 
#service-interval .interval-comment {
  background-color: #f5f5f5;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #f1f1f1;
  position: relative;
  margin-bottom: 0.325rem;
}
#service-interval .interval-comment .comment-author {
  font-weight: 600;
  color: #9d9d9d;
  font-size: 0.75rem;
  line-height: 1.1;
  margin: 0;
}
#service-interval .interval-comment .comment-text {
  margin: 0;
}
#service-interval .interval-comment .comment-time {
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
  color: #9d9d9d;
  background-color: #f5f5f5;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1;
  margin: 0;
}
#service-interval .system-card .badge-indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #a8a8a8;
  border-radius: 0.125rem;
  display: inline-block;
  margin-right: 0.5rem;
}
#service-interval .timeline li {
  font-size: 0.825rem;
  padding-right: 0;
}
.service-interval-modal .tb-modal-body {
  padding: 0 2.5rem;
  font-size: 0.875rem;
}
.service-interval-modal .form-group .form-control {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  transition: all 450ms ease
}
.service-interval-modal .form-group .form-control.has-error,
input.has-error {
  border-bottom-color: tomato;
}
.service-interval-modal .form-group input.form-control,
.service-interval-modal .form-group select.form-control {
  height: 1.5rem;
}
.service-interval-modal .form-group select.form-control {
  padding: 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;;
}
.service-interval-modal .form-group textarea.form-control {
  font-size: 0.875rem;
  padding: 0.375rem;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}
.service-interval-modal .form-group .form-control:focus {
  border-color: #558dca !important;
}
.service-interval-modal .form-group .form-control:read-only {
  background-color: #ffffff;
}
.service-interval-modal .react-datepicker-wrapper,
.service-interval-modal .react-datepicker__input-container {
  width: 100%;
}
.service-interval-modal .form-group .custom-file {
  font-size: 0.875rem;
}
.service-interval-modal .form-group .custom-file-input ~ .custom-file-label::after {
  content: "\e946" !important;
  font-family: 'icomoon';
  border-radius: 0;
}
.service-interval-modal .form-group .custom-file-label {
  height: calc(1.5rem + 0.75rem - 1px);
  padding: 0.375rem;
  border-radius: 3px;
  border: 1px solid #eaeaea;
}
.file-list {
  margin-top: 1rem;
  list-style: none;
  padding: 0;
}
.file-list li {
  font-size: 0.825rem;
}
.file-list li .icon-attachment{
  color: #bfbfbf;
}
.file-list li .icon-close {
  font-size: 0.625rem;
  color: #dd3444;
}
.text-underline {
  text-decoration: underline !important;
}
select:invalid { 
  color: #a1a1a1;
}

.custom-control-label {
  color: #797979;
}
.custom-control-label::before {
  width: 0.875rem;
  height: 0.875rem;
  border-color: #eaeaea;
}
.custom-control-label::after {
  top: calc(0.25rem + 1px);
  left: calc(-1.5rem + 1px);
  width: calc(0.875rem - 2px);
  height: calc(0.875rem - 2px);
}
.custom-control-input:checked~.custom-control-label::before {
  background-color: #ffffff;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background: #558dca;
  border: 3px solid #ffffff;
  border-radius: 50%;
}
.inputGroup {
  background-color: #fff;
  display: block;
  position: relative;
  flex: 1;
}
.inputGroup label {
  padding: 0.25rem 0.35rem;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 400ms ease-in;
  overflow: hidden;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  margin-bottom: 0;
}
.inputGroup + .inputGroup {
  margin-left: 0.5rem;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 16px;
  height: 16px;
  content: '';
  border: 1px solid #eaeaea;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -1px 0px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 0.35rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  /* color: #fff; */
  border-color: #558dca;
}
.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #558dca;
  border-color: #558dca;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.custom-checkbox input[type="checkbox"] {
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: all 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  margin-right: 0.5rem;
}
.custom-checkbox input[type="checkbox"]::before {
  position: absolute;
  content: '';
  display: block;
  /* top: 1px; */
  left: 4px;
  width: 6px;
  height: 11px;
  border-style: solid;
  border-color: #558dca;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.custom-checkbox input[type="checkbox"]:checked {
  color: #ffffff;
  border-color: #558dca;
  /* background-color: #558dca; */
}
.custom-checkbox input[type="checkbox"]:checked::before {
  opacity: 1;
}
.custom-checkbox label {
  margin: 0;
}
.comment-form {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0.5rem 0.5rem;
}
.comment-form textarea {
 border: 0;
 resize: none;
 padding: 0;
 font-size: 0.875rem;
 outline: none !important;
 box-shadow: none !important;
}
.comment-form button {
  padding: 0.25rem 0.5rem;
  line-height: 1.2rem;
  font-size: 0.875rem;
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker-popper {
  z-index: 10;
}

@media (max-width: 576px) {
  #service-interval .filter-input-active {
    max-width: 200px;
  }
}
@media (min-width: 769px) {
  #service-interval {
    padding: 2.5rem 2rem;
  }
  #service-interval .system-header h4 {
    line-height: 2rem;
  }
  #service-interval .system-body {
    padding-top: 1rem;
  }
}
@media (max-width: 768px) {
  #service-interval .system-header {
    padding: 0.5rem 1.25rem 1rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 25px - 0.625rem);
    z-index: 999;
  }
  #service-interval .system-header h4 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  #service-interval .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #service-interval .system-header p span {
    text-decoration: none;
  }
  #service-interval .system-header .filter-btn {
    margin-right: 0;
  }
  #service-interval .system-header .filter-btn button {
    border-radius: 50%;
    background-color: transparent;
  }
  #service-interval .system-header .btn-add {
    font-size: 1.25rem;
    padding: 0.125rem 0.625rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
  #service-interval .service-interval-body {
    margin-top: 10rem;
    margin-right: 0;
    margin-left: 0;
  }
  #service-interval .system-body {
    margin-top: 10.5rem;
    padding: 0 1rem;
    margin-right: 0;
    margin-left: 0;
  }
  #service-interval .system-body .system-names {
    background-color: #3b3b3b;
    /* padding-bottom: 0.5rem; */
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 1rem + 53px - 0.625rem);
    z-index: 2;
  }
  #service-interval .system-body .system-names .list-group {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #service-interval .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #service-interval .system-body .system-names .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #service-interval .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  #service-interval .system-body .tabular-interval {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
  }
  #service-interval .system-body .tabular-interval .table {
    margin-bottom: 0;
  }
  #service-interval .tabular-interval thead,
  #service-interval .tabular-interval th {
    display: none;
  }
  #service-interval .tabular-interval .table,
  #service-interval .tabular-interval tbody,
  #service-interval .tabular-interval td,
  #service-interval .tabular-interval tr {
    display: block;
  }
  #service-interval .tabular-interval tr {
    margin-top: 0.75rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  #service-interval .system-body .tabular-interval .table td.border-indicator {
    border-left: 0;
    border-top: 3px solid transparent;
  }
  #service-interval .system-body .tabular-interval .table td:first-child {
    border-radius: 2px 2px 0 0;
    font-weight: bold;
    flex-basis: 100%;
  }
  #service-interval .system-body .tabular-interval .table td:last-child {
    position: absolute;
    top: 3px;
    right: 0;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2),
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3),
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4) {
    position: relative;
    display: inline-block;
    font-size: 13px;
    padding-top: 1rem;
    padding-bottom: 1.25rem;
    flex: 1;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2)::before,
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3)::before,
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4)::before {
    position: absolute;
    top: 0;
    white-space: nowrap;
    color: #b7b7b7;
    font-size: 12px;
    content: attr(data-header);
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2) {
    text-align: left;
    border-radius: 0 0 0 3px;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3) {
    text-align: center;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4) {
    text-align: right;
    border-radius: 0 0 3px 0;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2):before {
    left: 0.75rem;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3):before {
    left: 50%;
    transform: translateX(-50%);
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4):before {
    right: 0.75rem;
  }
  #service-interval .interval-detail .card-header {
    display: flex;
    align-items: center;
    background-color: #eff3fa;
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #service-interval .interval-detail h6 {
    font-size: 0.875rem;
  }
  #service-interval .interval-detail .btn-back {
    padding-left: 0;
    font-size: 0.75rem;
  }
  #service-interval .interval-detail hr {
    margin: 0.5rem 0 1rem;
  }
  #service-interval .interval-detail .form-group {
    margin-bottom: 0.5rem;
  }
  #service-interval .interval-detail .col-form-label {
    margin-bottom: 0;
  }
  #service-interval .interval-detail .col-form-label + p {
    font-size: 0.825rem;
  }
  #service-interval .interval-detail .service-data {
    margin-top: 1rem;
  }
  #service-interval .interval-detail .comment-text {
    font-size: 0.8rem;
  }
  #service-interval .interval-detail .comment-form textarea {
    font-size: 0.8rem;
  }
  #service-interval .interval-detail .btn-sm {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }
  .service-interval-modal .tb-modal-body {
    padding: 0 1.25rem;
  }
  .border-right {
    border-right: 0 !important;
  }
  .inputGroup label {
    font-size: 13px;
    padding: 0.325rem 0.5rem;
  }
  .inputGroup label:after {
    width: 16px;
    height: 16px;
    right: 0.5rem;
  }
  .profile input {
    font-size: 13px;
  }

  .custom-checkbox label {
    margin-right: 0;
  }
}
#service-interval button {
  outline: 0 !important;
  box-shadow: none !important;
}