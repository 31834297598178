.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}
