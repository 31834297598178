#system-health {
  font-family: 'Nunito';
  padding-bottom: 1rem;
}
#system-health .component-sidebar {
  position: absolute;
  top: calc(7.5rem + 6px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#system-health .component-sidebar.open {
  left: 0;
}
#system-health .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#system-health .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  flex: 1;
}
#system-health .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  cursor: pointer;
}
#system-health .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#system-health .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#system-health .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#system-health .system-header p span {
  text-decoration: underline;
}
#system-health .system-header .btn-pill {
  border-radius: 25rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #3b3b3b;
  border-color: #3b3b3b;
}
#system-health .system-header .btn-pill .icon {
  font-size: 75%;
}
#system-health .system-header .btn-pill:hover,
#system-health .system-header .btn-pill:active,
#system-health .system-header .btn-pill:focus {
  background-color: #3b3b3b;
  color: #ffffff;
}
#system-health .system-header .btn-icon {
  border-radius: 50%;
  padding: 0.375rem 0.625rem;
}
#system-health .system-body .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#system-health .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#system-health .system-body .systems {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#system-health .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#system-health .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#system-health .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#system-health .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#system-health .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#system-health .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#system-health .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#system-health .system-card .header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
}
#system-health .system-card .header h5 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0;
}
#system-health .system-card .form-group {
  max-width: 350px;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  border-bottom: 1px solid #dcdcdc;
}
#system-health .system-card .form-group:last-child {
  border: 0;
}
#system-health .system-card p.system-status {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  position: relative;
}
#system-health .system-card p.system-status .helper-icon {
  font-size: 0.75rem;
  color: #a8a8a8;
}
#system-health .system-card p.system-status .helper {
  font-size: 0.75rem;
  color: #a8a8a8;
}
#system-health .system-card p.system-status .col-form-label {
  font-size: 0.875rem;
  padding: 0;
  color: #1a1818;
  display: flex;
  align-items: center;
}
#system-health .system-card p.system-status .col-form-label select {
  border: 0 !important;
}
#system-health .system-card p.system-status .col-form-label select option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
}
#system-health .system-card p.system-status + input {
  padding: 0;
  border: 0;
  font-size: 0.875rem;
  color: #1a1818;
  border-bottom: 1px solid #1a1818;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
}
#system-health .system-card p.system-status + input:focus {
  border-color: #558dca;
}
#system-health .system-card .btn-outline-primary {
  border-color: #558dca;
  color: #558dca;
  font-size: 0.75rem;
}
#system-health .system-card .badge-indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #a8a8a8;
  border-radius: 0.125rem;
  display: inline-block;
  margin-right: 0.5rem;
}
#system-health .system-card .btn-outline-primary:active, #system-health .system-card .btn-outline-primary:hover, #system-health .system-card .btn-outline-primary.active {
  background-color: #558dca;
  color: #ffffff;
}
/* #system-health .system-card .collapse {
  max-height: 350px;
}
#system-health .system-card .collapse:not(.show) {
  max-height: 0;
} */
#system-health .history-box,
#readings .history-box {
  background-color: #ffffff;
  transform: rotateX(90deg);
  transform-origin: top;
  opacity: 0;
  min-height: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  transition: all 450ms ease-in-out;
}
#system-health .history-box.open,
#readings .history-box.open {
  transform: rotateX(0);
  opacity: 1;
  min-height: 100%;
}
#system-health .history-block,
#readings .history-block {
  background-color: #f9f9f9;
  padding-bottom: 0.5rem;
  text-align: center;
}
#system-health .history-box .header,
#readings .history-box .header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
}
#system-health .history-box .header h5,
#readings .history-box .header h5 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0;
}
#system-health .history-box .header .close,
#readings .history-box .header .close {
  margin-left: auto;
  float: right;
  color: #ff3939;
  font-size: 0.875rem;
  cursor: pointer;
}
#system-health .history-box .body,
#readings .history-box .body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem 1.75rem;
  opacity: 0;
}
#system-health .history-box.open .body,
#readings .history-box.open .body {
  opacity: 1;
}
#system-health .history-box .body > *,
#readings .history-box .body > * {
  max-width: 400px;
  width: 100%;
}
.system-card .service-body {
  font-family: 'Nunito';
}
.system-card .service-body h6 {
  font-weight: bold;
}
.system-card .service-body h6 .icon {
  font-size: 0.375rem;
  line-height: 1.125rem;
  transform-origin: center;
  transform: rotate(0);
  transition: all 300ms ease;
}
.system-card .service-body h6 .icon.open {
  transform: rotate(180deg);
}
.system-card .service-body .service-group {
  margin-bottom: 1rem;
}
.system-card .service-body .react-datepicker-wrapper, 
.system-card .service-body .react-datepicker__input-container {
  display: block;
}
.system-card .service-body .service-label {
  font-size: 0.6875rem;
  line-height: 1.36;
  color: #a8a8a8;
  margin: 0;
}
.system-card .service-body .service-data {
  font-size: 0.8125rem;
  line-height: 1.38;
  color: #1a1818;
  margin: 0;
}
.system-card .service-body .service-label + input {
  font-size: 0.8125rem;
  line-height: 1.38;
  border: 0;
  padding: 0;
  border-bottom: 1px solid #1a1818;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
  display: block;
}
.system-card .service-body .service-label + input:focus {
  border-color: #558dca;
}
.system-card .service-body .service-input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #979797;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 0.75rem;
  line-height: 1.333;
  padding: 0 0 0.25rem;
  color: #1a1818;
}
.system-card .service-body .service-input:focus {
  border-color: #558dca;
}
.system-card .service-body .service-input.error {
  border-color: #ff3939;
}
.system-card .service-body .service-input::placeholder {
  color: #e7e7e7;
}
#system-health .btn-primary,
.system-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.system-card .service-body .helper {
  font-size: 0.7rem;
  text-align: center;
  display: inline-block;
  margin-top: 1rem;
  cursor: pointer;
}
.system-card .service-body .interval-history {
  font-size: 0.75rem;
  color: #1a1818;
  margin-bottom: 0.25rem;
  display: flex;
}
.system-card .service-body .interval-history span {
  color: #a8a8a8;
  white-space: nowrap;
  align-self: flex-end;
}
.system-card .service-body .service-link {
  text-decoration: underline;
  color: #558dca;
  font-size: 0.75rem;
  line-height: 1.583;
  cursor: pointer;
}

.timeline {
  padding-left: 0;
  margin-left: 50%;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: #a8a8a8;
  list-style: none;
  width: 150px !important;
  position: relative;
  border-left: 1px solid #3b3b3b;
}
.timeline li {
  padding: 0.5rem 1rem;
  position: relative;
  margin-left: -1px;
  border-left: 1px solid #3b3b3b;
  text-align: left;
}
.timeline li:last-child {
  padding-bottom: 0;
}
.timeline li::before,
.timeline.extra li .timeline-left {
  position: absolute;
  right: calc(100% + 1rem);
  content: attr(data-date);
  font-size: 0.75rem;
  line-height: 0.875rem;
  white-space: nowrap;
  color: #a8a8a8;
}
.timeline.extra li .timeline-left {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.timeline.extra li::before {
  content: unset;
}
.timeline li::after {
  position: absolute;
  top: 0.65rem;
  right: calc(100% + 1px);
  content: '';
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent; 
  border-right: 0.25rem solid #3b3b3b;
}
.timeline.new {
 padding-bottom: 1rem;
} 
.timeline.new li:first-child {
  padding-top: 0;
}
.timeline.new li:first-child::after {
  top: 0.2rem;
}
.system-sliding-pane  .slide-pane__content {
  padding: 1.5rem 0;
}
.system-sliding-pane h5 {
  font-family: 'Nunito';
  font-size: 1.125rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0 2rem 1.5rem;
}
.system-sliding-pane h5 .close {
  margin-left: auto;
  float: right;
  color: #ff3939;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.system-sliding-pane .system-card {
  padding: 0rem 2rem 1.5rem;
}
.system-sliding-pane .system-card h6 {
  font-size: 0.8125rem;
}
.system-sliding-pane .system-card h6 .badge {
  width: 0.75rem;
  height: 0.75rem;
  margin-bottom: -0.125rem;
  display: inline-block;
}
.system-sliding-pane .system-card .service-body .collapsing {
  width: 100%;
}
hr {
  border-top: 0;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 769px) {
  #system-health {
    padding: 2.5rem 2rem 1rem;
  }
  #system-health .system-header h4 {
    line-height: 2rem;
  }
  #system-health .system-body {
    padding-top: 1rem;
  }
  .slide-pane {
    width: 400px !important;
  }
}
@media (max-width: 768px) {
  #system-health .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999;
  }
  #system-health .system-header h4 {
    font-size: 1.25rem;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #system-health .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #system-health .system-header p span {
    text-decoration: none;
  }
  #system-health .system-header .subtitle {
    color: #a2a2a2;
    font-size: 0.625rem;
  }
  #system-health .system-header .btn-icon {
    font-size: 0.875rem;
    line-height: 1;
    padding: 5px 6px;
  }
  #system-health .system-header .btn-outline-secondary {
    border-color: #ffffff;
    color: #ffffff;
    font-size: 0.625rem;
    padding: 0.25rem 0.75rem;
  }
  #system-health .system-header .btn-outline-secondary:hover,
  #system-health .system-header .btn-outline-secondary:active,
  #system-health .system-header .btn-outline-secondary:focus {
    background-color: #ffffff;
    color: #3b3b3b;
  }
  #system-health .system-body {
    margin-top: 10.5rem;    
    margin-right: 0;
    margin-left: 0;
  }
  #system-health .system-body .system-names {
    background-color: #3b3b3b;    
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 1rem + 53px - 0.625rem);
    z-index: 2;
  }
  #system-health .system-body .system-names .list-group {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #system-health .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #system-health .system-body .system-names .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #system-health .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  .slide-pane {
    width: 100% !important;
  }
}

.bulk-audit-action{
  margin-top: 20px;
  z-index: 1;
  width: 275px;
}

.btn-bulk-update{
  margin: 10px auto;
  display: block;
}