.sh-table {
  font-family: Inter;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: #ffffff;
}
.sh-table td {
  padding: 12px 12px;
}
.sh-subtable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: none;
}
.status-critical,
.status-Red {
  background-color: #ff8d8d;
}
.status-warning,
.status-Amber {
  background-color: #ffdb1c;
}
.status-normal,
.status-Green {
  background-color: #01d09f;
}

.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}
table.child-table-border-separate {
  /* border-collapse: collapse !important; */
  border-collapse: separate !important;
  border-spacing: 0px 5px !important;
}
@media (max-width: 990px) {
  .back-button {
    margin-top: -20px;
  }
}

/* modal styles */
.modal-content {
  margin-top: 160px;
  border-radius: 8px;
}
.modal-body {
  padding: 0px;
}
.tableFixHead tbody tr td {
  border-bottom: 1px solid #e8e8e8;
}
