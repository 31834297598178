/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    outline: none;
    background: #FFFFFF;
    /* border: 0.0625rem solid #CCCCCC; */
    /* box-sizing: border-box; */
    border-radius: 0.1875rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
    width: 100%;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    outline: none;
    background: #FFFFFF;
    border: 0.0625rem solid #CCCCCC;
    box-sizing: border-box;
    width: 100%;
    padding: 0.3125rem;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 2px 2px;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 100%;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

/* modal */

.modal-body {
    padding: 24px;
}

