#contact-info .contacts-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.5fr));
  grid-gap: 1.5rem;
}
#contact-info .contacts-wrapper .contact-card {
  transition: all .3s ease;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  min-height: 1px;
}
#contact-info .contacts-wrapper .contact-card .info-group {
  font-size: 0.875rem;
  color: #1a1818;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#contact-info .contact-card .helper, 
#contact-info .contact-card .helper-icon {
  font-size: .75rem;
  color: #a8a8a8;
  margin-top: 0;
}
#contact-info .contact-card input {
  padding: 0;
  border: 0;
  font-size: .875rem;
  color: #1a1818;
  border-bottom: 1px solid #1a1818;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
}
#contact-info .contact-card input:focus {
  border-color: #92beef;
}

#contact-info .btn-outline-primary {
  border-color: #558dca;
  color: #558dca;
  font-size: 0.75rem;
}
#contact-info .edit-button {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media (max-width: 768px) {
  #contact-info .contacts-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}