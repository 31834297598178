.navbar {
    width: 100%;
    background-color: #3b3b3b;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 999;
}
.navbar .nav-brand {
    /* margin-left: 1.5rem;
    margin-right: 1.5rem; */
    padding-right: 2rem;
    /* border-right: 1px solid #555555; */
}
.navbar .nav-brand .logo {
    height: 2.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.navbar .nav-brand .logo.wordmark {
    padding-top: 0.75rem;
}
.navbar #logo-wordmark {
    height: 1rem;
}
.navbar .profile_logo div{
  font-family: 'Nunito';
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
}
.navbar .profile_logo img {
    /* height: 48px; */
    /* margin-right: 40px; */
    border-radius: 10px;

}
.navbar .logo_name {
  height: 26px;
  font-family: 'Barlow';
  font-size: 22px;
  color: #ffffff;
  /* position: relative; */
  /* top: 4px; */
}
.navbar .building_name {
  font-family: 'Nunito';
  color: white;
}
.navbarLabel{
    font-family: 'Nunito';	
    font-size: 23px;	
}
.navbarLabelActive{
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 500;
    background-color: rgb(74, 144, 226, 0.08) !important;
    /* box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); */
    /* padding-left: 4px;
    padding-right: 4px; */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 3px solid #4A90E2;
}

.activeBottombar {
    height:3px; 
    /* width: 85%;  */
    background-color: #4A90E2;

} 

@media (max-width: 576px) { 
    .navbarLabel{
        font-family: 'Nunito';	
        font-size: 15px;
        margin-left: 3px;	
    }
    .navbarLabelActive{
        font-family: 'Nunito';	
        font-size: 15px;	
        font-weight: 600;
        margin-left: 3px;
    }
    .activeBottombar {
        height:2px; 
        /* width: 85%; */
        background-color: #4A90E2
    } 

 }