.legend {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    }
    .legend-text{
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
    }
    .boxDanger {
        flex-shrink:0;
        width: 14px;
        height: 14px;
        background: #FF8D8D;
        margin-right:6px;
        border-radius: 2px;
    }

    .boxNormal {
        flex-shrink:0;
        width: 14px;
        height: 14px;
        background: #01D09F;
        margin-right:6px;
        border-radius: 2px;
    }

    .boxWarning {
        flex-shrink:0;
        width: 14px;
        height: 14px;
        background: #FFDB1C;
        margin-right:6px;
        border-radius: 2px;
    }

  