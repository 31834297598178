.system-sliding-pane.notifications .slide-pane__content {
  padding: 1.5rem 2rem;
}
.system-sliding-pane.notifications h5 {
  margin: 0;
}
.system-sliding-pane.notifications h6 {
  font-size: 0.875rem;
  font-weight: bold;
  font-family: 'Nunito';
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.system-sliding-pane.notifications .notification {
  border: 1px solid #f1f1f1;
  border-left: 0.25rem solid #eaeaea;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-top: 1rem;
}
.system-sliding-pane.notifications .notification p.name {
  margin: 0;
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #494949;
}
.system-sliding-pane.notifications .notification p.time {
  font-family: 'Nunito';
  font-size: 0.625rem;
  color: #b2b2b2;
  margin-bottom: 0;
}
.system-sliding-pane.notifications .view-all-link {
  font-size: 0.625rem;
  color: #558dca !important;
  display: block;
  text-align: right;
  text-decoration: underline !important;
  cursor: pointer;
  margin-top: 0.5rem;
}
.notification.red {
  border-left-color: #ff6a6a !important;
}
.notification.amber {
  border-left-color: #ffcd39 !important;
}
.notification.green {
  border-left-color: #28bb00 !important;
}
@media(max-width: 768px) {
  .system-sliding-pane.notifications h6 {
    margin-top: 1rem;
  }
  .system-sliding-pane.notifications .notification {
    margin-top: 0.5rem;
  }
}