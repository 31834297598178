:root {
  --color-active: #14d5ff;
  --color-completed: #73d48e;
  --color-defaulted: #ff8989;
  --color-upcoming: #999999;
  --color-white: #fff;
  --color-gray-1: #d6d6d6;
  --color-gray-2: #EEEEEE
; }

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.8eb1d16e.eot);
  src: url(/static/media/icomoon.8eb1d16e.eot#iefix) format("embedded-opentype"), url(/static/media/icomoon.744c3d83.ttf) format("truetype"), url(/static/media/icomoon.86ce2372.woff) format("woff"), url(/static/media/icomoon.7e50a8ad.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-record-outline:before {
  content: "\E93D"; }

.icon-alert:before {
  content: "\E93C"; }

.icon-minus:before {
  content: "\E93A"; }

.icon-plus:before {
  content: "\E93B"; }

.icon-info:before {
  content: "\E935"; }

.icon-mail:before {
  content: "\E936"; }

.icon-name:before {
  content: "\E937"; }

.icon-phone:before {
  content: "\E938"; }

.icon-tag:before {
  content: "\E939"; }

.icon-pencil:before {
  content: "\E925";
  color: #558dca; }

.icon-lock:before {
  content: "\E926"; }

.icon-converter .path1:before {
  content: "\E900";
  color: #b7b7b7; }

.icon-converter .path2:before {
  content: "\E901";
  margin-left: -0.900390625em;
  color: #3b3b3b; }

.icon-default-daily:before {
  content: "\E924"; }

.icon-attendance:before {
  content: "\E914"; }

.icon-water_outflow:before {
  content: "\E918"; }

.icon-arrow-left:before {
  content: "\E922"; }

.icon-menu:before {
  content: "\E91A"; }

.icon-qr-code:before {
  content: "\E91B"; }

.icon-notification:before {
  content: "\E91C"; }

.icon-daily_readings:before {
  content: "\E910"; }

.icon-dashboard_home:before {
  content: "\E90F"; }

.icon-chevron-right:before {
  content: "\E91D"; }

.icon-chevron-down:before {
  content: "\E91E"; }

.icon-arrow-right:before {
  content: "\E91F"; }

.icon-pool:before {
  content: "\E916"; }

.icon-stp:before {
  content: "\E915"; }

.icon-water_inflow:before {
  content: "\E917"; }

.icon-power:before {
  content: "\E919"; }

.icon-logout:before {
  content: "\E920"; }

.icon-staff_activities:before {
  content: "\E912"; }

.icon-adhoc_task:before {
  content: "\E913"; }

.icon-settings:before {
  content: "\E923"; }

.icon-reports:before {
  content: "\E921"; }

.icon-system_status:before {
  content: "\E911"; }

.icon-hamburger:before {
  content: "\E902"; }

.icon-search:before {
  content: "\E903"; }

.icon-history:before {
  content: "\E904"; }

.icon-carpenter:before {
  content: "\E905"; }

.icon-diesel-generator:before {
  content: "\E906"; }

.icon-electrician:before {
  content: "\E907"; }

.icon-electricity:before {
  content: "\E908"; }

.icon-fire:before {
  content: "\E909"; }

.icon-garden:before {
  content: "\E90A"; }

.icon-house_keeping:before {
  content: "\E90B"; }

.icon-lift:before {
  content: "\E90C"; }

.icon-plumber:before {
  content: "\E90D"; }

.icon-security:before {
  content: "\E90E"; }

.icon-filter:before {
  content: "\E927"; }

.icon-sort:before {
  content: "\E928"; }

.icon-close:before {
  content: "\E929"; }

.icon-calendar:before {
  content: "\E92A"; }

.icon-download:before {
  content: "\E92B";
  color: #3b3b3b; }

.icon-tick:before {
  content: "\E92C"; }

.icon-screw-driver:before {
  content: "\E92D"; }

.icon-clock:before {
  content: "\E92E"; }

.icon-gas-cylinder:before {
  content: "\E92F"; }

.icon-cctv:before {
  content: "\E930"; }

.icon-transformers:before {
  content: "\E931"; }

.icon-gym:before {
  content: "\E932"; }

.icon-water-tank:before {
  content: "\E933"; }

.icon-wtp:before {
  content: "\E934"; }

.icon-camera:before {
  content: "\E93E"; }

.icon-camera-off:before {
  content: "\E93F"; }

.icon-image:before {
  content: "\E940"; }

.icon-alert-circle:before {
  content: "\E941"; }

.icon-remove:before {
  content: "\E942"; }

.icon-cancel:before {
  content: "\E942"; }

.icon-times:before {
  content: "\E942"; }

.icon-close-circle:before {
  content: "\E942"; }

.icon-refresh:before {
  content: "\E943"; }

.icon-renew:before {
  content: "\E943"; }

.icon-message:before {
  content: "\E944"; }

.icon-comment:before {
  content: "\E944"; }

.icon-attachment:before {
  content: "\E945"; }

.icon-upload-cloud:before {
  content: "\E946"; }

.icon-tickets:before {
  content: "\E947"; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

select {
  border: 0;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid;
  border-radius: 0;
  -webkit-appearance: none;
  background-image: url(/static/media/chevron-down.d474c6eb.svg);
  background-position: 97%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-right: 1.25rem !important; }

.cursor-pointer {
  cursor: pointer; }

.text-indicator {
  font-weight: bold;
  color: #3d3d3d !important; }

.text-color {
  color: #3d3d3d; }

.text-color.red {
  color: #ff6a6a !important; }

.text-color.amber {
  color: #ffbf00 !important; }

.text-color.green {
  color: #28bb00 !important; }

.text-color.blue {
  color: #266afa !important; }

.badge-indicator.red {
  background-color: #ff6a6a !important; }

.badge-indicator.amber {
  background-color: #ffcd39 !important; }

.badge-indicator.green {
  background-color: #28bb00 !important; }

.badge-indicator.primary {
  background-color: #558dca !important; }

.badge-indicator.grey {
  background-color: #bdbdbd !important; }

.border-indicator {
  border-color: transparent !important; }

.border-indicator.red {
  border-color: #ff6a6a !important; }

.border-indicator.amber {
  border-color: #ffcd39 !important; }

.border-indicator.green {
  border-color: #28bb00 !important; }

.border-indicator.grey {
  border-color: #bdbdbd !important; }

.border-indicator.blue {
  border-color: #266afa !important; }

.border-indicator.danger {
  border-color: #ff6a6a !important; }

.border-indicator.success {
  border-color: #73d58e !important; }

.border-indicator.primary {
  border-color: #14d5ff !important; }

.linear-border {
  background-image: linear-gradient(#28bb00, #28bb00); }

.linear-border.amber {
  background-image: linear-gradient(#ffbf01, #ffbf01); }

.text-danger {
  color: #ff6a6a !important; }

.text-primary {
  color: #558dca !important; }

.modal-full {
  width: calc(100vw - 1rem);
  height: calc(100vh - 1rem); }

.modal-full .modal-content {
  height: 100%; }

.icon-2x {
  font-size: 1.125em; }

input[type="radio"] {
  display: none; }

.navbar {
    width: 100%;
    background-color: #3b3b3b;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 999;
}
.navbar .nav-brand {
    /* margin-left: 1.5rem;
    margin-right: 1.5rem; */
    padding-right: 2rem;
    /* border-right: 1px solid #555555; */
}
.navbar .nav-brand .logo {
    height: 2.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.navbar .nav-brand .logo.wordmark {
    padding-top: 0.75rem;
}
.navbar #logo-wordmark {
    height: 1rem;
}
.navbar .profile_logo div{
  font-family: 'Nunito';
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
}
.navbar .profile_logo img {
    /* height: 48px; */
    /* margin-right: 40px; */
    border-radius: 10px;

}
.navbar .logo_name {
  height: 26px;
  font-family: 'Barlow';
  font-size: 22px;
  color: #ffffff;
  /* position: relative; */
  /* top: 4px; */
}
.navbar .building_name {
  font-family: 'Nunito';
  color: white;
}
.navbarLabel{
    font-family: 'Nunito';	
    font-size: 23px;	
}
.navbarLabelActive{
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 500;
    background-color: rgb(74, 144, 226, 0.08) !important;
    /* box-shadow: 0 0 5px 0 rgba(0,0,0,0.1); */
    /* padding-left: 4px;
    padding-right: 4px; */
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 3px solid #4A90E2;
}

.activeBottombar {
    height:3px; 
    /* width: 85%;  */
    background-color: #4A90E2;

} 

@media (max-width: 576px) { 
    .navbarLabel{
        font-family: 'Nunito';	
        font-size: 15px;
        margin-left: 3px;	
    }
    .navbarLabelActive{
        font-family: 'Nunito';	
        font-size: 15px;	
        font-weight: 600;
        margin-left: 3px;
    }
    .activeBottombar {
        height:2px; 
        /* width: 85%; */
        background-color: #4A90E2
    } 

 }
.drp-container {
    display: inline-block;
}
.drp-button {
    padding: 0;
    width: 50px;
    border: 0;
    background-color: #3b3b3b;
    color: #fff;
    cursor: pointer;
    outline: 0;
    /* font-size: 40px; */
}

.mobile-drpdwn .drp-dropdown {
    top: calc(100% - 0.625rem - 5px);
}
.drp-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04); */
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: #3b3b3b;
    color: #fff;
    border-radius: 5px;
}
  
.drp-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Nunito";
    font-weight: 400;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 10px;
}
.drp-dropdown ul::-webkit-scrollbar {
    display: inline;
    display: initial;
    scrollbar-width: initial;
}
.drp-dropdown .drp-search {
    padding: 8px 12px 8px 22px;
}
.drp-dropdown .drp-search input.form-control{
    box-shadow: none !important;
    outline: none !important;
    border: 0;
    border-radius: 0;
    padding: 0;
    padding-bottom: 3px;
    border-bottom: 1px solid #b7b7b7;
    color: #b7b7b7;
    background-color: transparent;
    font-style: italic;
}
.drp-dropdown li {
    padding: 8px 12px;
}

.drp-dropdown li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
}
#sidebar {
  width: 260px;
  height: calc(100vh - 135px);
  background-color: #3b3b3b;
  transition: all 0.3s ease;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: visible;
  margin: 40px;
  margin-right: 0;
  border-radius: 5px;
  margin-top: 115px;
}
#sidebar.open {
  width: 70px;
}
#sidebar.mobile {
  position: absolute;
  top: 0;
  height: 100vh;
  border-radius: 0;
  margin: 0;
  z-index: 1021;
  width: 260px !important;
  left: -260px;
}
#sidebar.mobile.open {
  left: 0;
}
#sidebar .expand-switch {
  background-color: transparent;
  border: 0;
  margin-right: 1.75rem;
  margin-top: 0.75rem;
  font-size: 0.675rem;
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all 300ms ease;
  color: #ffffff;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
#sidebar .mobile-back {
  margin-top: 1.25rem;
  margin-left: 1.75rem;
  display: inline-block;
  color: #fff;
}
#sidebar.open .expand-switch {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 1.5rem;
}
#sidebar .menu-items {
  width: 100%;
  overflow: auto;
}
#sidebar.open .menu-items {
  overflow: visible;
}
#sidebar .footer {
  margin-top: auto;
}
ul.nav {
  width: 100%;
  color: #ffffff;
  font-family: "Nunito";
  font-weight: 400;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}
ul.nav li.nav-item {
  position: relative;
  width: 100%;
}
ul.nav li.nav-item:hover {
  background-color: #2c2c2c;
}
ul.nav li.nav-item .nav-link {
  padding: 0.75rem 2rem 0.75rem 1.75rem;
  border-left: 0.25rem solid transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  line-height: 1.25rem;
  white-space: nowrap;
  color: #ffffff;
}
ul.nav li.nav-item .tracbot-nav-link {
  padding: 0.75rem 2rem 0.75rem 1.6rem;
}
ul.nav li.nav-item .tracbot-nav-link .logo {
  height: 1.1rem;
  margin-left: 0px;
  padding-right: 0.1rem;
}
ul.nav li.nav-item .nav-link span.nav-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  opacity: 1;
  transition: all 500ms ease;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
ul.nav li.nav-item .nav-link.active-nav {
  border-left-color: #558dca;
  background-color: #2c2c2c;
}
ul.nav li.nav-item .nav-link.opened {
  background-color: #313131;
}
ul.nav li.nav-item .nav-link i[class^="icon-"] {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  width: 2rem;
}
ul.nav li.nav-item .nav-link span.icon-badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  margin-right: 1.75rem;
  margin-left: 0.375rem;
  display: inline-block;
}
ul.nav li.nav-item .nav-link img {
  float: right;
  margin-left: auto;
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all 0.5s ease;
}
ul.nav li.nav-item .nav-link.opened img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
ul.nav li.nav-item ul.sub-menu {
  background-color: #313131;
  transition: all 300ms ease-in-out;
}
#sidebar.open ul.nav li.nav-item .nav-link {
  padding-left: 1.25rem;
}
#sidebar.open ul.nav li.nav-item .tracbot-nav-link {
  padding-left: 1rem;
}
#sidebar.open:not(.mobile) ul.nav li.nav-item .nav-link span.nav-text {
  opacity: 0;
}
#sidebar.open:not(.mobile) ul.nav li.nav-item ul.sub-menu {
  position: absolute;
  top: 0;
  left: 70px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
}
#sidebar.open:not(.mobile)
  ul.nav.sub-menu.show
  li.nav-item
  .nav-link
  span.nav-text {
  opacity: 1;
}
#sidebar ul.sub-menu li.nav-item a.nav-link {
  padding-left: 2.75rem;
}
#sidebar ul.sub-menu li.nav-item .nav-link i[class^="icon-"] {
  font-size: 1rem;
  width: 1.25rem;
  margin-right: 0.75rem;
  -webkit-justify-content: center;
          justify-content: center;
}

#sidebar .url-arrow-container {
  width: 1rem;
  background-color: #fff;
  height: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
}

#sidebar .url-arrow {
  font-size: 0.4rem;
  color: #2c2c2c;
  font-weight: bold;
}

.reading-card {
  transition: all ease 300ms;
  position: relative;
}
.reading-card .card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
  height: 100%;
  transition: all 450ms ease;
}
.reading-card .card:hover {
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.13);
}

.reading-card .card .card-header {
  background-color: #ffffff;
  padding: 1.25rem;
  border-bottom-color: #eaeaea !important;
  border-top: 3px solid transparent;
}
.reading-card .card .card-header h5 {
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0;
  display: inline-block;
  -webkit-align-items: center;
          align-items: center;
}
.reading-card .card .card-header p {
  font-size: 0.875rem;
  line-height: 1;
}
.reading-card .card .card-header .badge-indicator {
  width: 1rem;
  height: 1rem;
  background-color: #a8a8a8;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: -0.125rem;
}
.reading-card .card .card-header span.icon {
  font-size: 0.375rem;
  margin-left: auto;
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}
.reading-card .card .card-header span.icon.open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.reading-card .card .card-header .edit-icon {
  font-size: 1.25rem;
  margin-top: -0.5rem;
}
.reading-card .card .card-body {
  font-size: 0.8125rem;
  color: #a8a8a8;
  position: relative;
}
.reading-card .card .card-body p {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 1.625rem;
}
.reading-card .card .card-body .time-widget.edit {
  margin-bottom: 2.5rem !important;
  position: relative;
}
.reading-card .card .card-body .badge-indicator,
.modal .badge-indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #a8a8a8;
  border-radius: 0.125rem;
  display: inline-block;
  margin-right: 0.5rem;
  -webkit-align-self: center;
          align-self: center;
}
.reading-card .card .card-body p .progress-side {
  width: 50%;
  margin-bottom: 0.25rem;
}
.reading-card .card .card-body p .progress-side .progress {
  width: 100%;
  height: 0.625rem;
  border-radius: 25rem;
}
.reading-card .card .card-body p .progress-side .progress .progress-bar {
  height: 0.625rem;
  border-radius: 25rem;
  margin-right: 0;
}
.reading-card .card .card-body .sub {
  font-size: 0.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: -0.25rem;
}
.reading-card .edit-body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.reading-card .time-widget .edit-body {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.reading-card .edit-body input {
  text-align: center;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  color: #a8a8a8;
  line-height: 1;
  outline: none !important;
  box-shadow: none !important;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.reading-card .edit-body input:focus {
  border-bottom-color: #558dca;
}
.reading-card .edit-body input[type="datetime-local"] {
  margin-top: 0.5rem;
  margin-right: 0;
  position: absolute;
  top: 1rem;
}
.reading-card .card .card-body select.edit-body {
  border-color: #eaeaea;
}
.reading-card .edit-body span.helper {
  cursor: pointer;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #b7b7b7;
  color: #6e6e6e;
  font-weight: bolder;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.reading-card .edit-body span.helper:hover {
  background-color: #eaeaea;
}
.reading-card .card .card-body .overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reading-card .btn-tab {
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: bold;
  line-height: 1.75;
  outline: none !important;
  box-shadow: none !important;
  color: #a5a5a5;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}
.reading-card .btn-tab:hover {
  color: #3d3d3d;
}
.reading-card .btn-tab.active {
  color: #3d3d3d;
  border-bottom-color: #558dca;
}
.reading-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.reading-card .btn-primary:hover, .reading-card .btn-primary:active, .reading-card .btn-primary:focus {
  background-color: #3b76b6;
  border-color: #3b76b6;
}
.reading-card .text-muted {
  color: #9d9d9d !important;
}

.reading-card .graph-sidebar {
  position: absolute;
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-left: 0;
}
.reading-card .graph-sidebar.open {
  left: 0;
}
.reading-card .graph-sidebar .close {
  font-size: 0.625rem;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
.reading-card .graph-sidebar .list-group {
  border-radius: 1rem;
  color: #a5a5a5;
  font-size: 0.75rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.reading-card .graph-sidebar .list-group .list-group-item {
  border: 0;
  background-color: #ffffff !important;
  border-bottom: 2px solid;
  border-color: transparent;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  font-weight: bold;
  color: #a5a5a5;
  cursor: pointer;
}
.reading-card .graph-sidebar .list-group .list-group-item.active {
  z-index: auto;
  color: #3d3d3d;
  border-color: #558dca;
}
.reading-card .graph-sidebar .list-group .list-group-item:hover {
  color: #3d3d3d;
}

@media (max-width: 768px) {
  .reading-card .card .card-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .reading-card .card .card-header h5 {
    font-size: 0.875rem;
    width: 8.5rem;
  }
  .reading-card .card .card-header .badge-indicator {
    margin-left: 0.5rem;
  }
  .reading-card .card .card-header p {
    font-size: 0.625rem;
    margin-left: 0.5rem;
  }
  .reading-card .card .card-body p {
    font-size: 0.75rem;
  }
}

.transient-edit {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100px;
  padding: 1.25rem;
  background-color: #ffffff;
  position: absolute;
  inset: 0 0 0 100%;
  opacity: 0;
  overflow: hidden;
  transition: all ease 0.4s;
}
.transient-edit.active {
  inset: 0;
  opacity: 1;
}

.history-block {
  background-color: #f9f9f9;
  padding-bottom: .5rem;
  text-align: center;
}
.history-block .helper {
  display: block;
  text-align: center;
  font-size: 0.7rem;
  margin-top: 1rem;
}
.timeline-description-input {
  width: 90px;
  padding: 0 0 0.125rem;
  border-radius: 0;
  height: auto;
  line-height: 0.75rem;
  font-size: 11px;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 0.125rem;
  outline: none !important;
  box-shadow: none !important;
}
.timeline .icon-close,
.timeline .icon-tick {
  font-size: 9px;
  margin-top: -2px;
}
.button-radio {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 0.5rem;
}
.button-radio label {
  padding: 0.125rem 0.5rem;
  border: 1px solid var(--gray);
  color: var(--gray);
  border-radius: 4px;
}
.button-radio label:hover {
  background-color: #fafafa;
}
.button-radio input[type="radio"]:checked + label {
  background-color: var(--gray);
  border-color: var(--gray);
  color: #ffffff;
}
.history-descriptions .form-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ced4da;
  outline: none;
  box-shadow: none;
  padding: 0.125rem 0;
  line-height: 1.5;
  height: calc(1.5rem + 0.125rem + 1px);
}
.reading-card .edit-body input.rbt-input-main,
.reading-card .edit-body input.rbt-input-hint {
  width: auto;
  margin: 0;
  font-size: 0.875rem;
  border-radius: 0;
  height: calc(0.965rem + 0.25rem + 2px);
  text-align: left;
}
.reading-card .edit-body input.rbt-input-hint {
  color: #9d9d9d;
}
.reading-card .rbt-menu {
  padding: 0.25rem 0;
  font-size: 0.8rem;
}
.reading-card .rbt-menu a.reading-card .rbt-menu a {
  padding: 0.25rem 0.5rem;
  line-height: 1.1;
}
#readings {
  font-family: 'Nunito';
}
#readings .readings-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#readings .readings-header p span {
  text-decoration: underline;
}
#readings .readings-body .readings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 1.5rem;
}
#readings .reading-card .menu {
  font-size: 0.625rem;
  padding: 0.5rem;
  cursor: pointer;
}
#readings .reading-card .menu-list {
  display: -webkit-flex;
  display: flex;
  overflow-x: auto;
  margin-left: 0.25rem;
}
#readings .switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
  margin-bottom: 0;
}
#readings .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
#readings .slider {
  position: absolute;
  cursor: pointer;
  height: 16px;
  width: 26px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d8d8;
  transition: .4s;
}
#readings .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: #558dca;
  transition: .4s;
}
#readings input:checked + .slider {
  background-color: #558dca;
}
#readings input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
#readings input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
  left: -14px;
  background-color: white;
}
#readings .slider.round {
  border-radius: 34px;
  margin-top: 2px;
}
#readings .slider.round:before {
  border-radius: 50%;
}

@media (min-width: 769px) {
  #readings {
    padding: 2.5rem 2rem;
  }
  #readings .readings-header h4 {
    line-height: 2rem;
  }
  #readings .readings-body {
    padding-top: 1rem;
  }
}
@media (max-width: 768px) {
  #readings .readings-header {
    padding: 0.5rem 1.25rem 1rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999;
  }
  #readings .readings-header h4 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  #readings .readings-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #readings .readings-header p span {
    text-decoration: none;
  }
  #readings .readings-body {
    margin-top: 10rem;
    margin-right: 0;
    margin-left: 0;
  }
}
.recharts-legend-item-text {
  font-size: 0.75rem;
}
.heading{
    color: #666666;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 400;	
    line-height: 31px;
    margin-left: 20px;
}
.subheading{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 800;	
    line-height: 31px;
}
.buttonrmstyle{
    background-color: transparent;
    border: 0px;
    outline: none !important;
}
.back-arrow{
    height: 25px;
    width: 25px;
}
.cancelButton{
    height: 28px;	
    width: 79px;
    background-color: transparent;
    border: 0px;
    outline: none !important;
    color: #3B3B3B;
    font-size: 14px;
    line-height: 19px;
    margin-right: 30px;
    font-family: 'Nunito';
    border-radius: 3px;
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
}
.saveButton{
    height: 28px;	
    width: 79px;
    background-color: #4A90E2;
    border: 0px;
    outline: none !important;
    border-radius: 3px;
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Nunito';
}

.animate{
    opacity: 0;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}

.numeric_input{
  height: 30px;
  width: 100px;
  font-size: 13px;
  font-weight: 400;
  border : 0px;
  border-bottom: 2px solid #4A90E2;
  padding-left: 5px;
  outline: none !important;
  font-family: 'Nunito';
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav_container {
  height: 100px; 
  width: 100%; 
  background-color: #f2f2f2;   
  padding-left: 20px;
}
#offline-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 2rem;
  opacity: 0;
  text-align: center;
  -webkit-transform: scale3d(0, 0, 0);
          transform: scale3d(0, 0, 0);
  transition: all ease 300ms;
}
#offline-text span {
  color: #fff;
  background-color: #3b3b3b;
  padding: 0.5rem 1rem;
  border-radius: 25rem;
  font-size: 0.75rem;
  display: inline-block;
}
#offline-text.active {
  opacity: 1;
  bottom: 1rem;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}
.rbt {
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
}
.rbt-input-main {
  width: 220px;
  font-family: 'Nunito';
  font-size: 1rem;
  margin-top: 2rem;
}
@media (max-width: 576px){
  .nav_container {
    height: 50px; 
    width: 100%; 
    background-color: #f2f2f2; 
    margin-top: 20px;    
  }
}
.cursor-pointer {
  cursor: pointer;
}
.ComponentCard{
    min-height: 140px;	
    width: 100%;
    border-radius: 5px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding: 15px;
    padding-left: 22px;
    margin-top: 30px;
}
.ComponentHead{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 800;	
    line-height: 31px;
}
.EquipmentItem{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 10px;	
    line-height: 14px;
}
.EquipmentStatus{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 14px;	
    line-height: 19px;
    width: 100%;
}
.LastUpdatedDate{
    color: #A6A6A6;	
    font-family: 'Nunito';	
    font-size: 10px;	
    line-height: 14px;
}
.pencil{
    height: 10px;
    width: 10px;
    margin-bottom: 1px;
}
.pencilButton{
    background: none;
    border: none;
    outline: none !important;
}

#SystemStatus .modal-content{
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 305px !important;
}
.modalHeader{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 14px;	
    font-weight: 800;	
    line-height: 19px;
}
.modalDropdown{
    width: 200px;
    height: 30px;
    border : 0px;
    overflow:hidden;
    font-size: 13px;
    outline: none !important;
    font-family: 'Nunito';
    cursor: pointer;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    border-radius: 0px !important;
    border-bottom: 2px solid #4A90E2;
    background-color: white;
}

.sysred {
    height: 10px;
        width: 10px;
        border-radius: 7px;
        background-color: red;
        margin-top: 7px;
    }
    .sysgreen {
    height: 10px;
        width: 10px;
        border-radius: 7px;
        background-color: #79d140;
        margin-top: 7px;
    }
    .sysamber {
    height: 10px;
        width: 10px;
        border-radius: 7px;
        background-color: #ffbf00;
        margin-top: 7px;
    }
    .modalSubhead{
        color: #4A90E2;	
        font-family: 'Nunito';	
        font-size: 10px;	
        line-height: 14px;
        margin-top: 10px;
        font-weight: 800;
    }

    #SystemServies .modal-content {
        padding-left: 25px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 25px;
        margin-top: 50px;
        margin-bottom: 100px;        
      }
      

      #SystemServies .modal {
        padding-left: 0px !important;
      }

      .serviceEdit{
          border-bottom: 1px solid #D5D5D5;
          width: 90%;
      }

      #history {
        height: 0px;
        overflow: hidden;
      }

      #history.expand {
        height: auto;
      }

      .historyButton{
        color: #4A90E2;
        font-family: 'Nunito';	
        font-size: 14px;
    }


      @media (min-width: 768px){        
        .modalFirstHalf{
            width: 400px;
        }
        .rightBorder {
            border-right: 1px solid #979797;
        }
        
    }


    @media (max-width: 576px){
       
    #SystemServies .modal-content {
        padding-left: 25px;
        padding-top: 25px;
        padding-bottom: 45px;
        padding-right: 0px;
        margin-top: 25px;
        margin-bottom: 50px;
      }
        
    }

    #Service .react-datepicker-wrapper{
        width: 100%;
    }

    #Service .react-datepicker__input-container{
        width: 100%;
    }

    #Service .react-datepicker-wrapper{
        width: 100%;
    }
.sysName{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    line-height: 14px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.sysGreen{
    height: 10px;	
    width: 10px;	
    border-radius: 5px;
    background-color: #64BE00;
}
.sysRed{
    height: 10px;	
    width: 10px;	
    border-radius: 5px;
    background-color: red;
}
.sysAmber{
    height: 10px;	
    width: 10px;	
    border-radius: 5px;
    background-color: #ffbf00;
}
.sysStatus{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 14px;	
    line-height: 19px;
}

.sysBottom{
    color: #A6A6A6;	
    font-family: 'Nunito';	
    font-size: 10px;	
    line-height: 14px;
}

#system-health {
  font-family: 'Nunito';
  padding-bottom: 1rem;
}
#system-health .component-sidebar {
  position: absolute;
  top: calc(7.5rem + 6px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  display: -webkit-flex;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#system-health .component-sidebar.open {
  left: 0;
}
#system-health .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#system-health .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}
#system-health .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  cursor: pointer;
}
#system-health .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#system-health .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#system-health .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#system-health .system-header p span {
  text-decoration: underline;
}
#system-health .system-header .btn-pill {
  border-radius: 25rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #3b3b3b;
  border-color: #3b3b3b;
}
#system-health .system-header .btn-pill .icon {
  font-size: 75%;
}
#system-health .system-header .btn-pill:hover,
#system-health .system-header .btn-pill:active,
#system-health .system-header .btn-pill:focus {
  background-color: #3b3b3b;
  color: #ffffff;
}
#system-health .system-header .btn-icon {
  border-radius: 50%;
  padding: 0.375rem 0.625rem;
}
#system-health .system-body .card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
#system-health .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#system-health .system-body .systems {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#system-health .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#system-health .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#system-health .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#system-health .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#system-health .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#system-health .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#system-health .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#system-health .system-card .header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#system-health .system-card .header h5 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0;
}
#system-health .system-card .form-group {
  max-width: 350px;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  border-bottom: 1px solid #dcdcdc;
}
#system-health .system-card .form-group:last-child {
  border: 0;
}
#system-health .system-card p.system-status {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 0;
  position: relative;
}
#system-health .system-card p.system-status .helper-icon {
  font-size: 0.75rem;
  color: #a8a8a8;
}
#system-health .system-card p.system-status .helper {
  font-size: 0.75rem;
  color: #a8a8a8;
}
#system-health .system-card p.system-status .col-form-label {
  font-size: 0.875rem;
  padding: 0;
  color: #1a1818;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#system-health .system-card p.system-status .col-form-label select {
  border: 0 !important;
}
#system-health .system-card p.system-status .col-form-label select option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
}
#system-health .system-card p.system-status + input {
  padding: 0;
  border: 0;
  font-size: 0.875rem;
  color: #1a1818;
  border-bottom: 1px solid #1a1818;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
}
#system-health .system-card p.system-status + input:focus {
  border-color: #558dca;
}
#system-health .system-card .btn-outline-primary {
  border-color: #558dca;
  color: #558dca;
  font-size: 0.75rem;
}
#system-health .system-card .badge-indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #a8a8a8;
  border-radius: 0.125rem;
  display: inline-block;
  margin-right: 0.5rem;
}
#system-health .system-card .btn-outline-primary:active, #system-health .system-card .btn-outline-primary:hover, #system-health .system-card .btn-outline-primary.active {
  background-color: #558dca;
  color: #ffffff;
}
/* #system-health .system-card .collapse {
  max-height: 350px;
}
#system-health .system-card .collapse:not(.show) {
  max-height: 0;
} */
#system-health .history-box,
#readings .history-box {
  background-color: #ffffff;
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  -webkit-transform-origin: top;
          transform-origin: top;
  opacity: 0;
  min-height: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  transition: all 450ms ease-in-out;
}
#system-health .history-box.open,
#readings .history-box.open {
  -webkit-transform: rotateX(0);
          transform: rotateX(0);
  opacity: 1;
  min-height: 100%;
}
#system-health .history-block,
#readings .history-block {
  background-color: #f9f9f9;
  padding-bottom: 0.5rem;
  text-align: center;
}
#system-health .history-box .header,
#readings .history-box .header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#system-health .history-box .header h5,
#readings .history-box .header h5 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0;
}
#system-health .history-box .header .close,
#readings .history-box .header .close {
  margin-left: auto;
  float: right;
  color: #ff3939;
  font-size: 0.875rem;
  cursor: pointer;
}
#system-health .history-box .body,
#readings .history-box .body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 1.5rem 1.75rem;
  opacity: 0;
}
#system-health .history-box.open .body,
#readings .history-box.open .body {
  opacity: 1;
}
#system-health .history-box .body > *,
#readings .history-box .body > * {
  max-width: 400px;
  width: 100%;
}
.system-card .service-body {
  font-family: 'Nunito';
}
.system-card .service-body h6 {
  font-weight: bold;
}
.system-card .service-body h6 .icon {
  font-size: 0.375rem;
  line-height: 1.125rem;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  transition: all 300ms ease;
}
.system-card .service-body h6 .icon.open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.system-card .service-body .service-group {
  margin-bottom: 1rem;
}
.system-card .service-body .react-datepicker-wrapper, 
.system-card .service-body .react-datepicker__input-container {
  display: block;
}
.system-card .service-body .service-label {
  font-size: 0.6875rem;
  line-height: 1.36;
  color: #a8a8a8;
  margin: 0;
}
.system-card .service-body .service-data {
  font-size: 0.8125rem;
  line-height: 1.38;
  color: #1a1818;
  margin: 0;
}
.system-card .service-body .service-label + input {
  font-size: 0.8125rem;
  line-height: 1.38;
  border: 0;
  padding: 0;
  border-bottom: 1px solid #1a1818;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
  display: block;
}
.system-card .service-body .service-label + input:focus {
  border-color: #558dca;
}
.system-card .service-body .service-input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #979797;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 0.75rem;
  line-height: 1.333;
  padding: 0 0 0.25rem;
  color: #1a1818;
}
.system-card .service-body .service-input:focus {
  border-color: #558dca;
}
.system-card .service-body .service-input.error {
  border-color: #ff3939;
}
.system-card .service-body .service-input::-webkit-input-placeholder {
  color: #e7e7e7;
}
.system-card .service-body .service-input::-ms-input-placeholder {
  color: #e7e7e7;
}
.system-card .service-body .service-input::placeholder {
  color: #e7e7e7;
}
#system-health .btn-primary,
.system-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.system-card .service-body .helper {
  font-size: 0.7rem;
  text-align: center;
  display: inline-block;
  margin-top: 1rem;
  cursor: pointer;
}
.system-card .service-body .interval-history {
  font-size: 0.75rem;
  color: #1a1818;
  margin-bottom: 0.25rem;
  display: -webkit-flex;
  display: flex;
}
.system-card .service-body .interval-history span {
  color: #a8a8a8;
  white-space: nowrap;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.system-card .service-body .service-link {
  text-decoration: underline;
  color: #558dca;
  font-size: 0.75rem;
  line-height: 1.583;
  cursor: pointer;
}

.timeline {
  padding-left: 0;
  margin-left: 50%;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: #a8a8a8;
  list-style: none;
  width: 150px !important;
  position: relative;
  border-left: 1px solid #3b3b3b;
}
.timeline li {
  padding: 0.5rem 1rem;
  position: relative;
  margin-left: -1px;
  border-left: 1px solid #3b3b3b;
  text-align: left;
}
.timeline li:last-child {
  padding-bottom: 0;
}
.timeline li::before,
.timeline.extra li .timeline-left {
  position: absolute;
  right: calc(100% + 1rem);
  content: attr(data-date);
  font-size: 0.75rem;
  line-height: 0.875rem;
  white-space: nowrap;
  color: #a8a8a8;
}
.timeline.extra li .timeline-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: right;
}
.timeline.extra li::before {
  content: unset;
}
.timeline li::after {
  position: absolute;
  top: 0.65rem;
  right: calc(100% + 1px);
  content: '';
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent; 
  border-right: 0.25rem solid #3b3b3b;
}
.timeline.new {
 padding-bottom: 1rem;
} 
.timeline.new li:first-child {
  padding-top: 0;
}
.timeline.new li:first-child::after {
  top: 0.2rem;
}
.system-sliding-pane  .slide-pane__content {
  padding: 1.5rem 0;
}
.system-sliding-pane h5 {
  font-family: 'Nunito';
  font-size: 1.125rem;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 2rem 1.5rem;
}
.system-sliding-pane h5 .close {
  margin-left: auto;
  float: right;
  color: #ff3939;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.system-sliding-pane .system-card {
  padding: 0rem 2rem 1.5rem;
}
.system-sliding-pane .system-card h6 {
  font-size: 0.8125rem;
}
.system-sliding-pane .system-card h6 .badge {
  width: 0.75rem;
  height: 0.75rem;
  margin-bottom: -0.125rem;
  display: inline-block;
}
.system-sliding-pane .system-card .service-body .collapsing {
  width: 100%;
}
hr {
  border-top: 0;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 769px) {
  #system-health {
    padding: 2.5rem 2rem 1rem;
  }
  #system-health .system-header h4 {
    line-height: 2rem;
  }
  #system-health .system-body {
    padding-top: 1rem;
  }
  .slide-pane {
    width: 400px !important;
  }
}
@media (max-width: 768px) {
  #system-health .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999;
  }
  #system-health .system-header h4 {
    font-size: 1.25rem;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #system-health .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #system-health .system-header p span {
    text-decoration: none;
  }
  #system-health .system-header .subtitle {
    color: #a2a2a2;
    font-size: 0.625rem;
  }
  #system-health .system-header .btn-icon {
    font-size: 0.875rem;
    line-height: 1;
    padding: 5px 6px;
  }
  #system-health .system-header .btn-outline-secondary {
    border-color: #ffffff;
    color: #ffffff;
    font-size: 0.625rem;
    padding: 0.25rem 0.75rem;
  }
  #system-health .system-header .btn-outline-secondary:hover,
  #system-health .system-header .btn-outline-secondary:active,
  #system-health .system-header .btn-outline-secondary:focus {
    background-color: #ffffff;
    color: #3b3b3b;
  }
  #system-health .system-body {
    margin-top: 10.5rem;    
    margin-right: 0;
    margin-left: 0;
  }
  #system-health .system-body .system-names {
    background-color: #3b3b3b;    
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 1rem + 53px - 0.625rem);
    z-index: 2;
  }
  #system-health .system-body .system-names .list-group {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #system-health .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #system-health .system-body .system-names .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #system-health .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  .slide-pane {
    width: 100% !important;
  }
}

.bulk-audit-action{
  margin-top: 20px;
  z-index: 1;
  width: 275px;
}

.btn-bulk-update{
  margin: 10px auto;
  display: block;
}
#device-info .device {
  position: relative;
  padding: 1.5rem 1rem 1rem;
  background-color: #f2f8ff;
  border-radius: 0.25rem;
  margin-top: 1rem;
}
#device-info .info-group {
  font-size: 0.875rem;
  color: #1a1818;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#device-info .helper, 
#device-info .helper-icon {
  font-size: .75rem;
  color: #a8a8a8;
  display: inline;
  text-overflow: ellipsis;
}
#device-info input {
  padding: 0;
  border: 0;
  font-size: .875rem;
  color: #1a1818;
  border-bottom: 1px solid #ababab;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
  background-color: transparent;
  width: 100%;
}
#device-info input:focus {
  border-color: #92beef;
}
#device-info .edit-button {
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
#contact-info .contacts-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.5fr));
  grid-gap: 1.5rem;
}
#contact-info .contacts-wrapper .contact-card {
  transition: all .3s ease;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  min-height: 1px;
}
#contact-info .contacts-wrapper .contact-card .info-group {
  font-size: 0.875rem;
  color: #1a1818;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#contact-info .contact-card .helper, 
#contact-info .contact-card .helper-icon {
  font-size: .75rem;
  color: #a8a8a8;
  margin-top: 0;
}
#contact-info .contact-card input {
  padding: 0;
  border: 0;
  font-size: .875rem;
  color: #1a1818;
  border-bottom: 1px solid #1a1818;
  margin-bottom: -1px;
  outline: 0 !important;
  box-shadow: none !important;
}
#contact-info .contact-card input:focus {
  border-color: #92beef;
}

#contact-info .btn-outline-primary {
  border-color: #558dca;
  color: #558dca;
  font-size: 0.75rem;
}
#contact-info .edit-button {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media (max-width: 768px) {
  #contact-info .contacts-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
.spinLabel{
    height: 10px;
    width: 10px;
    border: 2px solid currentColor;
    border-right-color: transparent;
    margin-top: 5px;
    margin-left: 7px;
}

#adhocTask{
    font-family: 'Nunito';
}

.activeAdhocButton{
    color: #3d3d3d;
    font-size: 23px;	
    font-weight: bold;	
    line-height: 31px;
    margin: 0;
}
.adhocButton{
    color: #666666;
    font-size: 23px;	
    font-weight: 300;	
    line-height: 31px;
}
.addTaskButton{
    color: #ffffff;
    font-size: 13px;	
    font-weight: bold;
    line-height: 19px;
}
.adhocCard{
    /* width: 260px;
    height: 175px; */
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    border-radius: 4px;
    background-color: #FFFFFF;	
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    padding : 10px;
    padding-left: 23px;
    padding-right: 23px;
    /* margin: 25px 17px; */
    cursor: pointer;
    border: 0px;
    outline: none !important;
}
.oldCard{
    height: 200px;	
    width: 300px;	
    border-radius: 5px;	
    background-color: #E8E8E8;	
    padding : 10px;
    padding-left: 23px;
    padding-right: 23px;
    margin: 25px;
    cursor: pointer;
    border: 0px;
    outline: none !important;
}

.adhocTaskTitle {
    color: #1a1818;
    font-size: 1.125rem;	
    font-weight: bold;	
}
.adhocTaskType{
    color: #9d9d9d;	
    font-size: 0.9375rem;	
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: 5px;
}

.adhocTaskDescription{
    padding-top: 8px;
    color: #3B3B3B;	
    font-size: 0.875rem;
}

.adhocTaskDate{
    color: #3B3B3B;	
    font-size: 11px;	
    min-width: 100px;
    /* padding-right: 30px; */
}
.adhocCurrentStatus{
    border-radius: 5px;	
    font-size: 0.75rem;	
    font-weight: bold;
    white-space: nowrap;
}

.modalSubHeadings{
    color: #3B3B3B;
    font-size: 10px;	
    line-height: 14px;
    margin-top: 15px;
}
.modalHeading{
    color: #3B3B3B;
    font-size: 14px;	
    font-weight: 800;	
    line-height: 19px;
}

.slide-pane{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.slide-pane__overlay{
    z-index: 1030;
}

.slide-pane__overlay.ReactModal__Overlay--after-open{
    background-color: transparent;
}

.slide-pane__header{
    display: none;
}

#adhocTask .close{
    background-color: white;
    border: 0;
}

.addTaskHeading{
    font-size: 18px;
    font-weight: bold;
    color: #1a1818;
}

.addTaskTitle{
    margin-top: 30px !important;
}

.addTaskSubHeadings{
    font-size: 12px;
    color: #a8a8a8;
}

.addTaskInputs{
    border: 0px;
    border-bottom: 1px solid #D5D5D5;
    width: 325px;
    outline: none;
    font-size: 14px;
    font-weight: normal;
    color: #1a1818;
}

.addTaskCalendarDatePicker{
    font-size: 12px;
    width: 325px;
    color: #3B3B3B;
    border: 0px;
    border-bottom: 1px solid #D5D5D5;
    outline: none;
}

.uploadFile{
    font-size: 0.75rem;
    color: #1a1818;
    height: 2.25rem;
    padding: 0px 14px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #d0efdb;
}

.addFile{
    width: 18px;
    height: 17px;
    background-color: #96ccaa;
}

.addFileDiv{
    width: 2.3125rem;
    height: 2.25rem;
    background-color: #96ccaa;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.cancel{
    width: 80px;
    height: 32px;
    border-radius: 3px;
    border: solid 1px #979797;
    background-color: #ffffff;
    font-size: 12px;
    color: #979797;
}

.saveDraft{
    background-color: transparent;
    text-decoration: underline;
    font-size: 12px;
    color: #558dca;
    font-weight: bold;
}

.save{
    background-color: #558dca;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    width: 80px;
    height: 32px;
}

.modalDropdown{
    width: 218px;
    border-bottom: 2px solid #D5D5D5;
    background-color: white;
}

#Adhoc .modal-content {
    padding-left: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 50px;
    margin-top: 100px;
}

.adhocInputs{
    border: 0px;
    border-bottom: 2px solid #D5D5D5;
    outline: none;
}
.CalendarDatePicker{
    font-size: 12px;
    width: 100px;
    color: #3B3B3B;
    border: 0px;
    border-bottom: 2px solid #D5D5D5;
    outline: none;
}

.AdhocButton {
    box-shadow: '0 0 10px 0 rgba(0,0,0,0.23)';
    color: #FFFFFF;
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;	
    text-align: center;
    margin-bottom: 15px;
    padding: 3px;
    border: 1px solid #4A90E2;
    border-radius: 4px;
    outline: none;
}

input[type="file"] {
    /* display: none; */
    opacity: 1;
    background-color: 'pink';
    width: 270px;
    height: 25px;
    opacity: 0;
    cursor: pointer;
    margin-left: -110px;
}
.uploadFileLabel{
    color: #4A90E2;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;
    margin-left: 7px;
    pointer-events: none;
}

.cabinet{
    overflow: hidden;
    /* margin-bottom: 50px; */
}

.addImage {
    height: 20px; 
    width: 20px;
    pointer-events: none;
}

.fileItems{
    color: #3B3B3B;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Adhoc .modalDropdown{
    width: 218px;
    border-bottom: 2px solid #D5D5D5;
    background-color: white;
}

.adhocDropdown{
    width: 34px;
    height: 34px;
    border : 0px;
    overflow:hidden;
    font-size: 13px;
    outline: none !important;
    cursor: pointer;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    background-color: transparent;
    border-radius: 3px;
    padding-left: 10px;
    position: relative;
    color: rgb(73, 69, 69);
    opacity: 0;
}
.adhocTaskCard{
    border-radius: 5px;
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    margin: 40px 15px 15px;
    border: 0px;
    outline: none !important;
}

.taskDetails{
    padding: 0 25px;
    font-family: 'Nunito';
}

.adhocTaskDetailCard{
    border-radius: 4px;
    background-color: #FFFFFF;	
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    margin: 40px 15px 15px;
    border: 0px;
    outline: none !important;
}

.rowDiv{
    display: -webkit-flex;
    display: flex;
}

.column1{
    width: 50%;
    padding: 18px 24px;
}

.column2{
    width: 50%;
    padding: 33px 150px 33px 35px;
    border-left: solid 1px #e0e0e0;
}

.heading{
    font-size: 18px;
    font-weight: bold;
    color: #1a1818;
}

.back-close{
    height: 16px;
    width: 16px;
}

#adhocTask .col-6{
    padding: 15px 35px;
}

.AdTaskTitle{
    color: #3B3B3B;
    font-size: 12px;	
    line-height: 14px;
}

.AdTaskAttachments{
    font-size: 14px;
    font-weight: bold;
    color: #1a1818;
}

.AdTaskTime{
    font-size: 12px;
    color: #9d9d9d;
}

.commentTitle{
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #1a1818;
}

.adhocTextarea{
    width: 100%;
    height: 76px;
    border: 1px solid #A1A1A1;	
    border-radius: 3px;
    resize: none;
    color: rgb(123, 123, 123);	
    font-size: 13px;	
    line-height: 14px;
    padding: 10px;
    outline: none !important;
    padding-right: 96px;
}
.submitButton {
    height: 28px;	
    width: 85px;	
    border-radius: 3px;	
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.23);
    border: 0px;
    color: #FFFFFF;	
    font-size: 14px;	
    font-weight: bold;	
    line-height: 19px;	
    text-align: center;
    outline: none !important;
}

.AdTaskCommonStyle{
    color: #3B3B3B;
    font-size: 16px;	
    line-height: 19px;
    white-space: pre-line;
}

.adTaskButtons{
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.23);
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.attachedFilesLabel{
    color: #4A90E2;	
    font-size: 14px;	
    line-height: 19px;
    -webkit-text-decoration: underline #4A90E2;
            text-decoration: underline #4A90E2
}

.noTasksDiv{
    height: 55vh;
    margin-top: 12px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    margin-left: 20px;
    margin-right: 50px;
}

.noTasks{
    font-size: 21px;
    font-weight: bold;
    color: #3b3b3b;
    margin-top: 17px;
}

.noTaskDesc{
    margin-top: -5px;
    font-size: 13px;
    color: #a2a2a2; 
}

.comments{
    min-height: 65px;
    margin-bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.commentedBy{
    font-size: 12px;
    color: #558dca;
}

.commentHeading{
    color: #a8a8a8;
    font-size: 10px;	
    line-height: normal;
}
.commentSubeading{
    color: #3B3B3B;	
    font-size: 16px;	
    line-height: 19px;
}

.morecomment-btn{
    font-size: 12px;
    color: rgb(0, 124, 201);
    text-decoration: underline;
}

#adhocTask #dashboard-nav {
    /* padding: 1rem 1.25rem;
    background-color: #3b3b3b; */
    height: 70px;
    margin-top: -0.3125rem !important;
    padding-top: 20px !important;
    /* border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff; */
}

#dashboard-nav h2 {
    font-size: 1.75rem;
    margin-top: 0.25rem;
}

#taskList .list-group .list-group-item{
    border: 0;
    border-radius: 0;
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    background-color: transparent;
}
#taskList .list-group .list-group-item + .list-group-item {
    border-top: 1px solid rgb(220, 220, 220);
}
#taskList .list-group .list-group-item::after{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 0 solid transparent;
    transition: all 300ms ease;
}
#taskList .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
    border-color: #333232;
}
#taskList .list-group .list-group-item:hover {
    font-weight: bold;
}
#taskList .list-group-item.active {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: bold;
    border-top-color: #2c2c2c;
}
#taskList .list-group .list-group-item.active::after {
    right: -6px;
    border-left: 6px solid #2c2c2c;
}
#taskList .list-group .list-group-item.active:hover{
    color: #ffffff;
}

#taskList .listCards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px,0.5fr));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding-right: 57px;
}
#taskList .card-main-icon {
    font-size: 42px;
    color: #558dca;
}

#taskList .card-header {
    font-weight: bold;
    background: #ffffff;;
}

#taskList .accordion table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    /* border-top: 1px solid #e5e5e5; */
    /* background-color: #fafafa ; */
}

#taskList .accordion table tr {
    background-color: #fafafa;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.08);
    border-radius: 0.25rem;
}

#taskList .accordion button.btn-outline-primary {
    white-space: nowrap;
    font-size: 0.75rem;
}

#taskList .card-header .headerCount {
    color: rgba(0, 0, 0, 0.3);
}

#taskList .card-header .icon-chevron-down {
    margin-right: 0.5rem;
    font-size: 0.5rem;
    color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    -webkit-transform-origin: center;
            transform-origin: center;
}
#taskList .card-header .icon-chevron-down.open {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

#taskList .accordion .card-body .taskTitle {
    font-weight: 500;
}

#adhocTask .component-sidebar {
    position: absolute;
    top: calc(5rem + 15px);
    left: -100%;
    width: 80%;
    transition: all 300ms ease-in-out;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    background-color: #3b3b3b;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.35);
}
#adhocTask .component-sidebar.open {
    left: 0;
    z-index: 2;
}
#adhocTask .component-sidebar .closeMenu {
    font-size: 0.75rem;
    color: #ffffff;
    margin-top: 1rem;
    margin-right: 0.5rem;
}
#adhocTask .component-sidebar .list-group {
    border-radius: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    -webkit-flex: 1 1;
            flex: 1 1;
}
#adhocTask .component-sidebar .list-group .list-group-item {
    background-color: #3b3b3b;
    border: 0;
    border-bottom: 1px solid;
    border-color: #777777 !important;
    border-radius: 0;
    margin-top: 1px;
    padding-left: 0.75rem;
}
#adhocTask .component-sidebar .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
}
#adhocTask .component-sidebar .list-group .list-group-item:hover {
    font-weight: bold;
}
#adhocTask .listCards .hourglass {
    margin-right: 8px;
    height: 13px;
}
#adhocTask .listCards .lastdate {
    font-size: 12px;
    color: #9d9d9d;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    white-space: nowrap;
}
.font-sm {
    font-size: 90%;
}

/* @media (max-width: 900px) {
    #taskList{
        flex-direction: column;
    }
    #taskList .listCards{
        margin-top: 15px !important;
    }
} */

@media only screen and (max-width: 576px) {
    .activeAdhocButton{
        font-size: 13px;	
    }
    .adhocButton{
        font-size: 13px;	
    }
    #Adhoc .modal-content {
        margin-top: 20px
    }
    .listCards{
        -webkit-justify-content: center;
                justify-content: center;
    }

    .listCards.grouped-cards {
        grid-template-columns: repeat(auto-fit, minmax(140px,1fr)) !important;
    }
    .listCards.grouped-cards .tasktype {
        font-size: 0.75rem !important;
        margin-right: 8px;
    }
    .listCards.grouped-cards .card-main-icon {
        font-size: 20px !important;
    }
    .listCards.grouped-cards .adhocTaskTitle {
        font-size: 0.875rem !important;
        margin-top: auto;
        margin-bottom: 0 !important;
    }
    .listCards.grouped-cards .adhocCard {
        min-height: 70px !important;
        padding: 16px 20px !important;
    }
    .listCards.grouped-cards .adhocCard .details {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 100%;
    }
    .listCards.grouped-cards .lastdate {
        font-size: 10px !important;
    }
    .listCards.grouped-cards .hourglass {
        height: 11px !important;
    }
}
@media only screen and (max-width: 1000px) {
    .rowDiv{
        display: block;
    }

    .column2{
        width: 100%;
        padding-right: 33px;
    }

    .column1{
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    #adhocTask{
        margin: 0 !important;
    }
    #taskList .listCards{
        -webkit-justify-content: center;
                justify-content: center;
        margin: 10px 10px 20px 10px;
        margin-left: 0 !important;
        padding-right: 15px;
        grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
    }
    .adhocCard{
        width: 100%;
        /* height: 13.0625rem; */
        padding: 22px 24px 24px !important;
        margin-bottom: 0;
    }
    .adhocTaskTitle{
        padding-top: 7px;
    }
    .adhocTaskType{
        padding-top: 5px;
    }
    .adhocTaskDescription{
        padding-top: 8px;
    }
    .adhocTaskDate{
        padding-top: 12px;
    }
    .adhocDiv{
        font-size: 1.25rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.25;
    }
    .activeAdhocDiv{
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
    }
    .some-custom-class{
        width: 100% !important;
    }
    .addTaskInputs, .addTaskCalendarDatePicker, .react-datepicker-wrapper, .react-datepicker__input-container{
        width: 100% !important;
    }
    #Adhoc{
        width: 100% !important;
    }
    .addTaskTitle{
        margin-top: 50px !important;
    }

    /* TaskDetail */
    .taskDetails{
        padding: 0;
        z-index: 999;
    }

    .adhocTaskDetailCard{
        width: 100% !important;
        border-radius: 0;
        margin: 0;
        min-height: 100vh;
    }

    .rowDiv{
        display: block;
    }

    .column1{
        width: 100%;
    }
    
    .column2{
        width: 100%;
        padding: 14px 24px 30px 25px !important;
        border-top: solid 1px #e0e0e0;
        border-left: 0;
    }

    .taskDetailHeading{
        border: 0 !important;
    }

    .timeDiv{
        margin-top: 11px;
    }

    .noTasksDiv{
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 50px;
    }

    #taskList .accordion table, #taskList .accordion tbody, #taskList .accordion td {
        display: block;
    }

    #taskList .accordion tr {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin-bottom: 0.75rem;
        padding: 1.25rem 1rem 0.25rem;
    }
    #taskList .accordion td {
        -webkit-order: 1;
                order: 1;
        padding: 0;
        margin-bottom: 1rem;
    }
    #taskList .accordion td:nth-child(3) {
        -webkit-order: 0;
                order: 0;
    }
}
#attendance .example-custom-input{
    font-size: 14px !important;
    font-weight: bold;
    color: #3b3b3b;
    width: 115px;
    text-align: left;
}

#attendance .react-datepicker-wrapper{
    width: 115px;
}

#attendance .react-datepicker__triangle{
    left: 111px !important;
}

#attendance .react-datepicker{
    left: -100px;
}

#attendance .calendar{
    margin-right: 20px;
    margin-left: -20px;
}

#attendance .search-icon{
    height: 20px !important;
    width: 20px !important;
    margin-top: 10px !important;
    margin-right: 5px;
}

#attendance .filter-input-active{
    margin-right: 10px;
}

#attendance .attendanceHeading{
    font-size: 22px;
    font-weight: bold;
    color: #3b3b3b;
    padding-left: 3px;
}

#monthAttendance .rm-button-style{
    display: none;
}

#monthAttendance .react-datepicker-wrapper{
    width: 90px;
}

#monthAttendance .custom-date-picker{
    width: 120px !important;
}

.attendanceWeb .attendanceList{
    margin-top: 10px;
    margin-right: 15px;
}

.StaffActivitiesCard{
    margin: 15px;
    margin-top: 0px;
    border-radius: 5px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 26px;
    border: 0px;
    outline: none !important;
}
.StaffHeading{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 800;	
    line-height: 31px;
    text-align: left;
    margin-left: 20px;
}

.StaffCategory{
    color: #3B3B3B;	
    font-size: 10px;	
    line-height: 14px;
}

.StaffActive{
    font-size: 25px;	
    line-height: 31px;
}

.StaffPassive{
    color: #3B3B3B;	
    font-size: 17px;	
    line-height: 19px;
}
.StaffMapContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    float: left;
}

.StaffSingleItem{
    min-width: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 15px;
}

.StaffMapContainerTasks{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    float: left;
    width: 100%
}

.cal-button-img{
    height: 25px;
    width: 25px;
    opacity: 0.7;
}

.rm-button-style{
    background-color: transparent;
    border: 0px;
    outline: none !important;
}
.font-style{
    font-family: 'Nunito';
    color: #3B3B3B;
}
.active-tab{
    font-weight: bold;
    font-size: 20px;
    border: 3px;
    color: #4A90E2
}
.inactive-tab{
    font-size: 20px;
    font-weight: bold;
    width: 100px;
}
.alt-text {
    min-height: 40px; 
    min-width: 40px; 
    border-radius: 5px; 
    margin-right: 10px; 
    color: white;
    font-size: 19px;
    background-color: #558dca;
    font-weight: 700;
}

.filter-input{
    outline: none !important;
    border: 0;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding-left: 10px;
    background-color: transparent;
    width: 30px;
    transition: 0.4s ease all;
    height: 26px;
    cursor: pointer;
    opacity: 0;
    padding-right: 5px;
}
.filter-input-active{
    outline: none !important;
    border: 0;
    border-bottom: 1px solid #558dca;
    padding-left: 10px;
    background-color: transparent;
    font-size: 0.875rem;
    width: 300px;
    opacity: 1;
    transition: 0.4s ease all;
    height: 26px;
}


.example-custom-input{
    background-color: transparent;
    border: 0px;
    outline: none !important;
    font-size: 20px;
    font-family: 'Nunito'
}
.example-custom-input-small{
    width: 100%;
    height: 20px;
    background-color: transparent;
    border: 0px;
    outline: none !important;
    font-size: 14px;
    font-family: 'Nunito';
    text-align: start;
    margin-right: 45px !important;
}

.employee-name{
    font-size: 14px;
    font-weight: normal;
    color: #3b3b3b;
}
.sort-icon-active{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0.8;
    transition: 0.4s ease all;
}

.sort-icon{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    transition: 0.4s ease all;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-top: 2px;
    opacity: 0.8;
}
.sort-icon-inetial{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    transition: 0.4s ease all;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    margin-top: 2px;
    opacity: 0.8;
}
.status-indictor{
    color: white;
    width: 70%;
    border-radius: 4px;
    font-weight: 600;
    opacity: 0.7;
    }

.search-icon{
    opacity: 1;
    margin-top: 3px;
    margin-left: -24px;
    pointer-events: none;
    transition: 0.4s ease all;
}

.search-icon-active{
    opacity: 0;
    margin-top: 3px;
    margin-left: -24px;
    transition: 0.4s ease all;
}

.dja {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.da {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.dj {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.summary-card{
    min-width: 150px; 
    height: 100px; 
    -webkit-flex-direction: column; 
            flex-direction: column;
    border-radius: 5px; 
    box-shadow: 0 2px 5px 3px rgba(0,0,0,0.05); 
    background-color: 'white';
    font-size: 30px;
}
.indiactor{
    height: 9px;
    width: 9px;
    border-radius: 9px;
}

.mob-month-values{
    font-size: 9px;
    font-family: 'Nunito';
    color: #3B3B3B;
    min-width: 60px;
}

.DetailComponentCard{
    width: 100%;
    border-radius: 5px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding: 15px;
    padding-left: 22px;
    margin-top: 30px;
}

.taskDetailTaskName {
    font-family: Nunito;	
    font-size: 14px;	
    line-height: 19px;
}

.circleCheckBox{
    min-width: 20px !important;
    min-height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    border-radius: 10px;
    background-color: transparent;
    outline: none !important;
}
.greenTick{
    height: 24px;
    width: 24px;
}
.taskDetailTaskNameSub{
    color: #9B9B9B;	
    font-family: Nunito;	
    font-size: 10px;	
    line-height: 14px;
}
.attendanceWeb .list-group .list-group-item{
    border: 0;
    border-radius: 0;
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    background-color: transparent;
}
.attendanceWeb .list-group .list-group-item::after{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 0 solid transparent;
    transition: all 300ms ease;
}
.attendanceWeb .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
    border-color: #333232;
}
.attendanceWeb .list-group .list-group-item:hover {
    font-weight: bold;
}
.attendanceWeb .list-group-item.active {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: bold;
    border-top-color: #2c2c2c;
}
.attendanceWeb .list-group .list-group-item.active::after {
    right: -6px;
    border-left: 6px solid #2c2c2c;
}
.attendanceWeb .list-group .list-group-item.active:hover{
    color: #ffffff;
}


#attendanceDiv .component-sidebar {
    position: absolute;
    top: calc(5rem + 8px);
    left: -100%;
    width: 80%;
    transition: all 300ms ease-in-out;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    background-color: #3b3b3b;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.35);
}
#attendanceDiv .component-sidebar.open {
    left: 0;
}
#attendanceDiv .component-sidebar .close {
    font-size: 0.75rem;
    color: #ffffff;
    margin-top: 1rem;
    margin-right: 0.5rem;
}
#attendanceDiv .component-sidebar .list-group {
    border-radius: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    -webkit-flex: 1 1;
            flex: 1 1;
}
#attendanceDiv .component-sidebar .list-group .list-group-item {
    background-color: #3b3b3b;
    border: 0;
    border-bottom: 1px solid;
    border-color: #777777 !important;
    border-radius: 0;
    margin-top: 1px;
    padding-left: 0.75rem;
}
#attendanceDiv .component-sidebar .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
}
#attendanceDiv .component-sidebar .list-group .list-group-item:hover {
    font-weight: bold;
}
  @media (max-width: 1000px) {
    .attendanceWeb{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .attendanceWeb .attendanceList{
        margin-left: 15px;
        margin-top: 15px;
    }
  }

  @media (max-width: 576px) {
    .StaffHeading{
        font-size: 18px;	
    }
    .StaffSingleItem{
        min-width: 0px;
        margin-top: 10px;
    }
    .StaffCategory{
        font-size: 10px;	
    }
    .summary-card{
        min-width: 90px; 
        height: 90px; 
        font-size: 20px;
    }
    .filter-input-active{
        width: 80vw;
    }
}

@media (max-width: 768px) {
    .mobileHeading{
        font-size: 13px;
        width: 191px;
        color: #b7b7b7;
    }
    .mobileHeading .staff{
        font-size: 0.875rem;
    }
    .mobileHeading .Attendance{
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: -5px;
        color: #ffffff;
    }
    #dailyAttendance .example-custom-input{
        font-size: 15px !important;
        font-weight: normal;
        color: #ffffff;
        text-align: right;
        margin-top: 1px;
        width: 132px;
        padding-right: 35px;
    }
    .mobileAttendance .react-datepicker-wrapper{
        width: 115px !important;
    }
    .mobileAttendance .react-datepicker{
        left: -130px !important;
    }
    .mobileAttendance .react-datepicker__triangle{
        left: 175px !important;
    }
    .mobileAttendance .calendar{
        margin-right: 12px;
        margin-left: -10px;
        font-size: 22px;
        margin-top: 1px;
        color: #999999;
    }
    .attendanceWeb{
        margin-top: 175px;
    }
    .attendanceWeb .col-11{
        padding-right: 0;
    }
    .attendanceWeb .attendanceList{
        border-radius: 4px;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
        margin-top: 0px;
    }
    .attendanceWeb .attendanceCard{
        height: 135px !important;
        margin-left: 10px;
        margin-right: 10px;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        padding-top: 20px;
    }
    .attendanceWeb .attendanceName{
        padding-left: 5px !important;
        width: 100%;
    }
    .attendanceWeb .attendanceName img{
        height: 50px;
        max-width: none !important;
        width: 50px;
        max-height: none !important;
        border-radius: 25px !important;
    }
    .attendanceWeb .dja{
        border-radius: 25px !important;
        height: 50px;
        width: 50px;
    }
    .attendanceWeb .attendanceDetails{
        margin-left: -5px;
        margin-right: -5px;
    }
    .attendanceWeb .attendanceDetails .detailHead{
        width: 100%;
        padding: 0px 12px;
        margin-top: 10px;
        font-size: 13px;
        font-weight: normal;
        color: #b7b7b7;
    }
    .attendanceWeb .attendanceDetails .detailValue{
        font-size: 12px;
        color: #3b3b3b;
    }
    .attendanceCard .monthDetails{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    #editTask .react-datepicker{
        left: -100px;
    }
    #editTask .react-datepicker__triangle{
        left: 135px;
    }
    .taskDetailHeading .editButton{
        padding-top: 10px;
    }
    .mobileAttendance #dashboard-nav{
        height: 115px;
        margin-top: -0.9375rem !important;
        padding-top: 5px !important;
    }
    #monthAttendance .example-custom-input{
        font-size: 15px !important;
        font-weight: normal;
        color: #ffffff;
        text-align: right;
        margin-top: 1px;
        width: 120px;
        padding-right: 40px;
    }
}
#staff-attendance {
  font-family: 'Nunito';
  padding-bottom: 1rem;
}
#staff-attendance .component-sidebar {
  position: absolute;
  top: calc(6rem + 3px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  display: -webkit-flex;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#staff-attendance .component-sidebar.open {
  left: 0;
}
#staff-attendance .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#staff-attendance .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}
#staff-attendance .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  cursor: pointer;
}
#staff-attendance .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#staff-attendance .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#staff-attendance .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#staff-attendance .system-header p span {
  text-decoration: underline;
}
#staff-attendance .system-header .filter-button {
  height: 26px;
}
#staff-attendance .system-header .filter-dropdown {
  width: 26px;
  height: 26px;
  margin-left: -26px;
  opacity: 0;
  font-size: 0.75rem;
}
#staff-attendance .system-header .filter-dropdown::before {
  content: '\E927';
  font-family: 'icomoon';
  font-size: 24px;
  height: 24px;
}
#staff-attendance .example-custom-input {
  font-size: 0.875rem !important;
  font-weight: 700;
  color: #3b3b3b;
  width: 120px;
  text-align: center;
  padding-right: 28px;
  margin-top: 3px;
}
#staff-attendance .system-header .react-datepicker-wrapper {
  font-size: 0.875rem;
  width: 120px;
}
#staff-attendance .react-datepicker__triangle {
  left: calc(100% - 50px);
}
#staff-attendance .system-body .card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
#staff-attendance .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#staff-attendance .system-body .systems {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#staff-attendance .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#staff-attendance .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#staff-attendance .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#staff-attendance .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#staff-attendance .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#staff-attendance .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#staff-attendance .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#staff-attendance .system-card .header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
#staff-attendance .system-card .header h5 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0;
}
#staff-attendance .system-card .tab-headers {
  display: -webkit-flex;
  display: flex;
}
#staff-attendance .system-card .tab-headers button {
  border: 0;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: #ffffff;
  text-align: left;
  font-weight: 400;
  font-size: 1.25rem;
  padding: 1rem 1.5rem calc(1rem - 0.375rem);
  border-bottom: 0.375rem solid #eaeaea;
  letter-spacing: 0.5px;
}
#staff-attendance .system-card .tab-headers button.active {
  font-weight: 700;
}
#staff-attendance .table {
  font-size: 0.875rem;
  line-height: 1.2;
  table-layout: fixed;
  margin-bottom: 0;
}
#staff-attendance .table th {
  border: 0;
  color: #b7b7b7;
  padding: 1rem 0;
}
#staff-attendance .table td {
  vertical-align: middle;
  padding: 1rem 0;
  color: #3b3b3b;
}
#staff-attendance .helper {
  font-size: 0.75rem;
  color: #b7b7b7;
  margin-top: 0.25rem;
  padding-left: 0.75rem;
}
#staff-attendance .badge-indicator {
  width: 0.625rem;
  height: 0.625rem;
  background-color: #a8a8a8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.25rem;
}
#staff-attendance .user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  margin-right: 0.625rem;
  font-weight: 700;
  font-size: 1rem;
  background:  linear-gradient(135deg, #558dca 0%, #558dca 48%, #67a1df 53%, #67a1df 100%);
}

#staff-attendance .accordion {
  width: 100%;
}
#staff-attendance .accordion .card {
  border: 0
}
#staff-attendance .accordion .card-header,
#staff-attendance .accordion .card-body {
  padding: 0;
}
#staff-attendance .accordion .card-header {
  background-color: #ffffff;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eaeaea;
}

#staff-attendance .accordion .card-header .icon-chevron-down {
  font-size: 0.5rem !important;
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.3);
  transition: -webkit-transform 400ms ease;
  transition: transform 400ms ease;
  transition: transform 400ms ease, -webkit-transform 400ms ease;
  display: inline-block;
}
#staff-attendance .accordion .card-header .icon-chevron-down.open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media (min-width: 769px) {
  #staff-attendance {
    padding: 2.5rem 2rem 1rem;
  }
  #staff-attendance .system-header h4 {
    line-height: 2rem;
  }
  #staff-attendance .system-body {
    padding-top: 1rem;
  }  
}
@media (max-width: 768px) {
  #staff-attendance .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999;
  }
  #staff-attendance .system-header .filter-button {
    background-color: transparent;
    color: #ffffff;
    border-radius: 50%;
    font-size: 60%;
    height: 20px;
    margin-top: 4px;
  }
  #staff-attendance .example-custom-input {
    color: #ffffff;
    margin-top: 2px;
  }
  #staff-attendance .system-header h4 {
    font-size: 1.25rem;
    line-height: 1;
  }
  #staff-attendance .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #staff-attendance .system-header p span {
    text-decoration: none;
  }
  #staff-attendance .system-header .subtitle {
    color: #a2a2a2;
    font-size: 0.625rem;
  }
  #staff-attendance .system-header .btn-icon {
    font-size: 0.875rem;
    line-height: 1;
    padding: 5px 6px;
  }
  #staff-attendance .system-header .btn-outline-secondary {
    border-color: #ffffff;
    color: #ffffff;
    font-size: 0.625rem;
    padding: 0.25rem 0.75rem;
  }
  #staff-attendance .system-header .btn-outline-secondary:hover,
  #staff-attendance .system-header .btn-outline-secondary:active,
  #staff-attendance .system-header .btn-outline-secondary:focus {
    background-color: #ffffff;
    color: #3b3b3b;
  }
  #staff-attendance .system-body {
    margin-top: 10.5rem;
    margin-right: 0;
    margin-left: 0;
  }
  #staff-attendance .system-body .system-names {
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 1rem + 40px - 0.625rem);
    z-index: 2;
  }
  #staff-attendance .system-body .system-names .list-group {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #staff-attendance .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #staff-attendance .system-body .system-names .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #staff-attendance .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  #staff-attendance .system-body .card-body {
    padding: 0;
  }
  #staff-attendance .system-card .tab-headers button {
    font-size: 0.875rem;
  }
  #staff-attendance .system-card .tab-headers.sticky-top {
    top: 161px !important;
  }
  #staff-attendance .accordion .card-header {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  #staff-attendance .table, 
  #staff-attendance .table thead, 
  #staff-attendance .table tbody { 
    display: block;
    height: auto !important;
    text-align: left !important;
  }
  #staff-attendance .table {
    padding: 0 0.75rem;
  }
  #staff-attendance .table thead { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  #staff-attendance .table tr {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-bottom: 1rem;
    border-bottom: 1px solid #efefef;
  }
  #staff-attendance .tb-modal-body .table tr {
    padding-bottom: 0;
    padding-top: 0.75rem;
  }
  #staff-attendance .table tr:last-of-type {
    border-bottom: 0;
  }
  #staff-attendance .table td { 
		border: none;
    position: relative;
    display: inline-block !important;
    padding: 0;
    font-size: 13px;
    padding-top: 1.125rem;
    width: 30%;
    -webkit-order: 3;
            order: 3;
    white-space: nowrap;
  }
  #staff-attendance .table td:nth-of-type(1) {
    width: 70%;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
    -webkit-order: 1;
            order: 1;
  }
  #staff-attendance .table td:nth-of-type(5) {
    width: 30%;
    padding-top: 1.625rem;
    padding-bottom: 0.75rem;
    -webkit-order: 2;
            order: 2;
    white-space: nowrap;
    font-style: italic;
    font-weight: 600;
  }
  #staff-attendance .tb-modal-body .table td:nth-of-type(1),
  #staff-attendance .tb-modal-body .table td:nth-of-type(2) {
    width: 30%;
  }
  #staff-attendance .tb-modal-body .table td:nth-of-type(4) {
    display: none !important;
  }
  #staff-attendance .table td:before { 
    position: absolute;
    top: 0;
		white-space: nowrap;
    color: #b7b7b7;
    font-size: 13px;
    content: attr(data-attr);
  }
}
#settings {
  font-family: 'Nunito';
  padding-bottom: 1rem;
}
#settings .component-sidebar {
  position: absolute;
  top: calc(5rem + 7px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#settings .component-sidebar.open {
  left: 0;
}
#settings .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#settings .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}
#settings .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
}
#settings .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#settings .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#settings .settings-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#settings .settings-header p span {
  text-decoration: underline;
}
#settings .settings-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#settings .settings-body .systems {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#settings .settings-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#settings .settings-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#settings .settings-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#settings .settings-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#settings .settings-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#settings .settings-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#settings .settings-body .settings-card-header {
  padding: 0.875rem 0rem;
  border-bottom: 1px solid #dadada;
}
#settings .settings-card .settings-card-header span.header-helper {
  float: right;
  margin-left: auto;
  color: #558dca;
  font-size: 1rem;
  margin-top: 4px;
}
#settings .settings-card .settings-card-header .search-component {
  float: right;
  margin-left: auto;
  margin-top: 4px;
}
#settings .settings-body .settings-card-body {
  padding: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.5fr));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}
#settings .settings-card .card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
  transition: all ease 450ms;
}
#settings .settings-card .card:hover {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
}
#settings .settings-card .card .card-subtitle {
  font-size: 0.875rem;
  color: #9d9d9d;
}
#settings .settings-card .card .card-subtitle a {
  color: #558dca !important;
}
#settings .settings-card .card .card-text {
  font-size: 0.875rem;
}
#settings .settings-card .card .buttons {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
}
#settings .settings-card .card .buttons button {
  font-size: 1.125rem;
  background-color: transparent;
  border: 0;
}
#settings .settings-card .card .btn-del {
  color: #ff6a6a !important;
  text-decoration: underline;
  font-size: 0.75rem;
  cursor: pointer;
  line-height: 1.875rem;
}
#settings .settings-card .card .card-footer {
  padding: 0;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 1.25rem;
  border: 0;
}
#settings .settings-card .card .roles > div, .profile .roles > div {
  border: 0 !important;
  border-bottom: 1px solid #eaeaea !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
#settings .settings-card .card .roles ul li, .profile .roles ul li {
  padding: 0.5rem 1rem !important;
  cursor: pointer;
}
#settings .settings-card .card .roles ul li:hover, .profile .roles ul li:hover {
  background-color: #2c2c2c !important;
  color: #ffffff;
}
#settings .settings-card .profile-img, .profile .profile-img {
  position: relative;
}
#settings .settings-card .profile-img label, .profile .profile-img label {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all ease 300ms;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}
#settings .settings-card .profile-img label::after, .profile .profile-img label::after {
  content: 'Change';
  position: absolute;
  font-size: 0.625rem;
  font-weight: bold;
  color: #2c2c2c;
  top: calc(50% - 5px);
}
#settings .settings-card .profile-img:hover label, .profile .profile-img:hover label {
  opacity: 1;
}

.settings-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.settings-card .btn-primary:hover, .settings-card .btn-primary:active, .settings-card .btn-primary:focus {
  background-color: #3b76b6;
  border-color: #3b76b6;
}
.settings-card .text-danger {
  color: #ff6a6a !important;
}
.settings-card .text-muted {
  color: #9d9d9d !important;
}
#settings .table {
  font-size: 0.875rem;
}
#settings .table thead th {
  color: #b7b7b7;
  border-top: 0;
}
.tb-modal-header, .tb-modal-footer {
  border: 0;
}
.tb-modal-header .close {
  color: #ff6a6a;
}
.tb-modal-title {
  font-weight: bold;
  font-family: 'Nunito';
}
.tb-modal-body {
  padding: 0 1rem;
  font-family: 'Nunito';
}
.tb-modal-footer {
  font-family: 'Nunito';
  border: 0;
}
.tb-modal-footer .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
.tb-modal-footer .btn-primary:hover, .reading-card .btn-primary:active, .reading-card .btn-primary:focus {
  background-color: #3b76b6;
  border-color: #3b76b6;
}

#settings .table button:disabled {
  opacity: 0.4;
}
#settings .table button .icon-pencil {
  font-size: 1rem;
}
#settings .altText, .profile .altText{
  height: 50px;
  width: 50px;
  color: white;
  border-radius: 50%;
  font-weight: 700;
  cursor: pointer;
  background: linear-gradient(135deg, #558dca 0%, #558dca 48%, #67a1df 53%, #67a1df 100%);
}

.profile {
  font-family: 'Nunito';
}
.profile .col-form-label {
  font-size: 0.75rem;
  color: #9d9d9d;
  display: block;
  margin-bottom: 0.25rem;
  padding: 0;
  line-height: 1.1;
}
.profile p {
  font-size: 0.875rem;
  color: #212529;
  line-height: 1.1;
  margin-bottom: 0;
}
.profile .helper {
  font-size: 0.675rem;
  color: #9d9d9d;
  line-height: 1.1;
}
.profile input {
  outline: 0 !important;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  padding: 0;
  font-size: 0.875rem;
  color: #212529;
  display: block;
  width: 100%;
  line-height: 1.1;
}
.profile input:focus, .profile input:active {
  border-color: #558dca;
}
.profile select {
  display: block;
  width: 100%;
  border-color: #eaeaea;
  font-size: 0.875rem;
  line-height: 1.1;
}

@media (min-width: 769px) {
  #settings {
    padding: 2.5rem 2rem 1rem;
  }
  #settings .settings-header h4 {
    line-height: 2rem;
  }
  #settings .settings-body {
    padding-top: 1rem;
  }
  #settings .settings-card.card {
    min-height: 100%;
  }
}
@media (max-width: 768px) {
  #settings .settings-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.375rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 3;
  }
  #settings .settings-header h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  #settings .settings-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #settings .settings-header p span {
    text-decoration: none;
  }
  #settings .settings-body {
    margin-right: 0;
    margin-left: 0;
  }
  #settings .settings-body .settings-card-body {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  #settings .settings-body .card-header {
    padding: 1.5rem 1.25rem 1.5rem;
  }
  #settings .settings-body .card-header h5 {
    font-size: 1.125rem;
  }
  #settings .settings-card.card .card-header span.header-helper {
    font-size: 0.875rem;
  }
  #settings .settings-body .profile-types {
    background-color: #3b3b3b;
    margin-top: -0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: -webkit-sticky;
    position: sticky;
    top: calc(2rem + 30px - 0.625rem + 1rem + 24px - 0.625rem);
    z-index: 2;
  }
  #settings .settings-body .profile-types .list-group {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #settings .settings-body .profile-types .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #settings .settings-body .profile-types .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #settings .settings-body .settings-card-header h5 {
    font-size: 1.125rem;
  }
  #settings .settings-body .profile-types .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  #settings table, #settings thead, #settings tbody, #settings th, #settings td, #settings tr { 
    display: block; 
    width: 100%;
    height: auto !important;
    text-align: left !important;
  }
  #settings table thead { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  #settings table tr {
    position: relative;
    padding: 0.75rem 0;
    border-bottom: 1px solid #efefef;
  }
  #settings table td { 
		border: none;
    position: relative;
    padding: 0.375rem;
		padding-left: 40% !important; 
  }
  #settings td:nth-of-type(5) {
    position: absolute;
    top: 0.25rem;
    right: 0;
    padding: 0 !important;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  #settings table td:before { 
		position: absolute;
		left: 0.5rem;
		width: 35%; 
		padding-right: 5%; 
		white-space: nowrap;
    color: #b7b7b7;
  }
  #settings td:nth-of-type(1):before { content: "Full Name"; }
	#settings td:nth-of-type(2):before { content: "Roles"; }
	#settings td:nth-of-type(3):before { content: "Email"; }
	#settings td:nth-of-type(4):before { content: "Phone"; }  
}
#service-interval {
  font-family: 'Nunito';
}
#service-interval .component-sidebar {
  position: absolute;
  top: calc(7.5rem + 6px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 1000;
  display: -webkit-flex;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}
#service-interval .component-sidebar.open {
  left: 0;
}
#service-interval .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#service-interval .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}
#service-interval .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
  cursor: pointer;
}
#service-interval .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#service-interval .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#service-interval .system-body .card-body {
  font-size: 0.875rem;
}
#service-interval .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#service-interval .system-body .systems {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#service-interval .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#service-interval .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#service-interval .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#service-interval .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: '';
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#service-interval .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#service-interval .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#service-interval .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#service-interval .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#service-interval .system-header p span {
  text-decoration: underline;
}
#service-interval .system-body h6 {
  font-weight: 600;
  margin-bottom: 0;
}
#service-interval .system-body .tabular-interval .table {
  font-size: 0.875rem;
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 10px;
}
#service-interval .system-body .tabular-interval .table th {
  border: 0 !important;
  padding: 0 0.75rem;
}
#service-interval .system-body .tabular-interval .table tr.interval-row {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  cursor: pointer;
  transition: all 450ms ease;
}
#service-interval .system-body .tabular-interval .table td {
  border: 0;
  background-color: #ffffff;
}
#service-interval .system-body .tabular-interval .table tr.interval-row:hover {
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.13);
}
#service-interval .system-body .tabular-interval .table td::first-letter {
  text-transform: uppercase;
}
#service-interval .system-body .tabular-interval .table td:first-child {
  border-radius: 2px 0 0 2px;
}
#service-interval .system-body .tabular-interval .table td:last-child {
  border-radius: 0 2px 2px 0;
}
#service-interval .system-body .tabular-interval .table td.border-indicator {
  border-left: 3px solid transparent;
}
#service-interval .system-body .card-interval {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 1.5rem;
}
#service-interval .system-body .card-interval .card {
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0px #efefef;
}
#service-interval .system-body .card-interval .card .card-body {
  border-top: 3px solid transparent;
  border-radius: 2px;
  font-size: 0.875rem;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
#service-interval .btn-primary, .system-card .btn-primary {
  background-color: #558dca;
  border-color: #558dca;
}
#service-interval .btn-success, .system-card .btn-success {
  background-color: #28bb00;
  border-color: #28bb00;
}
#service-interval .btn-light {
  background-color: transparent;
  border-color: transparent;
  color: #6d6d6d;
}
#service-interval .btn-light:hover {
  background-color: #f8f9fa;
  color: #212529;
}
#service-interval .filter-btn {
  position: relative;
}
#service-interval .filter-btn button {
  background-color: #fafafa;
  cursor: pointer;
  border: 1px solid #999999;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  padding: 0.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
#service-interval .filter-btn select {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  z-index: 2;
  font-size: 0.875rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  opacity: 0;
}
.required::after {
  content: '*';
  color: #dd3444;
  margin-left: 3px;
}
#service-interval .col-form-label {
  font-size: 0.75rem;
  color: #9d9d9d;
  display: block;
  margin-bottom: 0.25rem;
  padding: 0;
  line-height: 1.1;
} 
#service-interval .interval-comment {
  background-color: #f5f5f5;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #f1f1f1;
  position: relative;
  margin-bottom: 0.325rem;
}
#service-interval .interval-comment .comment-author {
  font-weight: 600;
  color: #9d9d9d;
  font-size: 0.75rem;
  line-height: 1.1;
  margin: 0;
}
#service-interval .interval-comment .comment-text {
  margin: 0;
}
#service-interval .interval-comment .comment-time {
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
  color: #9d9d9d;
  background-color: #f5f5f5;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1;
  margin: 0;
}
#service-interval .system-card .badge-indicator {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #a8a8a8;
  border-radius: 0.125rem;
  display: inline-block;
  margin-right: 0.5rem;
}
#service-interval .timeline li {
  font-size: 0.825rem;
  padding-right: 0;
}
.service-interval-modal .tb-modal-body {
  padding: 0 2.5rem;
  font-size: 0.875rem;
}
.service-interval-modal .form-group .form-control {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  transition: all 450ms ease
}
.service-interval-modal .form-group .form-control.has-error,
input.has-error {
  border-bottom-color: tomato;
}
.service-interval-modal .form-group input.form-control,
.service-interval-modal .form-group select.form-control {
  height: 1.5rem;
}
.service-interval-modal .form-group select.form-control {
  padding: 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;;
}
.service-interval-modal .form-group textarea.form-control {
  font-size: 0.875rem;
  padding: 0.375rem;
  border: 1px solid #eaeaea;
  border-radius: 3px;
}
.service-interval-modal .form-group .form-control:focus {
  border-color: #558dca !important;
}
.service-interval-modal .form-group .form-control:-moz-read-only {
  background-color: #ffffff;
}
.service-interval-modal .form-group .form-control:read-only {
  background-color: #ffffff;
}
.service-interval-modal .react-datepicker-wrapper,
.service-interval-modal .react-datepicker__input-container {
  width: 100%;
}
.service-interval-modal .form-group .custom-file {
  font-size: 0.875rem;
}
.service-interval-modal .form-group .custom-file-input ~ .custom-file-label::after {
  content: "\E946" !important;
  font-family: 'icomoon';
  border-radius: 0;
}
.service-interval-modal .form-group .custom-file-label {
  height: calc(1.5rem + 0.75rem - 1px);
  padding: 0.375rem;
  border-radius: 3px;
  border: 1px solid #eaeaea;
}
.file-list {
  margin-top: 1rem;
  list-style: none;
  padding: 0;
}
.file-list li {
  font-size: 0.825rem;
}
.file-list li .icon-attachment{
  color: #bfbfbf;
}
.file-list li .icon-close {
  font-size: 0.625rem;
  color: #dd3444;
}
.text-underline {
  text-decoration: underline !important;
}
select:invalid { 
  color: #a1a1a1;
}

.custom-control-label {
  color: #797979;
}
.custom-control-label::before {
  width: 0.875rem;
  height: 0.875rem;
  border-color: #eaeaea;
}
.custom-control-label::after {
  top: calc(0.25rem + 1px);
  left: calc(-1.5rem + 1px);
  width: calc(0.875rem - 2px);
  height: calc(0.875rem - 2px);
}
.custom-control-input:checked~.custom-control-label::before {
  background-color: #ffffff;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background: #558dca;
  border: 3px solid #ffffff;
  border-radius: 50%;
}
.inputGroup {
  background-color: #fff;
  display: block;
  position: relative;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.inputGroup label {
  padding: 0.25rem 0.35rem;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 400ms ease-in;
  overflow: hidden;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  margin-bottom: 0;
}
.inputGroup + .inputGroup {
  margin-left: 0.5rem;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 16px;
  height: 16px;
  content: '';
  border: 1px solid #eaeaea;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -1px 0px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 0.35rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  /* color: #fff; */
  border-color: #558dca;
}
.inputGroup input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #558dca;
  border-color: #558dca;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  -webkit-order: 1;
          order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
.custom-checkbox {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
}
.custom-checkbox input[type="checkbox"] {
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 1px solid #bdbdbd;
  border-radius: 2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  cursor: pointer;
  transition: all 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  margin-right: 0.5rem;
}
.custom-checkbox input[type="checkbox"]::before {
  position: absolute;
  content: '';
  display: block;
  /* top: 1px; */
  left: 4px;
  width: 6px;
  height: 11px;
  border-style: solid;
  border-color: #558dca;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}
.custom-checkbox input[type="checkbox"]:checked {
  color: #ffffff;
  border-color: #558dca;
  /* background-color: #558dca; */
}
.custom-checkbox input[type="checkbox"]:checked::before {
  opacity: 1;
}
.custom-checkbox label {
  margin: 0;
}
.comment-form {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0.5rem 0.5rem;
}
.comment-form textarea {
 border: 0;
 resize: none;
 padding: 0;
 font-size: 0.875rem;
 outline: none !important;
 box-shadow: none !important;
}
.comment-form button {
  padding: 0.25rem 0.5rem;
  line-height: 1.2rem;
  font-size: 0.875rem;
  outline: none !important;
  box-shadow: none !important;
}
.react-datepicker-popper {
  z-index: 10;
}

@media (max-width: 576px) {
  #service-interval .filter-input-active {
    max-width: 200px;
  }
}
@media (min-width: 769px) {
  #service-interval {
    padding: 2.5rem 2rem;
  }
  #service-interval .system-header h4 {
    line-height: 2rem;
  }
  #service-interval .system-body {
    padding-top: 1rem;
  }
}
@media (max-width: 768px) {
  #service-interval .system-header {
    padding: 0.5rem 1.25rem 1rem;
    background-color: #3b3b3b;
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 25px - 0.625rem);
    z-index: 999;
  }
  #service-interval .system-header h4 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  #service-interval .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #service-interval .system-header p span {
    text-decoration: none;
  }
  #service-interval .system-header .filter-btn {
    margin-right: 0;
  }
  #service-interval .system-header .filter-btn button {
    border-radius: 50%;
    background-color: transparent;
  }
  #service-interval .system-header .btn-add {
    font-size: 1.25rem;
    padding: 0.125rem 0.625rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }
  #service-interval .service-interval-body {
    margin-top: 10rem;
    margin-right: 0;
    margin-left: 0;
  }
  #service-interval .system-body {
    margin-top: 10.5rem;
    padding: 0 1rem;
    margin-right: 0;
    margin-left: 0;
  }
  #service-interval .system-body .system-names {
    background-color: #3b3b3b;
    /* padding-bottom: 0.5rem; */
    margin-top: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 1rem + 53px - 0.625rem);
    z-index: 2;
  }
  #service-interval .system-body .system-names .list-group {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #service-interval .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #service-interval .system-body .system-names .list-group .list-group-item::after {
    content: '';
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;

  }
  #service-interval .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }
  #service-interval .system-body .tabular-interval {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
  }
  #service-interval .system-body .tabular-interval .table {
    margin-bottom: 0;
  }
  #service-interval .tabular-interval thead,
  #service-interval .tabular-interval th {
    display: none;
  }
  #service-interval .tabular-interval .table,
  #service-interval .tabular-interval tbody,
  #service-interval .tabular-interval td,
  #service-interval .tabular-interval tr {
    display: block;
  }
  #service-interval .tabular-interval tr {
    margin-top: 0.75rem;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  #service-interval .system-body .tabular-interval .table td.border-indicator {
    border-left: 0;
    border-top: 3px solid transparent;
  }
  #service-interval .system-body .tabular-interval .table td:first-child {
    border-radius: 2px 2px 0 0;
    font-weight: bold;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
  #service-interval .system-body .tabular-interval .table td:last-child {
    position: absolute;
    top: 3px;
    right: 0;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2),
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3),
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4) {
    position: relative;
    display: inline-block;
    font-size: 13px;
    padding-top: 1rem;
    padding-bottom: 1.25rem;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2)::before,
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3)::before,
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4)::before {
    position: absolute;
    top: 0;
    white-space: nowrap;
    color: #b7b7b7;
    font-size: 12px;
    content: attr(data-header);
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2) {
    text-align: left;
    border-radius: 0 0 0 3px;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3) {
    text-align: center;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4) {
    text-align: right;
    border-radius: 0 0 3px 0;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(2):before {
    left: 0.75rem;
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(3):before {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  #service-interval .system-body .tabular-interval .table td:nth-of-type(4):before {
    right: 0.75rem;
  }
  #service-interval .interval-detail .card-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: #eff3fa;
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #service-interval .interval-detail h6 {
    font-size: 0.875rem;
  }
  #service-interval .interval-detail .btn-back {
    padding-left: 0;
    font-size: 0.75rem;
  }
  #service-interval .interval-detail hr {
    margin: 0.5rem 0 1rem;
  }
  #service-interval .interval-detail .form-group {
    margin-bottom: 0.5rem;
  }
  #service-interval .interval-detail .col-form-label {
    margin-bottom: 0;
  }
  #service-interval .interval-detail .col-form-label + p {
    font-size: 0.825rem;
  }
  #service-interval .interval-detail .service-data {
    margin-top: 1rem;
  }
  #service-interval .interval-detail .comment-text {
    font-size: 0.8rem;
  }
  #service-interval .interval-detail .comment-form textarea {
    font-size: 0.8rem;
  }
  #service-interval .interval-detail .btn-sm {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }
  .service-interval-modal .tb-modal-body {
    padding: 0 1.25rem;
  }
  .border-right {
    border-right: 0 !important;
  }
  .inputGroup label {
    font-size: 13px;
    padding: 0.325rem 0.5rem;
  }
  .inputGroup label:after {
    width: 16px;
    height: 16px;
    right: 0.5rem;
  }
  .profile input {
    font-size: 13px;
  }

  .custom-checkbox label {
    margin-right: 0;
  }
}
#service-interval button {
  outline: 0 !important;
  box-shadow: none !important;
}
.dashboard {
    margin-top: 2.5rem;
}
.dashboard .arrow-back, .dashboard .arrow-next{
  height: 1rem;
  width: 1.25rem;
}
.dashboard .arrow-back {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

button:focus {
    outline: none;
}
#dashboardCard {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    margin-right: 2.5rem;
    width: 235px;
    position: relative;
}
#dashboardCard:hover {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
}

#dashboard .daily_readings, #dashboard .system_statuses, #dashboard .task-row{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.5rem;
}

#dashboard_system_status #dashboardCard, #dashboard_staff_activities #dashboardCard, .attendance-row #dashboardCard>.d-flex {
    -webkit-align-items: start;
            align-items: start;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: none;
}
#dashboard_daily_reading .daily_readings::before, #dashboard_system_status .system_statuses::before, #dashboard_staff_activities .task-row::before  {
    content: " ";
    min-width: 2.5rem;
}
#dashboard_daily_reading .daily_readings::after, #dashboard_system_status .system_statuses:last-child::after, #dashboard_staff_activities .task-row:last-child::after  {
    content: " ";
    min-width: 2.5rem;
}

#dashboard_daily_reading .label {
  font-family: 'Nunito';
  font-size: 1rem;
  color: #494949;
}
#dashboard_daily_reading .last_updated {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #a2a2a2;
}

#dashboard_daily_reading .icon {
    max-height: 3.75rem;
    font-size: 2.75rem;
    line-height: 1;
    color: #558dca;
    margin-bottom: 0.75rem;
    background-color: transparent;
}

#dashboard_system_status .label {
  font-family: 'Nunito';
  font-size: 1rem;
  color: #494949;
  margin-bottom: 1rem;
}
#dashboard_system_status .status {
    font-family: 'Nunito';
    font-size: 1rem;
    color: #494949;
    font-weight: bold;
  }
#dashboard_system_status .last_updated {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #a2a2a2;
}
#dashboard_system_status .updatedBy {
    font-family: 'Nunito';
    font-size: 0.75rem;
    color: #b2b2b2;
    margin-top: 1rem;
}
#dashboard_system_status .icon {
    max-height: 3.75rem;
    margin-bottom: 5px;
    background-color: transparent;
}

#dashboard_staff_activities .attendance-row {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
    position: relative;
}
#dashboard_staff_activities .tasks .total{
    font-size: 1.125rem;
    color: #494949;
    font-weight: bold;
}
#dashboard_staff_activities .tasks .completed{
    font-size: 0.875rem;
    color: #a2a2a2;
}
#dashboard_staff_activities .label {
    font-family: 'Nunito';
    font-size: 1rem;
    display: block;
    text-align: left;
    padding-right: 2.5rem;
}
#dashboard_staff_activities .status {
    font-family: 'Nunito';
    font-size: 1.125rem;
    color: #494949;
}
#dashboard .tasks .label span {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    font-size: 2rem;
}
.navbar-mobile { 
    background-color: #3b3b3b;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    padding: 1rem 1.25rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2
}
.navbar-mobile .menu-button {
    cursor: pointer;
}
.navbar-mobile .menu-icons {
    font-size: 1.5rem;
    line-height: 1.25;
}
#dashboard-nav {
    padding: 1rem 1.25rem;
    background-color: #3b3b3b;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    font-family: 'Nunito';
    color: #ffffff;
    position: fixed;
    top: calc(1.375rem + 30px);
    z-index: 2;
    width: 100%;
}
#viewall-nav {
    padding: 0.5rem 1.25rem 1rem;
    background-color: #3b3b3b;
    margin-top: -0.25rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    font-family: 'Nunito';
    color: #ffffff;
}
#dashboard-nav h2 {
    font-size: 1.75rem;
    margin-top: 0.25rem;
}
a.d-mobile {
    position: relative;
    padding-right: 1rem;
}
a.d-mobile::after {
    position: absolute;
    right: 0;
    content: '\2192';

}

@media (max-width: 768px){
    .dashboard {
        margin-top: 0;
        margin-right: 0; 
    }
    .dashboard_body {
        margin-top: 10rem;
        margin-bottom: 1rem;
    }
    .dashboard .arrow-back, .dashboard .arrow-next{
        height: 0.5rem;
        width: 0.75rem;
    }
    #dashboard_daily_reading .daily_readings::before, #dashboard_system_status .system_statuses::before, #dashboard_staff_activities .task-row::before  {
        content: " ";
        min-width: 1rem;
    }
    #dashboard_daily_reading .daily_readings::after, #dashboard_system_status .system_statuses:last-child::after, #dashboard_staff_activities .task-row:last-child::after  {
        content: " ";
        min-width: 1rem;
    }
    #dashboard #dashboardCard, #expand_section #dashboardCard {
        box-shadow:  0 0 2px 0 rgba(0, 0, 0, 0.17);
        margin-right: 1rem;
        min-width: 8.75rem;
        max-height: 125px;
        width: unset;
    }
   
    #dashboard .daily_readings, #dashboard .system_statuses, #dashboard .task-row{
        overflow-x: scroll;
        padding-left: 0;
        display: -webkit-flex;
        display: flex;
    }
    #dashboard_system_status #dashboardCard, #dashboard_staff_activities #dashboardCard {
        -webkit-align-items: start;
                align-items: start;
        padding-top: 1rem;
        padding-bottom: 1.375rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    #dashboard_staff_activities #dashboardCard {
        padding-bottom: 1rem;
    }
    #dashboard_staff_activities #attendance-row #dashboardCard{
        border-bottom: 0;
        box-shadow: none;
        position: relative;
    }
    #dashboard_daily_reading .label {
      font-size: 0.75rem;
    }
    #dashboard_daily_reading .last_updated {
      font-size: 0.5rem;
    }
    
    #dashboard_daily_reading .icon {
        max-height: 1.875rem;
        font-size: 1.875rem;
        margin-bottom: 0.6875rem;
        background-color: transparent;
    }
    
    #dashboard_system_status .label {
      font-size: 0.75rem;
      margin-bottom: 1.125rem;
    }
    #dashboard_system_status .status {
        font-size: 0.75rem;
      }
    #dashboard_system_status .last_updated {
      font-size: 0.5625rem;
    }
  
    #dashboard_staff_activities .tasks {
        margin-bottom: 0;
    }
    #dashboard_staff_activities .attendance{
        margin-bottom: 1rem;
    }
    #dashboard_staff_activities .tasks .total{
        font-size: 0.75rem;
    }
    #dashboard_staff_activities .label {
        font-size: 0.75rem;
        padding-right: 1.75rem;
    }
    #dashboard .tasks .label span {
        top: 1rem;
        right: 1rem;
        font-size: 1.25rem;
    }
    #dashboard_staff_activities .status {
        font-size: 0.75rem;
    }
    #dashboard_staff_activities .attendance-row {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

/* After 09/10/2020 */

.icon-audit:before {
    content: "\E941";
    color: #ffcd39;
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 18px;
    background: #fff;
}
.slider-arrow {
  z-index: 10;
  padding: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  width: unset;
  height: unset;
  font-size: unset;
  line-height: unset;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 350ms ease;
  width: 45px;
  height: 45px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
}
.slider-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}
.slider-arrow:hover, .slider-arrow:focus {
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}
.slider-arrow::before {
  content: '';
}
.slider-next {
  right: -5px;
}
.slider-prev {
  left: -5px;
}
.slick-track {
  display: -webkit-flex;
  display: flex;
  width: 100% !important;
}
#profile{
    font-family: Nunito;
}

#profile .userName{
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: bold;
    color: #1a1818;
    margin-top: 0.5rem;
}

#profile .userMail, .userPhone{
    font-size: 13px;
    color: #b7b7b7;
}

#profile .userDetails{
    border-bottom: solid 1px #efefef;
}

#profile .userDiv{
    border-radius: 4px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    min-height: 243px;
}

#profile .userDetailsDiv{
    border-radius: 4px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    min-height: 243px;
}

#profile .user_image {
    height: 100px;
    width: 100px;
}
.camera {
    height:60px;
    width:60px;
    border-radius: 30px;
    border: 2px solid rgb(160, 156, 156);
}
.camera_button {
    margin-top: -80px;
    margin-left: 140px;
    margin-bottom: 20px;
}
#profile .profile-heading {
    color: #3B3B3B;
    font-size: 24px;
    font-family: Nunito;
    font-weight: bold;
    line-height: 31px;
    width: 100%;
    padding-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#profile .editName {
    color: #3B3B3B;	
    height: 30px;
    font-size: 12px;
    border-bottom: solid 1px #e0e0e0;
    margin-top: -7px;
}

#profile .editEmail{
    font-size: 12px;
    color: #1a1818;
    border-bottom: 0;
    margin-top: -7px;
}

#profile .emailDiv{
    font-size: 12px;
    opacity: 0.29;
    border-bottom: solid 1px #e0e0e0;
}

#profile .editPhone{
    font-size: 12px;
    color: #1a1818;
    border-bottom: solid 1px #e0e0e0;
    margin-top: -7px;
}

#profile .label{
    text-align: left;
    font-size: 10px;
    color: #a8a8a8;
}

.name ::-moz-selection {
    color: red; 
    background: yellow;
}

.name ::selection {
    color: red; 
    background: yellow;
}

#profile .email {
    color: #3B3B3B;	
    font-size: 15px;	
    font-family: Nunito;
    opacity: 0.5;
}

#profile .phone {
    color: #3B3B3B;	
    font-size: 20px;	
    font-family: Nunito;
    opacity: 0.9;
    border: 0px;
    text-align: center;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

#profile input {
    height: 25px;
    width: 300px;
    font-size: 17px;
    border-radius: 0px;
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid rgb(138, 138, 138);
    padding-left: 10px;
    font-family: Nunito;
    outline: none;
}

#profile .pass_button {
    height: 40px;
    width: 200px;
    font-size: 15px;
    border-radius: 5px;
    font-family: Nunito;
    outline: none;
    background-color: rgb(74, 144, 226);
    color: white;
}

.password_container {
    border-top: 1px rgb(209, 209, 209) solid;
}

#profile .dataHead{
    font-size: 13px;
    font-weight: bold;
    color: #1a1818;
}

#profile .dataValues{
    font-size: 13px;
    color: #3b3b3b;
}

#toggleButton .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
    margin-bottom: 0;
}
  
#toggleButton .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

#toggleButton .slider {
    position: absolute;
    cursor: pointer;
    height: 16px;
    width: 26px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d8d8d8;
    transition: .4s;
}

#toggleButton .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: #558dca;
    transition: .4s;
}

#toggleButton input:checked + .slider {
    background-color: #558dca;
}

#toggleButton input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

#toggleButton input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
    left: -14px;
    background-color: white;
}

#toggleButton .slider.round {
    border-radius: 34px;
    margin-top: 2px;
}
  
#toggleButton .slider.round:before {
    border-radius: 50%;
}

#profile #dashboard-nav{
    height: 120px;
    margin-top: 0.375rem !important;
    padding-top: 5px !important;
    z-index: 2;
}

#profile .myRolesLabel, .myApartmentsLabel, .defaultApartmentLabel{
    font-size: 10px !important;
    color: #a8a8a8 !important;
    font-weight: normal !important;
    padding-top: 15px;
}

#profile .editMyRoles, .editMyApartments{
    font-size: 12px !important;
    border-bottom: solid 1px #e0e0e0;
    color: #1a1818;
    opacity: 0.29;
}

#profile .cancelButton{
    height: 32px;
    width: 81px;
    font-size: 12px;
}

#profile .modalDropdown{
    background-position: 100% !important;
}

@media (max-width: 576px){    
    .camera {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        overflow: hidden;
    }
    .camera_button {
        margin-top: -65px;
        margin-left: 110px;
    }
    #profile input {
        height: 25px;
        width: 90%;
        font-size: 18px;
    }
    #profile .password {
        height: 40px;
        width: 70%;
        font-size: 18px;
    }
}

@media (max-width: 768px){
    #profile .userDiv{
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: solid 1px #efefef;
        padding-bottom: 10px;
        min-height: auto;
    }
    #profile .userDetailsDiv{
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-height: auto;
    }
    #profile .profileDiv{
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
        margin-top: 110px;
        z-index: 999;
    }
    #profile .user_image{
        margin-top: -100px;
    }
    #profile .editImage{
        margin-top: -49px;
    }
    #profile .editButton{
        margin-right: -10px;
        font-size: 20px;
        text-align: right;
        margin-bottom: 14px;
    }
    #profile .label{
        text-align: left;
        font-size: 10px;
        color: #a8a8a8;
    }
    #profile .myRolesLabel, .myApartmentsLabel{
        font-size: 10px !important;
        color: #a8a8a8 !important;
        font-weight: normal !important;
        padding-top: 15px;
    }
    #profile .editMyRoles, .editMyApartments{
        font-size: 12px !important;
        border-bottom: solid 1px #e0e0e0;
        color: #1a1818;
        opacity: 0.29;
    }
}


.expand_body {
  margin-top: 2.5rem;
  margin-left: 2.5rem;
}

.expand_section .section_header { 
  /* font-size: 1.5rem; */
  color: #3b3b3b;
  font-weight: bold;
  font-family: 'Nunito';
}
.expand_section .section_desc { 
  font-size: 0.875rem;
  color: #a2a2a2;
  font-family: 'Nunito';
}

#daily_reading .label, 
#system_health .label,
#staff_activities .label {
  font-family: 'Nunito';
  font-size: 1rem;
  color: #494949;
  height: 1.5rem;
}
#system_health .status {
  font-family: 'Nunito';
  font-size: 1.125rem;
  color: #494949;
  font-weight: bold;
}
#daily_reading .last_updated {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #a2a2a2;
}
#system_health .last_updated {
  font-family: 'Nunito';
  font-size: 0.875rem;
  color: #a2a2a2;
  margin-top: 0.25rem;
}
#system_health .updatedBy {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #b2b2b2;
  margin-top: 1.5rem;
  margin-bottom: 0.9375rem;
}

.expand_section .icon {
  max-height: 3.75rem;
  font-size: 2.75rem;
  line-height: 1;
  color: #558DCA;
  margin-bottom: 0.675rem;
  background-color: transparent;
}
#system_health .updatedBy {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #b2b2b2;
  margin-top: 1.5rem;
  margin-bottom: 0.9375rem;
}
#staff_activities .tasks .label {
  padding-right: 2.5rem;
}
#staff_activities .tasks .label span {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  font-size: 2rem;
}
#staff_activities .tasks .total {
  font-size: 1.125rem;
  color: #494949;
  font-weight: bold;
}
#staff_activities .tasks .completed {
  font-size: 0.875rem;
  color: #a2a2a2;
}

@media(max-width: 768px) {  
  .expand_body {
    margin: 1rem; 
  }
  .expand_section #dashboardCard, .dash_item.hidden {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  #daily_reading #dashboardCard {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    min-width: 100%;
    height: 110px;
    line-height: 1;
  }
  #system_health #dashboardCard {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    min-width: 100%;
    height: 100px;
    line-height: 1;
  }
  #staff_activities #dashboardCard {
    width: 100%;
  }
  .expand_section .dashboard_body {
      margin: 1rem;
  }
  .expand_section .d-flex .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    -webkit-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .expand_section .d-flex .col-50 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; 
  }
  .expand_section .nav_section {
    margin-top: 0.6875rem;
    margin-bottom: 0.25rem;
  }
  .expand_section .section_header { 
    font-size: 1.125rem;
    color: #ffffff;
  }
  .expand_section .section_desc { 
    font-size: 0.75rem;
    color: #a2a2a2;
  }
  .expand_section .dash_item {
    box-shadow:  0 0 2px 0 rgba(0, 0, 0, 0.17);
    margin-bottom: 1rem;
    -webkit-flex: 30% 1;
            flex: 30% 1;
    -webkit-flex-grow: inherit;
            flex-grow: inherit;
  }
  
  #daily_reading .label {
    font-size: 0.75rem;
  }
  #system_health .label {
    font-size: 0.75rem;
  }
  #daily_reading .last_updated {
    font-size: 0.5rem;
  }
  #system_health .last_updated {
    font-size: 0.5rem;
  }
  #system_health .status {
    font-size: 0.75rem;
    font-weight: bold;
  }
  #staff_activities .label {
    font-size: 0.75rem;
  }
  #staff_activities .tasks .label span {
    top: 0.75rem;
    right: 1rem;
    font-size: 1.25rem;
  }
  #staff_activities .tasks .total {
    font-size: 0.75rem;
  }
  .expand_section .icon {
      max-height: 1.875rem;
      font-size: 1.875rem;
      margin-bottom: 0.675rem;
      background-color: transparent;
  }
}
@media(max-width: 576px) {
  .expand_section #dashboardCard, .dash_item.hidden {
    min-width: 7rem;
  }
}

.add-task-modal .modalDropdown {
  border-bottom: 1px solid #d5d5d5; }

.add-task-modal .w-336 {
  width: 336px; }

#staff-activities {
  font-family: "Nunito";
  padding-bottom: 1rem; }
  #staff-activities .system-header h4 {
    font-weight: bold;
    margin-bottom: 0; }
  #staff-activities .system-header p span {
    text-decoration: underline; }
  #staff-activities .tb-camera {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1030;
    background-color: #333232;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow: hidden; }
  #staff-activities .tb-camera img {
    width: 100%;
    height: auto; }
  #staff-activities .tb-camera .btn-capture {
    position: absolute;
    bottom: 80px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #558dca;
    -webkit-align-self: center;
            align-self: center;
    border: 0;
    box-shadow: 0px 0px 45px -10px #558dca; }
  #staff-activities .tb-camera .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ffffff;
    border: 0;
    z-index: 10; }
  #staff-activities .tb-camera .btn-close.btn-cancel {
    top: auto;
    bottom: 80px;
    right: auto;
    left: 60px;
    color: var(--danger);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    padding: 0.5rem;
    width: 38px;
    height: 38px; }
  #staff-activities .tb-camera .btn-close.btn-confirm {
    top: auto;
    bottom: 80px;
    right: 60px;
    color: var(--success);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    padding: 0.5rem;
    width: 38px;
    height: 38px; }
  #staff-activities .rotate-180 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  #staff-activities .icon-arrow-left {
    font-size: 1rem; }

@media (min-width: 769px) {
  #staff-activities {
    padding: 2.5rem 2rem 1rem; }
    #staff-activities .system-header h4 {
      line-height: 2rem; }
    #staff-activities .system-body {
      padding-top: 1rem; }
    #staff-activities .system-card.card {
      min-height: 100%; } }

@media (max-width: 768px) {
  #staff-activities .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.375rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 999; }
  #staff-activities .system-header h4 {
    font-size: 1.25rem;
    line-height: 1.2; }
  #staff-activities .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem; }
  #staff-activities .system-header p span {
    text-decoration: none; }
  #staff-activities .system-body {
    margin: calc(7rem + 30px - 0.625rem) 1rem 1rem; }
  #staff-activities .system-body .header {
    padding-left: 0.875rem; }
  #staff-activities .add-ticket-btn {
    border-radius: 50% !important;
    padding: 0;
    line-height: 1.25;
    height: 38px;
    width: 38px;
    font-size: 1.25rem; } }

#staff-activity-list .list-group .list-group-item {
  background-color: var(--color-white);
  border: 0;
  border-radius: 4px;
  padding: 1.5rem 1.5rem;
  font-size: 1.15rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-bottom: 1rem;
  transition: all 300ms ease;
  box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.05);
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

#staff-activity-list .name {
  width: 20%; }

#staff-activity-list .stats {
  width: 40%; }

#staff-activity-list .icon-state {
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  text-align: center;
  padding: 3px; }
  #staff-activity-list .icon-state.icon-active {
    background: url(/static/media/active.6971eab6.svg) no-repeat center #14d5ff; }
  #staff-activity-list .icon-state.icon-done {
    background: url(/static/media/done.79538321.svg) no-repeat center #73d48e; }
  #staff-activity-list .icon-state.icon-exception {
    background: url(/static/media/hourglass.3cfb411a.svg) no-repeat center #ff8989; }

#staff-activity-list .count {
  color: #adadad;
  width: 60px; }

#staff-activity-list .progress {
  border-radius: 0.25rem;
  width: 30%; }

#staff-activity-list .progress-indicator {
  background: #558dca; }

#staff-activity-list .icon-chevron-right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  color: #adadad;
  position: absolute;
  right: 20px; }

#staff-activity-list .badge-indicator {
  background-color: #558dca; }

@media (max-width: 768px) {
  #staff-activity-list .name {
    width: 90%;
    margin-bottom: 20px; }
  #staff-activity-list .stats {
    width: 60%;
    -webkit-order: 3;
            order: 3; }
  #staff-activity-list .progress {
    width: 90%;
    -webkit-order: 2;
            order: 2;
    margin-bottom: 20px; } }

#staff-activity-detail .tab-head {
  list-style-type: none; }
  #staff-activity-detail .tab-head .item {
    width: 33%;
    border-radius: 4px;
    opacity: 0.5;
    color: var(--color-white);
    position: relative; }
    #staff-activity-detail .tab-head .item .item-count {
      font-size: 1.5rem; }
    #staff-activity-detail .tab-head .item .item-state {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      text-align: center;
      padding: 3px; }
    #staff-activity-detail .tab-head .item.selected {
      cursor: default;
      opacity: 1; }
      #staff-activity-detail .tab-head .item.selected:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -7px;
        margin: auto; }
    #staff-activity-detail .tab-head .item.active {
      background: var(--color-active); }
      #staff-activity-detail .tab-head .item.active .item-state {
        background: url(/static/media/active-filled.288c337e.svg) no-repeat center #fff; }
      #staff-activity-detail .tab-head .item.active:after {
        border-top: 7px solid var(--color-active); }
    #staff-activity-detail .tab-head .item.completed {
      background: var(--color-completed); }
      #staff-activity-detail .tab-head .item.completed .item-state {
        background: url(/static/media/completed-filled.9a39e312.svg) no-repeat center #fff; }
      #staff-activity-detail .tab-head .item.completed:after {
        border-top: 7px solid var(--color-completed); }
    #staff-activity-detail .tab-head .item.defaulted {
      background: var(--color-defaulted); }
      #staff-activity-detail .tab-head .item.defaulted .item-state {
        background: url(/static/media/defaulted-filled.342f332c.svg) no-repeat center #fff; }
      #staff-activity-detail .tab-head .item.defaulted:after {
        border-top: 7px solid var(--color-defaulted); }
    #staff-activity-detail .tab-head .item.upcoming {
      background: var(--color-upcoming); }
      #staff-activity-detail .tab-head .item.upcoming .item-state {
        background: url(/static/media/upcoming-filled.92970078.svg) no-repeat center #fff; }
      #staff-activity-detail .tab-head .item.upcoming:after {
        border-top: 7px solid var(--color-upcoming); }

#staff-activity-detail .tab-content .card {
  border: 0;
  box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.05); }

#staff-activity-detail .tab-content .card-header {
  background-color: var(--color-white);
  font-size: 1rem;
  cursor: pointer;
  border: 0; }
  #staff-activity-detail .tab-content .card-header .icon-chevron-down {
    font-size: 0.5rem !important;
    margin-right: 0.5rem;
    color: rgba(0, 0, 0, 0.3);
    transition: -webkit-transform 400ms ease;
    transition: transform 400ms ease;
    transition: transform 400ms ease, -webkit-transform 400ms ease;
    display: inline-block; }
    #staff-activity-detail .tab-content .card-header .icon-chevron-down.open {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

#staff-activity-detail .tab-content .name {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6); }

#staff-activity-detail .tab-content .time {
  background: #eeeeee;
  padding: 2px 4px;
  color: #00000066;
  font-size: 0.75rem;
  border-radius: 2px; }

#staff-activity-detail .tab-content .close-time {
  padding: 2px 4px;
  font-size: 0.75rem;
  border-radius: 2px; }
  #staff-activity-detail .tab-content .close-time-gray {
    border: 1px solid #bdbdbd;
    color: #bdbdbd; }
  #staff-activity-detail .tab-content .close-time-red {
    border: 1px solid #ff8d8d;
    color: #ff8d8d; }

#staff-activity-detail .tab-content .frequency {
  font-size: 0.75rem;
  color: #4f4f4f; }

#staff-activity-detail .tab-content .performer {
  color: #bdbdbd;
  font-size: 0.75rem;
  list-style-type: disc; }

#staff-activity-detail .tab-content .separator {
  font-size: 0.75rem;
  color: #4f4f4f; }

#staff-activity-detail .icon-chevron-down {
  font-size: 0.5rem !important;
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.3);
  transition: -webkit-transform 400ms ease;
  transition: transform 400ms ease;
  transition: transform 400ms ease, -webkit-transform 400ms ease;
  display: inline-block; }
  #staff-activity-detail .icon-chevron-down.open {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

#staff-activity-detail .icon-comments {
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 3px;
  background: url(/static/media/icon-comments.9f3a1f06.svg) no-repeat center transparent; }
  #staff-activity-detail .icon-comments.active-comments {
    background: url(/static/media/icon-comments-active.201cf9b4.svg) no-repeat center; }

#staff-activity-detail .icon-images {
  width: 24px;
  height: 24px;
  text-align: center;
  padding: 3px;
  background: url(/static/media/icon-images.bd12eb0a.svg) no-repeat center transparent; }

#staff-activity-detail .icon-camera {
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px; }
  #staff-activity-detail .icon-camera:hover:before {
    color: black; }

#staff-activity-detail .comment-count {
  font-size: 0.75rem;
  color: #00000066; }

#staff-activity-detail .link {
  font-size: 0.875rem;
  font-weight: 600; }

#staff-activity-detail .btn-approve {
  color: #73d48e;
  border: 0;
  padding: 3px 6px 3px 30px;
  border-radius: 4px;
  background: url(/static/media/icon-thumbs-up.c3d8d69b.svg) no-repeat 3px center #e1ffe9; }
  #staff-activity-detail .btn-approve:disabled {
    opacity: 0.6; }

#staff-activity-detail .label-approve {
  color: #73d48e;
  padding: 3px 6px 3px 30px;
  background: url(/static/media/icon-thumbs-up.c3d8d69b.svg) no-repeat 3px center; }

#staff-activity-detail .btn-reject {
  color: #ff6a6a;
  border: 0;
  padding: 3px 6px 3px 30px;
  border-radius: 4px;
  background: url(/static/media/icon-thumbs-down.2c3922d6.svg) no-repeat 3px center #fff0f4; }
  #staff-activity-detail .btn-reject:disabled {
    opacity: 0.6; }

#staff-activity-detail .label-reject {
  color: #ff6a6a;
  padding: 3px 6px 3px 30px;
  background: url(/static/media/icon-thumbs-down.2c3922d6.svg) no-repeat 3px center; }

#staff-activity-detail .open-detail {
  width: 44px;
  height: 30px;
  background: url(/static/media/arrow-blue-large.8d74369f.svg) no-repeat 3px center;
  cursor: pointer; }

#staff-activity-detail .task-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.25rem; }

#staff-activity-detail .all-actions {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

#staff-activity-detail .line-break {
  width: 100%;
  height: 1rem; }

#staff-activity-detail .tb-camera {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: 1030;
  background-color: #333232;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }
  #staff-activity-detail .tb-camera img {
    width: 100%;
    height: auto; }
  #staff-activity-detail .tb-camera .btn-capture {
    position: absolute;
    bottom: 80px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #558dca;
    -webkit-align-self: center;
            align-self: center;
    border: 0;
    box-shadow: 0px 0px 45px -10px #558dca; }
  #staff-activity-detail .tb-camera .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ffffff;
    border: 0;
    z-index: 10; }
    #staff-activity-detail .tb-camera .btn-close.btn-cancel {
      top: auto;
      bottom: 80px;
      right: auto;
      left: 60px;
      color: var(--danger);
      background: rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      padding: 0.5rem;
      width: 38px;
      height: 38px; }
    #staff-activity-detail .tb-camera .btn-close.btn-confirm {
      top: auto;
      bottom: 80px;
      right: 60px;
      color: var(--success);
      background: rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      padding: 0.5rem;
      width: 38px;
      height: 38px; }

#container-circles {
  bottom: 140px !important; }

@media (max-device-width: 767px) {
  #staff-activity-detail .tab-content .name {
    font-size: 0.9rem; }
  #staff-activity-detail .tab-content .item-highlights {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
    #staff-activity-detail .tab-content .item-highlights .close-time {
      margin: 0.5rem 0; } }

.activity-detail-modal .name {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6); }

.activity-detail-modal .his-comment {
  font-size: 0.875rem;
  color: var(--black); }

.activity-detail-modal .his-time {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.4); }

.activity-detail-modal .comment {
  background: var(--color-gray-2);
  border-radius: 4px;
  line-height: 1.25rem;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  max-width: 320px; }

.activity-detail-modal .comment-summary {
  line-height: 1rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.4); }

.activity-detail-modal .adhocTextarea {
  height: 120px;
  padding-right: 10px; }

.activity-detail-modal .submitButton {
  background: var(--color-active);
  bottom: 15px;
  right: 10px; }
  .activity-detail-modal .submitButton:disabled {
    opacity: 0.5;
    -webkit-filter: grayscale(1);
            filter: grayscale(1); }

#reports {
  font-family: "Nunito";
  padding-bottom: 1rem;
  height: 100%;
}
#reports .component-sidebar {
  position: absolute;
  top: calc(6rem - 2px);
  left: -100%;
  width: 80%;
  transition: all 300ms ease-in-out;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  background-color: #3b3b3b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
}
#reports .component-sidebar.open {
  left: 0;
}
#reports .component-sidebar .close {
  font-size: 0.75rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-right: 0.5rem;
}
#reports .component-sidebar .list-group {
  border-radius: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  -webkit-flex: 1 1;
          flex: 1 1;
}
#reports .component-sidebar .list-group .list-group-item {
  background-color: #3b3b3b;
  border: 0;
  border-bottom: 1px solid;
  border-color: #777777 !important;
  border-radius: 0;
  margin-top: 1px;
  padding-left: 0.75rem;
}
#reports .component-sidebar .list-group .list-group-item.active {
  z-index: auto;
  font-weight: bold;
  background-color: #333232;
}
#reports .component-sidebar .list-group .list-group-item:hover {
  font-weight: bold;
}
#reports .system-header h4 {
  font-weight: bold;
  margin-bottom: 0;
}
#reports .system-header p span {
  text-decoration: underline;
}
#reports .system-body .card-header {
  background-color: #ffffff;
  padding: 1.5rem 1.25rem 2rem;
}
#reports .system-body .card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
#reports .system-body .menu {
  color: #ffffff;
  padding: 0.5rem;
  font-size: 0.75rem;
}
#reports .system-body .systems {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
#reports .system-body .list-group .list-group-item {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  height: 3.25rem;
  color: #3b3b3b;
  cursor: pointer;
  margin-top: 0;
  transition: all 300ms ease;
}
#reports .system-body .list-group .list-group-item + .list-group-item {
  border-top: 1px solid #dcdcdc;
}
#reports .system-body .list-group .list-group-item:hover {
  font-weight: bold;
}
#reports .system-body .list-group .list-group-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -6px;
  content: "";
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0 solid transparent;
  transition: all 300ms ease;
}
#reports .system-body .list-group .list-group-item.active {
  background-color: #2c2c2c;
  color: #ffffff;
  font-weight: bold;
  border-top-color: #2c2c2c;
}
#reports .system-body .list-group .list-group-item.active::after {
  right: -6px;
  border-left: 6px solid #2c2c2c;
}
#reports .system-card.card {
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
  border: 0;
}
#reports .system-card.card .card-header span.header-helper {
  float: right;
  margin-left: auto;
  color: #558dca;
  font-size: 1rem;
  margin-top: 4px;
}
@media (min-width: 769px) {
  #reports {
    padding: 2.5rem 2rem 1rem;
  }
  #reports .system-header h4 {
    line-height: 2rem;
  }
  #reports .system-body {
    padding-top: 1rem;
  }
  #reports .system-card.card {
    min-height: 100%;
  }
}
@media (max-width: 768px) {
  #reports .system-header {
    padding: 0.5rem 1.25rem 0.5rem;
    background-color: #3b3b3b;
    margin-top: 0.375rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    color: #ffffff;
    position: fixed;
    width: 100%;
    top: calc(2rem + 30px - 0.625rem);
    z-index: 2;
  }
  #reports .system-header h4 {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  #reports .system-header p {
    color: #a2a2a2 !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  #reports .system-header p span {
    text-decoration: none;
  }
  #reports .system-body {
    margin-right: 0;
    margin-left: 0;
    margin-top: 6.15rem;
  }
  #reports .system-body .card-header {
    padding: 1.5rem 1.25rem 1.5rem;
  }
  #reports .system-body .card-header h5 {
    font-size: 1.125rem;
  }
  #reports .system-card.card .card-header span.header-helper {
    font-size: 0.875rem;
  }
  #reports .system-body .system-names {
    background-color: #3b3b3b;
    margin-top: -0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    position: fixed;
    top: calc(2rem + 30px - 0.625rem + 55px - 0.625rem);
    z-index: 2;
  }
  #reports .system-body .system-names .list-group {
    -webkit-flex-direction: row;
            flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  #reports .system-body .system-names .list-group .list-group-item {
    color: #838383;
    border: 0;
    padding: 0.75rem;
  }
  #reports .system-body .system-names .list-group .list-group-item::after {
    content: "";
    right: 50%;
    margin-left: 50%;
    bottom: -4px;
    border: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 0 solid #3b3b3b;
  }
  #reports .system-body .system-names .list-group .list-group-item.active {
    color: #ffffff;
    background-color: transparent;
    z-index: 0;
  }

  /* ios iframe scroll issue fix */
  .system-card {
    height: 100%;
  }
  #mobile-iframe {
    height: 100%;
  }

  #mobile-iframe iframe {
    width: 100%;
    height: 100%;
    border-width: 0px;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .mobile {
    height: 80%;
  }
}

.typeform-container {
  height: calc(100vh - 56px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.typeform-body {
  height: calc(100vh - 7.5rem);
  overflow: hidden;
  position: relative; }

.typeform-footer {
  height: 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; }

.q-block {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease 0s, -webkit-transform 600ms ease 0s;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s, -webkit-transform 600ms ease 0s;
  opacity: 0;
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh); }

.question {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1.5rem !important; }

.tf-input {
  background: transparent !important;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #fff !important;
  padding: 0.5rem 0;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 1.2rem !important; }
  .tf-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5); }

label.tf-file-upload {
  padding: 2.5rem 3rem;
  cursor: pointer;
  border-radius: .35rem;
  background-color: rgba(255, 255, 255, 0.1); }
  label.tf-file-upload:hover {
    background-color: rgba(255, 255, 255, 0.3); }

.tb-camera {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: 1030;
  background-color: #333232;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden; }
  .tb-camera img {
    width: 100%;
    height: auto; }
  .tb-camera .btn-capture {
    position: absolute;
    bottom: 80px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #558dca;
    -webkit-align-self: center;
            align-self: center;
    border: 0;
    box-shadow: 0px 0px 45px -10px #558dca; }
  .tb-camera .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    color: #ffffff;
    border: 0;
    z-index: 10; }
  .tb-camera .btn-close.btn-cancel {
    top: auto;
    bottom: 80px;
    right: auto;
    left: 60px;
    color: var(--danger);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    padding: 0.5rem;
    width: 38px;
    height: 38px; }
  .tb-camera .btn-close.btn-confirm {
    top: auto;
    bottom: 80px;
    right: 60px;
    color: var(--success);
    background: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    padding: 0.5rem;
    width: 38px;
    height: 38px; }

.tf-radio-group .tf-radio-btn label {
  position: relative;
  padding: .5rem 2.5rem .5rem 1rem;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  margin-right: 1rem;
  transition: all .25s;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.1); }
  .tf-radio-group .tf-radio-btn label:hover {
    background-color: rgba(255, 255, 255, 0.3); }

.tf-radio-group .tf-radio-btn input[type='radio']:checked + label {
  border: 2px solid #fff; }
  .tf-radio-group .tf-radio-btn input[type='radio']:checked + label::after {
    content: '\E92C';
    position: absolute;
    font-family: 'icomoon' !important;
    color: #fff;
    right: .5rem;
    font-size: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.full-modal .modal-dialog {
  max-width: unset !important;
  margin: 0;
  font-family: 'Nunito'; }

.full-modal .modal-dialog .modal-content {
  border-radius: 0;
  border: 0;
  background-color: #558dca;
  /* background-color: rgb(210 243 255); */
  color: #fff;
  margin-top: 0px; }

.add-incident-header {
  background-color: #3b3b3b;
  border-radius: 0; }
  .add-incident-header button {
    color: #fff;
    font-weight: 500;
    opacity: 1; }

.trak-bot-avatar img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover; }

.trak-bot-avatar .initial {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  margin-right: 0.625rem;
  font-weight: 700;
  background: linear-gradient(135deg, #558dca 0%, #558dca 48%, #67a1df 53%, #67a1df 100%); }

#ticket-details {
  margin: 4.5rem 0rem;
  box-shadow: 0px 0px 6px 0px #ccc;
  font-family: 'Nunito';
  font-size: 0.875rem;
  color: #000;
  min-height: 100%; }
  @media (min-width: 769px) {
    #ticket-details {
      margin: 2.5rem 2rem; } }
  #ticket-details .border-bottom {
    border-bottom: 1px solid #ececec !important; }
  #ticket-details .priority-select {
    line-height: 1.85;
    border: 0;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    width: unset;
    background-position: 90%;
    padding-right: 1.35rem !important; }
    #ticket-details .priority-select option {
      color: black;
      background-color: #fff; }
  #ticket-details .files {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    -webkit-overflow-scrolling: touch;
    display: -webkit-flex;
    display: flex; }
    #ticket-details .files img:not(:last-child) {
      margin-right: 10px; }
  #ticket-details .badge-indicator {
    width: 0.75rem;
    height: 0.75rem;
    background-color: #a8a8a8;
    border-radius: 0.125rem;
    display: inline-block;
    margin-right: 0.5rem; }
  #ticket-details .sub-tasks .card-header {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    color: #000; }
    #ticket-details .sub-tasks .card-header i {
      font-size: 0.5rem;
      color: rgba(0, 0, 0, 0.3); }

button.btn {
  box-shadow: none !important;
  outline: none !important; }


.assign-modal {
  font-family: 'Nunito'; }
  .assign-modal .modal-header {
    padding: .5rem 1rem;
    font-weight: 600; }
  .assign-modal .modal-content {
    border-radius: 0;
    border: 0; }

#incidents {
  /* font-size: 13px; */
  padding-top: 8.75rem;
  background-color: #eef2fa;
  font-family: 'Nunito'; }
  @media (min-width: 769px) {
    #incidents {
      padding: 2.5rem 2rem;
      background-color: #fafafa; } }
  #incidents .status-track .status-radio-btn {
    font-size: 14px; }
    #incidents .status-track .status-radio-btn label {
      margin-bottom: 0;
      display: block;
      padding: .5rem 1rem;
      border-radius: 0.25rem;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      font-weight: 700;
      color: #fff;
      opacity: 0.5;
      white-space: normal;
      cursor: pointer; }
      #incidents .status-track .status-radio-btn label .count {
        font-weight: 700;
        background-color: #fff;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        padding: .5rem;
        text-align: center;
        margin-left: 1rem !important; }
    #incidents .status-track .status-radio-btn input[type='checkbox'] {
      display: none; }
      #incidents .status-track .status-radio-btn input[type='checkbox']:checked + label {
        opacity: 1; }
  @media (max-width: 565px) {
    #incidents .status-track {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
      white-space: nowrap;
      overflow-x: scroll; }
      #incidents .status-track .col-6 {
        -webkit-flex: none !important;
                flex: none !important;
        width: unset !important;
        max-width: unset !important; }
        #incidents .status-track .col-6:not(:last-child) {
          padding-right: 0 !important; } }
  #incidents .filter-input-active {
    padding-left: 0; }
  #incidents .age-filter {
    width: unset;
    border: 0;
    background-color: transparent; }
    @media (max-width: 565px) {
      #incidents .age-filter {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        -webkit-order: -1;
                order: -1; } }
  #incidents .priority-select {
    border: 2px solid #000;
    border-radius: 40px;
    font-size: unset;
    font-weight: 700;
    background-position: 85%;
    padding: .365rem .75rem;
    width: unset;
    min-width: 100px; }
    #incidents .priority-select option {
      padding: 2px !important;
      color: black; }
  @media (max-width: 565px) {
    #incidents .category {
      border: 1px solid #cdcdcd; } }
  #incidents .tag-track {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll; }
  #incidents .tb-tag {
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    padding: .25rem 0.75rem;
    font-weight: 600; }
    #incidents .tb-tag i {
      opacity: 0.7; }
      #incidents .tb-tag i:hover {
        opacity: 1; }
  @media (max-width: 768px) {
    #incidents .incident-header {
      position: fixed;
      background: #3b3b3b;
      color: #fff;
      margin: 0 !important;
      width: 100%;
      z-index: 999;
      padding: 0 1rem;
      top: 62px; }
      #incidents .incident-header h1 {
        font-size: 1.25rem;
        font-weight: 600 !important; }
      #incidents .incident-header .add-incident-btn {
        border-radius: 50% !important;
        padding: 0;
        line-height: 1.25;
        height: 38px;
        width: 38px;
        font-size: 1.25rem; } }
  #incidents .nav-tabs.incident-tab .nav-link {
    color: #c5c5c5;
    border: 0;
    padding: .5rem 0rem;
    margin-right: 1rem;
    font-weight: 600; }
    #incidents .nav-tabs.incident-tab .nav-link.active {
      color: #000;
      font-weight: 700;
      background-color: transparent;
      border-bottom: 3px solid #000; }
  @media (max-width: 768px) {
    #incidents .nav-tabs.incident-tab {
      position: fixed;
      background-color: #3b3b3b;
      top: 100px;
      width: 100%;
      padding: 0 1rem;
      padding-bottom: .5rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      margin-left: -1rem;
      z-index: 999; }
      #incidents .nav-tabs.incident-tab .nav-link {
        font-size: 14px;
        font-weight: 500 !important;
        color: #b9b9b9 !important; }
        #incidents .nav-tabs.incident-tab .nav-link.active {
          color: #fff !important;
          border: 0 !important;
          font-weight: 600 !important; } }

#incidents .nav-tabs.incident-tab .nav-link {
  color: #c5c5c5;
  border: 0;
  padding: .5rem 0rem;
  margin-right: 1rem;
  font-weight: 600; }

#incidents .nav-tabs.incident-tab .nav-link.active {
  color: #000;
  font-weight: 700;
  background-color: transparent;
  border-bottom: 3px solid #000; }

#incidents .count-card {
  border-radius: 0.25rem; }

#incidents .card-header {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  color: #000;
  font-size: 16px !important;
  padding: 1rem; }
  #incidents .card-header i {
    font-size: 0.5rem;
    color: rgba(0, 0, 0, 0.3); }

.incidents-table.table {
  font-size: 0.875rem;
  table-layout: fixed; }
  .incidents-table.table thead tr {
    background-color: #fff;
    color: #b7b7b7;
    border-top: 1px solid #ececec; }
    .incidents-table.table thead tr th {
      border: 0;
      padding: .75rem .5rem;
      background-color: #eaeaea;
      color: #7e7e7e;
      text-transform: uppercase; }
  .incidents-table.table tbody tr {
    background-color: #fff;
    cursor: pointer;
    transition: all .45s ease; }
  .incidents-table.table td {
    border-color: #ececec;
    padding: .5rem;
    vertical-align: middle;
    color: #000; }
  .incidents-table.table .priority {
    border: 2px solid #000;
    border-radius: 40px;
    min-width: 100px;
    text-align: center;
    padding: .335rem .75rem;
    font-weight: 700; }
  @media (max-width: 565px) {
    .incidents-table.table {
      display: block;
      background-color: #fff;
      border-top: 1px solid #cdcdcd; }
      .incidents-table.table thead {
        display: none !important; }
      .incidents-table.table tbody tr {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        border: 1px solid #cdcdcd;
        border-top: 3px solid;
        border-radius: 0.25rem;
        margin: 5px;
        padding: 0.5rem .75rem;
        width: calc(100vw - 44px); }
      .incidents-table.table td {
        padding: 0;
        display: block;
        border: 0 !important;
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%; }
        .incidents-table.table td:nth-child(1) {
          -webkit-order: 2;
                  order: 2;
          -webkit-flex: 0 0 35%;
                  flex: 0 0 35%;
          text-align: right;
          color: gray;
          font-weight: 600; }
        .incidents-table.table td:nth-child(2) {
          -webkit-order: 3;
                  order: 3;
          font-weight: 700;
          font-size: 1rem;
          padding-bottom: .6rem;
          border-bottom: 1px solid #cdcdcd !important;
          margin-bottom: 4px; }
        .incidents-table.table td:nth-child(3) {
          -webkit-order: 0;
                  order: 0;
          -webkit-flex: 0 0 65%;
                  flex: 0 0 65%;
          font-size: 88%;
          color: gray; }
        .incidents-table.table td:nth-child(4) {
          -webkit-order: 8;
                  order: 8;
          -webkit-flex: 0 0 20%;
                  flex: 0 0 20%;
          display: none; }
        .incidents-table.table td:nth-child(5) {
          -webkit-order: 6;
                  order: 6;
          -webkit-flex: 0 0 60%;
                  flex: 0 0 60%;
          text-align: right;
          margin-top: 0.5rem; }
          .incidents-table.table td:nth-child(5) div {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row-reverse;
                    flex-direction: row-reverse;
            -webkit-align-items: center;
                    align-items: center; }
          .incidents-table.table td:nth-child(5) img {
            height: 30px;
            width: 30px; }
        .incidents-table.table td:nth-child(6) {
          -webkit-order: 5;
                  order: 5;
          -webkit-flex: 0 0 40%;
                  flex: 0 0 40%;
          color: gray; }
          .incidents-table.table td:nth-child(6) .status {
            font-weight: 600; } }

.font-13 {
  font-size: 13px !important; }

.font-14 {
  font-size: 14px !important; }

.font-16 {
  font-size: 16px !important; }

.font-18 {
  font-size: 18px !important; }

.font-weight-400 {
  font-weight: 400 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.btn-outline-primary {
  color: #558dca;
  border-color: #558dca; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #558dca;
    border-color: #558dca; }

.btn-primary {
  background-color: #558dca;
  border-color: #558dca; }

#incident-summary {
  /* font-size: 13px; */
  padding: 5rem 1rem;
  font-family: 'Nunito'; }
  @media (min-width: 769px) {
    #incident-summary {
      padding: 2.5rem 2rem; } }
  @media (max-width: 768px) {
    #incident-summary .add-incident-btn {
      border-radius: 50% !important;
      padding: 0;
      line-height: 1.25;
      height: 38px;
      width: 38px;
      font-size: 1.25rem; } }

.count-card {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0.25rem;
  cursor: pointer; }
  .count-card .count {
    font-weight: 700;
    background-color: #fff;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    padding: .5rem;
    text-align: center;
    margin-left: 1rem !important; }

.staff-activity-card {
  font-family: Inter;
  width: 298px;
  height: 150px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  margin: 0 12px;
  position: relative;
}

.critical {
  border-bottom: 4px solid #ff8d8d;
}
.average {
  border-bottom: 4px solid #ffdb1c;
}
.good {
  border-bottom: 4px solid #01d09f;
}
.staff-activity-count {
  /* font-family: Inter;
    font-style: normal; */
  font-family: Inter;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: #434343;
}
.staff-activity-defaulted-count {
  /* font-family: Inter;
    font-style: normal; */
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #434343;
}
.staff-activity-defaulted-name {
  /* font-family: Inter;
    font-style: normal;
    font-weight: normal; */
  font-size: 10px;
  line-height: 10px;
  color: #A4A4A4;
}
.staff-activity-name {
   font-family: Inter;
    /* font-style: normal; */
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #5A5A5A;
}

.Red {
  color: #ff8d8d;
}
.Amber {
  color: #ffdb1c;
}
.staff-activity-label {
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #9F9F9F;
}
button.widget-button {
  background-color: #ffffff;
  color: #558dca;
  border: 1.5px solid #558dca;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 11px 15px;
  line-height: 19px;
}

.widget-header-container {
    padding: 16px 16px;
    border-bottom: 1px solid #E8E8E8;
}
.widget-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.widget-label {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #5A5A5A;
}

.widget-description {
    font-size: 14px;
    line-height: 17px;
    color: #A4A4A4;
}

.widget-header .disabled {
    color: #5A5A5A;
}
.svg-container {
    width: 100%;
    height: 80%;
}

.svg-content {
    display: block;
    margin: auto;
}

.label-donut {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #434343;
}
.legend {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    }
    .legend-text{
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
    }
    .boxDanger {
        -webkit-flex-shrink:0;
                flex-shrink:0;
        width: 14px;
        height: 14px;
        background: #FF8D8D;
        margin-right:6px;
        border-radius: 2px;
    }

    .boxNormal {
        -webkit-flex-shrink:0;
                flex-shrink:0;
        width: 14px;
        height: 14px;
        background: #01D09F;
        margin-right:6px;
        border-radius: 2px;
    }

    .boxWarning {
        -webkit-flex-shrink:0;
                flex-shrink:0;
        width: 14px;
        height: 14px;
        background: #FFDB1C;
        margin-right:6px;
        border-radius: 2px;
    }

  
.widget {
  /* height: 480px; */
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  margin: 15px 0px;
  border-radius: 5px;
  font-family: Inter;
}
.widget .disabled {
  pointer-events: none;
  opacity: 0.4;
}
.edit-dashed .widget {
  border: 2px dashed #989898;
}
.full-h {
  height: 480px;
}

.half-h {
  height: 250px;
}
.dashboard-header {
  font-family: Inter;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #3a3a3a;
}
.dashboard-sub-header {
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  color: #a4a4a4;
}
.layout-mode-button {
  /* padding: 14px 24px; */
  padding: 12px 18px;
  border: 1.5px solid #9fc9f7;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

/* Compliance styles */
nav.nav-tabs {
  border: none !important;
}

.nav-tabs > .nav-link {
  font-family: Inter;
  font-size: 14px;
  line-height: 17px;
  border: none;
  color: #c9c9c9;
}

/* .nav-tabs > .nav-link {    
    color: #333333
} */

.nav-tabs > .nav-link.active {
  color: #333333;
  border-bottom: 2px solid #000000 !important;
}

.cancelButton {
  height: 44px !important;
  width: 79px;
  background-color: transparent;
  border: 0px;
  outline: none !important;
  color: #ff5151;
  font-size: 14px;
  line-height: 19px;
  margin-right: 30px;
  font-family: "Nunito";
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.06) !important;
}

.details-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 12px;
}
.details-label {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: #5a5a5a;
}

.details-description {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #a4a4a4;
}
@media (max-width: 990px) {
  .details-header {
    margin-top: 20px;
  }
  .details-label {
    font-size: 28px;
  }
  .details-description {
    font-size: 14px;
    padding: 6px 0px 16px;
  }
}

/* table.exe-table {
  font-family: Inter;
  border-collapse: collapse;
  width: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: #ffffff;
}

table.exe-table th {
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #b6b6b6;
  padding: 22px 24px;
}

.exe-details-header-container {
  margin: 15px 0px;
  padding: 16px 0px;
}

.btn-white {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 13px 16px;
}
.bgc {
  background-color: red;
} */
.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}

table.child-table-border-separate {
  /* border-collapse: collapse !important; */
  border-collapse: separate !important;
  border-spacing: 0px 5px !important;
}

@media (max-width: 990px) {
  .back-button {
    margin-top: -20px;
  }
}

/* modal styles */
.modal-content {
  margin-top: 160px;
  border-radius: 8px;
}
.modal-body {
  padding: 0px;
}
.tableFixHead tbody tr td {
  border-bottom: 1px solid #e8e8e8;
}
.tableFixHead thead th {
  background: #ffffff !important;
  border-bottom: 1px solid #e8e8e8;
}
.exe-contact {    
    padding: 10px;
}
.sh-table {
  font-family: Inter;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: #ffffff;
}
.sh-table td {
  padding: 12px 12px;
}
.sh-subtable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: none;
}
.status-critical,
.status-Red {
  background-color: #ff8d8d;
}
.status-warning,
.status-Amber {
  background-color: #ffdb1c;
}
.status-normal,
.status-Green {
  background-color: #01d09f;
}

.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}
table.child-table-border-separate {
  /* border-collapse: collapse !important; */
  border-collapse: separate !important;
  border-spacing: 0px 5px !important;
}
@media (max-width: 990px) {
  .back-button {
    margin-top: -20px;
  }
}

/* modal styles */
.modal-content {
  margin-top: 160px;
  border-radius: 8px;
}
.modal-body {
  padding: 0px;
}
.tableFixHead tbody tr td {
  border-bottom: 1px solid #e8e8e8;
}

.tableFixHead {
    overflow-y: auto;
    overflow-x: auto;
    /* overflow-x: hidden; */
    /* height: calc(100% - 200px); */
    height: 60vh;
  }
  .tableFixHead thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #dde8f4;
  }
  
  /* Just common table stuff. Really. */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    padding: 8px 16px;
  }
  th {
    background: #eee;
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #434343;
    background: rgba(85, 141, 202, 0.2);
    padding: 1rem;
  }
  tbody tr td {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4f4f4f;
    padding: 1rem;
  }
  
  .child-table-border-separate td {
    padding: 8px !important
  }

/* .status-good {
    background-color: #01D09F;
}
.status-goood {
    background-color: #FFE350;
}
.status-poor {
    background-color: #FF8D8D;
}
.status-medium {
    background-color: #FFDB1C;
}
.status-med {
    background-color: #FFCB7E;
}
.status-other {
    background-color: #7FACFA;
} */
.tableFixHead {
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}

.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}
table.child-table-border-separate {
  /* border-collapse: collapse !important; */
    border-collapse: separate !important;
    border-spacing: 0px 5px !important;
}
.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}

.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* overflow-x: hidden; */
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}

.child-table-border-separate td {
  padding: 8px !important
}
/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    outline: none;
    background: #ffffff;
    /* border: 0.0625rem solid #CCCCCC; */
    /* box-sizing: border-box; */
    border-radius: 0.1875rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
    width: 100%;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    outline: none;
    background: #ffffff;
    border: 0.0625rem solid #cccccc;
    box-sizing: border-box;
    width: 100%;
    padding: 0.3125rem;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 2px 2px;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 100%;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

/* modal styles */
.modal-content {
    margin-top: 160px;
    border-radius: 8px;
}
/* .modal-body {
    padding: 0px;
} */
.table tbody tr td {
    border-bottom: 1px solid #e8e8e8;
}
.table thead th {
    background: #ffffff !important;
    border-bottom: 1px solid #e8e8e8;
}
.zone-table {
    overflow: scroll;
    max-width: 100%;
}
/* Example Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    outline: none;
    background: #FFFFFF;
    /* border: 0.0625rem solid #CCCCCC; */
    /* box-sizing: border-box; */
    border-radius: 0.1875rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
    width: 100%;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    outline: none;
    background: #FFFFFF;
    border: 0.0625rem solid #CCCCCC;
    box-sizing: border-box;
    width: 100%;
    padding: 0.3125rem;

}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 2px 2px;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 100%;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

/* modal */

.modal-body {
    padding: 24px;
}


.tableFixHead {
  overflow-y: auto;
  overflow-x: auto;
  /* height: calc(100% - 200px); */
  height: 60vh;
}
.tableFixHead thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #dde8f4;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #eee;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  background: rgba(85, 141, 202, 0.2);
  padding: 1rem;
}
tbody tr td {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 1rem;
}

.scanner-container {
    width: 65%;
    border: 7px solid #000;
    opacity: 0.8;
    -webkit-animation: blink 1s linear infinite;
            animation: blink 1s linear infinite;
}

.scanner-container:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

@-webkit-keyframes blink{
    0%{opacity: 0.8;}
    50%{opacity: .5;}
    100%{opacity: 0.8;}
}

@keyframes blink{
    0%{opacity: 0.8;}
    50%{opacity: .5;}
    100%{opacity: 0.8;}
}
.qr-modal {
  width: 100%;
  margin: 0;
}
.qr-modal .modal-content {
  height: 100%;
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
.qr-modal .modal-body {
  background-color: #3b3b3b;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0;
}
.qr-modal .modal-body .close {
  margin-left: auto;
  font-size: 0.75rem;
  margin-right: 1rem;
  margin-top: 1rem;
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 50%;
  opacity: 1;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.18);
  cursor: pointer;
}
.qr-modal .modal-body .qr-scanner section div {
  box-shadow: rgba(0, 0, 0, 0.18) 0 0 0.75rem 0.25rem inset !important;
}
.system-sliding-pane.notifications .slide-pane__content {
  padding: 1.5rem 2rem;
}
.system-sliding-pane.notifications h5 {
  margin: 0;
}
.system-sliding-pane.notifications h6 {
  font-size: 0.875rem;
  font-weight: bold;
  font-family: 'Nunito';
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.system-sliding-pane.notifications .notification {
  border: 1px solid #f1f1f1;
  border-left: 0.25rem solid #eaeaea;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-top: 1rem;
}
.system-sliding-pane.notifications .notification p.name {
  margin: 0;
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #494949;
}
.system-sliding-pane.notifications .notification p.time {
  font-family: 'Nunito';
  font-size: 0.625rem;
  color: #b2b2b2;
  margin-bottom: 0;
}
.system-sliding-pane.notifications .view-all-link {
  font-size: 0.625rem;
  color: #558dca !important;
  display: block;
  text-align: right;
  text-decoration: underline !important;
  cursor: pointer;
  margin-top: 0.5rem;
}
.notification.red {
  border-left-color: #ff6a6a !important;
}
.notification.amber {
  border-left-color: #ffcd39 !important;
}
.notification.green {
  border-left-color: #28bb00 !important;
}
@media(max-width: 768px) {
  .system-sliding-pane.notifications h6 {
    margin-top: 1rem;
  }
  .system-sliding-pane.notifications .notification {
    margin-top: 0.5rem;
  }
}
