#incident-summary {
    /* font-size: 13px; */
    padding: 5rem 1rem;
    font-family: 'Nunito';
    @media (min-width: 769px) {
        padding: 2.5rem 2rem;
    }
    @media (max-width: 768px) {
        .add-incident-btn {
            border-radius: 50% !important;
            padding: 0;
            line-height: 1.25;
            height: 38px;
            width: 38px;
            font-size: 1.25rem;
        }
    }
}

.count-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    .count {
        font-weight: 700;
        background-color: #fff;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        padding: .5rem;
        text-align: center;
        margin-left: 1rem !important;
    }
    
}