.drp-container {
    display: inline-block;
}
.drp-button {
    padding: 0;
    width: 50px;
    border: 0;
    background-color: #3b3b3b;
    color: #fff;
    cursor: pointer;
    outline: 0;
    /* font-size: 40px; */
}

.mobile-drpdwn .drp-dropdown {
    top: calc(100% - 0.625rem - 5px);
}
.drp-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 300px;
    z-index: 2;
    /* border: 1px solid rgba(0, 0, 0, 0.04); */
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: #3b3b3b;
    color: #fff;
    border-radius: 5px;
}
  
.drp-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Nunito";
    font-weight: 400;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 10px;
}
.drp-dropdown ul::-webkit-scrollbar {
    display: initial;
    scrollbar-width: initial;
}
.drp-dropdown .drp-search {
    padding: 8px 12px 8px 22px;
}
.drp-dropdown .drp-search input.form-control{
    box-shadow: none !important;
    outline: none !important;
    border: 0;
    border-radius: 0;
    padding: 0;
    padding-bottom: 3px;
    border-bottom: 1px solid #b7b7b7;
    color: #b7b7b7;
    background-color: transparent;
    font-style: italic;
}
.drp-dropdown li {
    padding: 8px 12px;
}

.drp-dropdown li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
}