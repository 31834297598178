

.expand_body {
  margin-top: 2.5rem;
  margin-left: 2.5rem;
}

.expand_section .section_header { 
  /* font-size: 1.5rem; */
  color: #3b3b3b;
  font-weight: bold;
  font-family: 'Nunito';
}
.expand_section .section_desc { 
  font-size: 0.875rem;
  color: #a2a2a2;
  font-family: 'Nunito';
}

#daily_reading .label, 
#system_health .label,
#staff_activities .label {
  font-family: 'Nunito';
  font-size: 1rem;
  color: #494949;
  height: 1.5rem;
}
#system_health .status {
  font-family: 'Nunito';
  font-size: 1.125rem;
  color: #494949;
  font-weight: bold;
}
#daily_reading .last_updated {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #a2a2a2;
}
#system_health .last_updated {
  font-family: 'Nunito';
  font-size: 0.875rem;
  color: #a2a2a2;
  margin-top: 0.25rem;
}
#system_health .updatedBy {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #b2b2b2;
  margin-top: 1.5rem;
  margin-bottom: 0.9375rem;
}

.expand_section .icon {
  max-height: 3.75rem;
  font-size: 2.75rem;
  line-height: 1;
  color: #558DCA;
  margin-bottom: 0.675rem;
  background-color: transparent;
}
#system_health .updatedBy {
  font-family: 'Nunito';
  font-size: 0.75rem;
  color: #b2b2b2;
  margin-top: 1.5rem;
  margin-bottom: 0.9375rem;
}
#staff_activities .tasks .label {
  padding-right: 2.5rem;
}
#staff_activities .tasks .label span {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  font-size: 2rem;
}
#staff_activities .tasks .total {
  font-size: 1.125rem;
  color: #494949;
  font-weight: bold;
}
#staff_activities .tasks .completed {
  font-size: 0.875rem;
  color: #a2a2a2;
}

@media(max-width: 768px) {  
  .expand_body {
    margin: 1rem; 
  }
  .expand_section #dashboardCard, .dash_item.hidden {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  #daily_reading #dashboardCard {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    min-width: 100%;
    height: 110px;
    line-height: 1;
  }
  #system_health #dashboardCard {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    min-width: 100%;
    height: 100px;
    line-height: 1;
  }
  #staff_activities #dashboardCard {
    width: 100%;
  }
  .expand_section .dashboard_body {
      margin: 1rem;
  }
  .expand_section .d-flex .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .expand_section .d-flex .col-50 {
    flex: 0 0 50%;
    max-width: 50%; 
  }
  .expand_section .nav_section {
    margin-top: 0.6875rem;
    margin-bottom: 0.25rem;
  }
  .expand_section .section_header { 
    font-size: 1.125rem;
    color: #ffffff;
  }
  .expand_section .section_desc { 
    font-size: 0.75rem;
    color: #a2a2a2;
  }
  .expand_section .dash_item {
    box-shadow:  0 0 2px 0 rgba(0, 0, 0, 0.17);
    margin-bottom: 1rem;
    flex: 30%;
    flex-grow: inherit;
  }
  
  #daily_reading .label {
    font-size: 0.75rem;
  }
  #system_health .label {
    font-size: 0.75rem;
  }
  #daily_reading .last_updated {
    font-size: 0.5rem;
  }
  #system_health .last_updated {
    font-size: 0.5rem;
  }
  #system_health .status {
    font-size: 0.75rem;
    font-weight: bold;
  }
  #staff_activities .label {
    font-size: 0.75rem;
  }
  #staff_activities .tasks .label span {
    top: 0.75rem;
    right: 1rem;
    font-size: 1.25rem;
  }
  #staff_activities .tasks .total {
    font-size: 0.75rem;
  }
  .expand_section .icon {
      max-height: 1.875rem;
      font-size: 1.875rem;
      margin-bottom: 0.675rem;
      background-color: transparent;
  }
}
@media(max-width: 576px) {
  .expand_section #dashboardCard, .dash_item.hidden {
    min-width: 7rem;
  }
}
