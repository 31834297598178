.staff-activity-card {
  font-family: Inter;
  width: 298px;
  height: 150px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  margin: 0 12px;
  position: relative;
}

.critical {
  border-bottom: 4px solid #ff8d8d;
}
.average {
  border-bottom: 4px solid #ffdb1c;
}
.good {
  border-bottom: 4px solid #01d09f;
}
.staff-activity-count {
  /* font-family: Inter;
    font-style: normal; */
  font-family: Inter;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: #434343;
}
.staff-activity-defaulted-count {
  /* font-family: Inter;
    font-style: normal; */
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #434343;
}
.staff-activity-defaulted-name {
  /* font-family: Inter;
    font-style: normal;
    font-weight: normal; */
  font-size: 10px;
  line-height: 10px;
  color: #A4A4A4;
}
.staff-activity-name {
   font-family: Inter;
    /* font-style: normal; */
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #5A5A5A;
}

.Red {
  color: #ff8d8d;
}
.Amber {
  color: #ffdb1c;
}
.staff-activity-label {
  font-family: Inter;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #9F9F9F;
}