.scanner-container {
    width: 65%;
    border: 7px solid #000;
    opacity: 0.8;
    animation: blink 1s linear infinite;
}

.scanner-container:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

@keyframes blink{
    0%{opacity: 0.8;}
    50%{opacity: .5;}
    100%{opacity: 0.8;}
}