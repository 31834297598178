#attendance .example-custom-input{
    font-size: 14px !important;
    font-weight: bold;
    color: #3b3b3b;
    width: 115px;
    text-align: left;
}

#attendance .react-datepicker-wrapper{
    width: 115px;
}

#attendance .react-datepicker__triangle{
    left: 111px !important;
}

#attendance .react-datepicker{
    left: -100px;
}

#attendance .calendar{
    margin-right: 20px;
    margin-left: -20px;
}

#attendance .search-icon{
    height: 20px !important;
    width: 20px !important;
    margin-top: 10px !important;
    margin-right: 5px;
}

#attendance .filter-input-active{
    margin-right: 10px;
}

#attendance .attendanceHeading{
    font-size: 22px;
    font-weight: bold;
    color: #3b3b3b;
    padding-left: 3px;
}

#monthAttendance .rm-button-style{
    display: none;
}

#monthAttendance .react-datepicker-wrapper{
    width: 90px;
}

#monthAttendance .custom-date-picker{
    width: 120px !important;
}

.attendanceWeb .attendanceList{
    margin-top: 10px;
    margin-right: 15px;
}

.StaffActivitiesCard{
    margin: 15px;
    margin-top: 0px;
    border-radius: 5px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 26px;
    border: 0px;
    outline: none !important;
}
.StaffHeading{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 800;	
    line-height: 31px;
    text-align: left;
    margin-left: 20px;
}

.StaffCategory{
    color: #3B3B3B;	
    font-size: 10px;	
    line-height: 14px;
}

.StaffActive{
    font-size: 25px;	
    line-height: 31px;
}

.StaffPassive{
    color: #3B3B3B;	
    font-size: 17px;	
    line-height: 19px;
}
.StaffMapContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    float: left;
}

.StaffSingleItem{
    min-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.StaffMapContainerTasks{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    float: left;
    width: 100%
}

.cal-button-img{
    height: 25px;
    width: 25px;
    opacity: 0.7;
}

.rm-button-style{
    background-color: transparent;
    border: 0px;
    outline: none !important;
}
.font-style{
    font-family: 'Nunito';
    color: #3B3B3B;
}
.active-tab{
    font-weight: bold;
    font-size: 20px;
    border: 3px;
    color: #4A90E2
}
.inactive-tab{
    font-size: 20px;
    font-weight: bold;
    width: 100px;
}
.alt-text {
    min-height: 40px; 
    min-width: 40px; 
    border-radius: 5px; 
    margin-right: 10px; 
    color: white;
    font-size: 19px;
    background-color: #558dca;
    font-weight: 700;
}

.filter-input{
    outline: none !important;
    border: 0;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding-left: 10px;
    background-color: transparent;
    width: 30px;
    transition: 0.4s ease all;
    height: 26px;
    cursor: pointer;
    opacity: 0;
    padding-right: 5px;
}
.filter-input-active{
    outline: none !important;
    border: 0;
    border-bottom: 1px solid #558dca;
    padding-left: 10px;
    background-color: transparent;
    font-size: 0.875rem;
    width: 300px;
    opacity: 1;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    height: 26px;
}


.example-custom-input{
    background-color: transparent;
    border: 0px;
    outline: none !important;
    font-size: 20px;
    font-family: 'Nunito'
}
.example-custom-input-small{
    width: 100%;
    height: 20px;
    background-color: transparent;
    border: 0px;
    outline: none !important;
    font-size: 14px;
    font-family: 'Nunito';
    text-align: start;
    margin-right: 45px !important;
}

.employee-name{
    font-size: 14px;
    font-weight: normal;
    color: #3b3b3b;
}
.sort-icon-active{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    transform: rotate(0deg);
    opacity: 0.8;
    transition: 0.4s ease all;
}

.sort-icon{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    transition: 0.4s ease all;
    transform: rotate(180deg);
    margin-top: 2px;
    opacity: 0.8;
}
.sort-icon-inetial{
    height: 10px;
    width: 10px;
    margin-left: 10px;
    transition: 0.4s ease all;
    transform: rotate(-180deg);
    margin-top: 2px;
    opacity: 0.8;
}
.status-indictor{
    color: white;
    width: 70%;
    border-radius: 4px;
    font-weight: 600;
    opacity: 0.7;
    }

.search-icon{
    opacity: 1;
    margin-top: 3px;
    margin-left: -24px;
    pointer-events: none;
    transition: 0.4s ease all;
}

.search-icon-active{
    opacity: 0;
    margin-top: 3px;
    margin-left: -24px;
    transition: 0.4s ease all;
}

.dja {
    display: flex;
    align-items: center;
    justify-content: center;
}
.da {
    display: flex;
    align-items: center;
}
.dj {
    display: flex;
    justify-content: center;
}
.summary-card{
    min-width: 150px; 
    height: 100px; 
    flex-direction: column;
    border-radius: 5px; 
    box-shadow: 0 2px 5px 3px rgba(0,0,0,0.05); 
    background-color: 'white';
    font-size: 30px;
}
.indiactor{
    height: 9px;
    width: 9px;
    border-radius: 9px;
}

.mob-month-values{
    font-size: 9px;
    font-family: 'Nunito';
    color: #3B3B3B;
    min-width: 60px;
}

.DetailComponentCard{
    width: 100%;
    border-radius: 5px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    padding: 15px;
    padding-left: 22px;
    margin-top: 30px;
}

.taskDetailTaskName {
    font-family: Nunito;	
    font-size: 14px;	
    line-height: 19px;
}

.circleCheckBox{
    min-width: 20px !important;
    min-height: 20px !important;
    max-width: 20px !important;
    max-height: 20px !important;
    border-radius: 10px;
    background-color: transparent;
    outline: none !important;
}
.greenTick{
    height: 24px;
    width: 24px;
}
.taskDetailTaskNameSub{
    color: #9B9B9B;	
    font-family: Nunito;	
    font-size: 10px;	
    line-height: 14px;
}
.attendanceWeb .list-group .list-group-item{
    border: 0;
    border-radius: 0;
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    background-color: transparent;
}
.attendanceWeb .list-group .list-group-item::after{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 0 solid transparent;
    transition: all 300ms ease;
}
.attendanceWeb .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
    border-color: #333232;
}
.attendanceWeb .list-group .list-group-item:hover {
    font-weight: bold;
}
.attendanceWeb .list-group-item.active {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: bold;
    border-top-color: #2c2c2c;
}
.attendanceWeb .list-group .list-group-item.active::after {
    right: -6px;
    border-left: 6px solid #2c2c2c;
}
.attendanceWeb .list-group .list-group-item.active:hover{
    color: #ffffff;
}


#attendanceDiv .component-sidebar {
    position: absolute;
    top: calc(5rem + 8px);
    left: -100%;
    width: 80%;
    transition: all 300ms ease-in-out;
    z-index: 1;
    display: flex;
    background-color: #3b3b3b;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.35);
}
#attendanceDiv .component-sidebar.open {
    left: 0;
}
#attendanceDiv .component-sidebar .close {
    font-size: 0.75rem;
    color: #ffffff;
    margin-top: 1rem;
    margin-right: 0.5rem;
}
#attendanceDiv .component-sidebar .list-group {
    border-radius: 1rem;
    color: #ffffff;
    font-size: 0.875rem;
    flex: 1;
}
#attendanceDiv .component-sidebar .list-group .list-group-item {
    background-color: #3b3b3b;
    border: 0;
    border-bottom: 1px solid;
    border-color: #777777 !important;
    border-radius: 0;
    margin-top: 1px;
    padding-left: 0.75rem;
}
#attendanceDiv .component-sidebar .list-group .list-group-item.active {
    z-index: auto;
    font-weight: bold;
    background-color: #333232;
}
#attendanceDiv .component-sidebar .list-group .list-group-item:hover {
    font-weight: bold;
}
  @media (max-width: 1000px) {
    .attendanceWeb{
        flex-direction: column;
    }
    .attendanceWeb .attendanceList{
        margin-left: 15px;
        margin-top: 15px;
    }
  }

  @media (max-width: 576px) {
    .StaffHeading{
        font-size: 18px;	
    }
    .StaffSingleItem{
        min-width: 0px;
        margin-top: 10px;
    }
    .StaffCategory{
        font-size: 10px;	
    }
    .summary-card{
        min-width: 90px; 
        height: 90px; 
        font-size: 20px;
    }
    .filter-input-active{
        width: 80vw;
    }
}

@media (max-width: 768px) {
    .mobileHeading{
        font-size: 13px;
        width: 191px;
        color: #b7b7b7;
    }
    .mobileHeading .staff{
        font-size: 0.875rem;
    }
    .mobileHeading .Attendance{
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: -5px;
        color: #ffffff;
    }
    #dailyAttendance .example-custom-input{
        font-size: 15px !important;
        font-weight: normal;
        color: #ffffff;
        text-align: right;
        margin-top: 1px;
        width: 132px;
        padding-right: 35px;
    }
    .mobileAttendance .react-datepicker-wrapper{
        width: 115px !important;
    }
    .mobileAttendance .react-datepicker{
        left: -130px !important;
    }
    .mobileAttendance .react-datepicker__triangle{
        left: 175px !important;
    }
    .mobileAttendance .calendar{
        margin-right: 12px;
        margin-left: -10px;
        font-size: 22px;
        margin-top: 1px;
        color: #999999;
    }
    .attendanceWeb{
        margin-top: 175px;
    }
    .attendanceWeb .col-11{
        padding-right: 0;
    }
    .attendanceWeb .attendanceList{
        border-radius: 4px;
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.03);
        margin-top: 0px;
    }
    .attendanceWeb .attendanceCard{
        height: 135px !important;
        margin-left: 10px;
        margin-right: 10px;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 20px;
    }
    .attendanceWeb .attendanceName{
        padding-left: 5px !important;
        width: 100%;
    }
    .attendanceWeb .attendanceName img{
        height: 50px;
        max-width: none !important;
        width: 50px;
        max-height: none !important;
        border-radius: 25px !important;
    }
    .attendanceWeb .dja{
        border-radius: 25px !important;
        height: 50px;
        width: 50px;
    }
    .attendanceWeb .attendanceDetails{
        margin-left: -5px;
        margin-right: -5px;
    }
    .attendanceWeb .attendanceDetails .detailHead{
        width: 100%;
        padding: 0px 12px;
        margin-top: 10px;
        font-size: 13px;
        font-weight: normal;
        color: #b7b7b7;
    }
    .attendanceWeb .attendanceDetails .detailValue{
        font-size: 12px;
        color: #3b3b3b;
    }
    .attendanceCard .monthDetails{
        flex-direction: column;
    }
    #editTask .react-datepicker{
        left: -100px;
    }
    #editTask .react-datepicker__triangle{
        left: 135px;
    }
    .taskDetailHeading .editButton{
        padding-top: 10px;
    }
    .mobileAttendance #dashboard-nav{
        height: 115px;
        margin-top: -0.9375rem !important;
        padding-top: 5px !important;
    }
    #monthAttendance .example-custom-input{
        font-size: 15px !important;
        font-weight: normal;
        color: #ffffff;
        text-align: right;
        margin-top: 1px;
        width: 120px;
        padding-right: 40px;
    }
}