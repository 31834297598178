.heading{
    color: #666666;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 400;	
    line-height: 31px;
    margin-left: 20px;
}
.subheading{
    color: #3B3B3B;	
    font-family: 'Nunito';	
    font-size: 23px;	
    font-weight: 800;	
    line-height: 31px;
}
.buttonrmstyle{
    background-color: transparent;
    border: 0px;
    outline: none !important;
}
.back-arrow{
    height: 25px;
    width: 25px;
}
.cancelButton{
    height: 28px;	
    width: 79px;
    background-color: transparent;
    border: 0px;
    outline: none !important;
    color: #3B3B3B;
    font-size: 14px;
    line-height: 19px;
    margin-right: 30px;
    font-family: 'Nunito';
    border-radius: 3px;
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
}
.saveButton{
    height: 28px;	
    width: 79px;
    background-color: #4A90E2;
    border: 0px;
    outline: none !important;
    border-radius: 3px;
    box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Nunito';
}

.animate{
    opacity: 0;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}

.numeric_input{
  height: 30px;
  width: 100px;
  font-size: 13px;
  font-weight: 400;
  border : 0px;
  border-bottom: 2px solid #4A90E2;
  padding-left: 5px;
  outline: none !important;
  font-family: 'Nunito';
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}