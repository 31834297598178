.slider-arrow {
  z-index: 10;
  padding: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  width: unset;
  height: unset;
  font-size: unset;
  line-height: unset;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 350ms ease;
  width: 45px;
  height: 45px;
  display: flex !important;
  align-items: center;
}
.slider-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}
.slider-arrow:hover, .slider-arrow:focus {
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}
.slider-arrow::before {
  content: '';
}
.slider-next {
  right: -5px;
}
.slider-prev {
  left: -5px;
}
.slick-track {
  display: flex;
  width: 100% !important;
}