#ticket-details {
    margin: 4.5rem 0rem;
    box-shadow: 0px 0px 6px 0px #ccc;
    // background-color: #fafafa;
    font-family: 'Nunito';
    font-size: 0.875rem;
    color: #000;
    min-height: 100%;
    @media (min-width: 769px) {
        margin: 2.5rem 2rem;
    }
    .border-bottom {
        border-bottom: 1px solid #ececec !important;
    }
    .priority-select {
        // background-image: url('/assets/images/close.png');
        
        line-height: 1.85;
        border: 0;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        width: unset;
        background-position: 90%;
        padding-right: 1.35rem !important;
        option {
            color: black;
            background-color: #fff;
        }
    }
    .files {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        position: relative;
        -webkit-overflow-scrolling: touch;
        display: flex;
        img {
            // display: inline-block;
            // flex: 1;
        }
        >div {
            // flex: 1;
        }
        img:not(:last-child) {
            margin-right: 10px;
        }
    }


    //History
    .badge-indicator {
        width: 0.75rem;
        height: 0.75rem;
        background-color: #a8a8a8;
        border-radius: 0.125rem;
        display: inline-block;
        margin-right: 0.5rem;
    }

    .sub-tasks {
        .card-header {
            border: 0;
            border-radius: 0;
            background-color: #fff;
            color: #000;
            i {
                font-size: 0.5rem;
                color: rgba($color: #000000, $alpha: 0.3);
            }
        }
    }
}
button.btn {
    box-shadow: none !important;
    outline: none !important;
}